@import "../../mixin.scss";

.container {
  display: flex;
  height: 100%;
  flex-direction: column;

  @include for-size(phone-only) {
    border: solid 1px #EAEAEA;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: auto;
    padding: 0;
  }

  @include for-size(tablet-portrait-up) {
    font-size: 18px;
  }

}

.selectedFilter {
  background: #8459E9;
  height: 2px;
  margin-top: 17px;
  margin-bottom: -18px;
}

.smileContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.actionImage {
  width: 24px;
  height: 24px;
  max-width: 30px;
  max-height: 30px;
}

.actionImageBellIcon {
  width: 28px;
  height: 34px;
}

.actionImageHeartIcon {
  width: 32px;
  height: 28px;
}

.descriptionTitle {
  font-size: 17px;
  line-height: 20px;
  word-break: break-word;
  color: #020202;
  color: black;
}

.descriptionText {
  margin-bottom: 25px;
  padding-top: 11px;
  font-size: 15px;
  line-height: 22px;
  word-break: break-word;
  color: #656565;
  white-space: pre-wrap;
}

.mostAccuracyContainer {
  background-color: rgba($color: #FBCC33, $alpha: 0.9);
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
}

.mostAccuracyContainerBrowser {
  background-color: #FBCC33;
  height: var(--mostAccuracyContainerBrowserHeight);
  font-size: 13px;
  text-align: center;
  align-items: center;
}

.mostAccuracyAbsoluteContainerBrowser {
  position: absolute;
  width: 100%;
  z-index: 10;
  bottom: 0;
}

.accuracyDetails {
  position: absolute;
  right: 0;
}

.accuracyBtn {
  outline: none;
  border: none;
  background-color: rgba($color: #FBCC33, $alpha: 0.9);
  text-decoration: underline;
  font-weight: 500;
}

.accuracyContainer {
  text-align: center;
  padding: 30px;
}

.accuracyDescription {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
}

.crossedPrice {
  text-decoration: line-through;
  font-size: 13px;
}

.accurateModalOkBtn {
  color: white;
  background-color: var(--app-color);
  margin-top: 20px;
  border-radius: 5px;
}

.accurateModalOkBtn:hover {
  color: white;
  background-color: var(--app-color);
}

.accurateModalOkBtn:focus {
  color: white;
  background-color: var(--app-color);
}

.accuracyText {
  color: #2F2F2F;

  @include for-size(small-phone-only) {
    font-size: 11px;
  }
}

.accurateModa {
  border-radius: 5px;
}

.headerButton {
  background: transparent;
  cursor: pointer;
  border: none;
  outline: 0 !important;
  padding-top: 15px;
  padding-right: 15px;
}

.headerButton:focus {
  background: transparent;
  cursor: pointer;
  border: none;
  outline: 0 !important;
}

.headerButton:hover {
  background: transparent;
  cursor: pointer;
  border: none;
  outline: 0 !important;
}

.lastOrderStripeRoot {
  width: 100%;
  max-width: 100%;
  padding: 0.9rem;
  background: #F9F9F9;
}

.lastOrderStripeRow {
  flex-wrap: nowrap;
}

.lastOrderStripeStaticText {
  color: #9D9D9D;
}

.lastOrderStripeTextContainer {
  @include for-size(small-phone-only) {
    font-size: 12px;
  }
}

.lastOrderStripeText {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stripeArrowIcon {
  color: var(--app-color);
}

.avisorInfoContainerNames {
  width: 100%;
}

.infoData {
  overflow: hidden;
  padding-left: 10px;
}

.infoDataBrowser {
  flex: 1;
  overflow: hidden;
  padding-left: 55px;
  padding-right: 2%;
  color: white;
}

.advisorInfoNameTextMobile {
  width: 100%;
  max-width: 100%;
  font-size: 17px;
}

.advisorInfoNameTextH1MobileContent {
  width: 100%;
  max-width: 100%;
  font-size: 17px;
  color: #2F2F2F;
}

.advisorInfoServiceTitleTextMobile {
  width: 100%;
  max-width: 100%;
  font-size: 15px;
  color: #a4a4a4;
}

.advisorInfoTextBrovserView {
  width: 100%;
  max-width: 100%;
  color: white;
  padding-top: 20px;
}

.advisorInfoTextH1BrovserViewContent {
  width: 100%;
  max-width: 100%;
  color: white;
  padding-top: 20px;
}

.advisorInfoStarBrovserView {
  padding-top: 20px;
}

.rateCol {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.scrollContainer {
  background-color: white;
  position: relative;
  z-index: 9;
}

.withoutHeaderContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  background-color: white;
}

.headerContainer {
  display: flex;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  z-index: 9;
  background: linear-gradient(black, transparent 100%);
}

.fullScreen {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.withoutHeaderContainer::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.fullDivider {
  background-color: #e7e7e7;
  height: 1px;
}

.marginDivider {
  background-color: #e7e7e7;
  height: 1px;
  margin-left: 1em;
}

.marginDividerBrowser {
  background-color: #e7e7e7;
  height: 1px;
  margin-left: 31px;
}

.actionContainer {
  background-color: white;
}

.readingsYearJoinedContainer {
  background-color: white;
  justify-content: space-around;
  padding: 10px;
}

.readingsCount {
  color: var(--app-color);
}

.yearJoinedCount {
  color: #6EDA11;
}

.advisorInfoContainerBrowser {
  background: linear-gradient(to right, #7e62e3, #f096f4);
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 12;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 279px;
}

.advisorSupportInfoContainerFullScreenBrowser {
  @include fullScreenWidth();
  display: flex;
  position: absolute;
  top: 279px;
  left: 0;
  border-bottom: 1px solid #C0C0C0;
  background-color: white;
  justify-content: center;
}

.advisorCoreInfoContainerBrowser {
  width: 100%;
  max-width: 1200px;

  @include for-size(tablet-landscape-up) {
    max-width: 1000px;
  }
}

.advisorSupportInfoContainerBrowser {
  width: 100%;
  max-width: 1200px;
  padding-top: 17px;
  padding-bottom: 17px;
  display: flex;

  @include for-size(tablet-landscape-up) {
    max-width: 1000px;
  }
}

.mobileViewContainer {
  background-color: white;
}

.browserViewContainer {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include for-size(tablet-landscape-up) {
    max-width: 1000px;
    min-width: 1000px;
  }
}

.advisorSupportInfoBrowserDeepRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.advisorIsOfflineLabel {
  background-color: #E53975;
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
}

.advisorIsOfflineLabelCol {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.advisorIsOfflineLabelNotifyMeButton,
.advisorIsOfflineLabelNotifyMeButton:hover,
.advisorIsOfflineLabelNotifyMeButton:focus {
  color: white;
  border: 1px solid white;
  background: transparent;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  outline: 0 !important;
}

.advisorIsOfflinelabelIcon {
  width: 32px;
  height: 28px;
  padding-right: 5px;
}

.viewContainer {
  display: flex;
  justify-content: center;
}

.statsHolder {
  width: 60%;
}

.advisorServiseContainer {
  border: 1px solid #e7e7e7;
  border-radius: 8px;
}

.categoriesHolder {
  padding-bottom: 20px;
  font-size: 15px;
  font-weight: 500;
  color: black;
}

.categoriesContainer {
  display: flex;
  flex-direction: column;
}

.promotionHolder {
  display: flex;
  border-radius: 8px;
  margin: 0px 30px;

  @include for-size(phone-only) {
      display: none;
  }
}

.promotionFooter {
  display: none;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;

  @include for-size(phone-only) {
      display: flex;
  }
}

.promoLabelContainer {
  padding-left: 30px;
  padding-right: 30px;
  @include for-size(phone-only) {
    margin-top: 25px;
    padding: 0;
}
}

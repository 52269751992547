@import "../../common_mixin.scss";

.paymentMethodBtn {
  @include noBackgroundButton;
  max-width: 645px;
  width: 100%;
}


.sourceErrorContainer {
  padding-right: 20px;
  @include for-size(phone-only) {
    padding-right: 10px;
  }
}

.sourceNameContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.skeleton:first-of-type {
  margin-top: 0;
}

.loadingContent {
  height: 50px;
}

.skeleton {
  @include skeletonContainer();
}

.skeleton:first-of-type {
  margin-top: 0;
}

// PURCHASE
.sourceContainer_purchase {
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  @include for-size(phone-only) {
    padding-top: 15px;
  }

  .mobileSource {
    height: 64px;
    background-color: var(--app-background-secondary-color);
    align-items: center;
    display: flex;
    width: 100%;
    border: 0.5px solid var(--app-border-primary-color);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
    border-radius: var(--app-payment-sources-border-radius, 6px);
    padding: 24px 18px;
  }

  .sourceImg {
    width: 48px;
    height: 32px;
  }

  .sourceImgChangingSource {
    width: 48px;
    height: 32px;
  }

  .sourceShortInfo {
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: var(--app-text-primary);
  }

  .defaultText {
    font-size: 11px;
    font-weight: 500;
    color: var(--app-text-secondary);
  }

  .errorText {
    color: #E34545;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    margin-top: 3px;
  }

  .errorImg {
    width: 15px;
    height: 15px;
  }

  .paymentArrow{
    width: 10px;
  }

  .skeleton {
    height: 50px;
    max-width: 645px;
    margin-top: 20px;
    @include for-size(phone-only) {
      max-width: 370px;
    }
  }
}

.sourceContainer_purchase:first-child {
  padding-top: 0px;
}

.sourceContainer_purchase:last-child {
  @include for-size(phone-only) {
    padding-bottom: 5px;
  }
}

// WALLET
.sourceContainer_wallet {
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  @include for-size(phone-only) {
    padding-top: 15px;
  }

  .mobileSource {
    height: 150px;
    background-color: var(--app-background-secondary-color);
    align-items: center;
    display: flex;
    width: 100%;
    font-weight: 700;
    font-size: 25px;
    border-radius: 10px;
    border: 0.5px solid #C7C7C7;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
    border-radius: var(--app-payment-sources-border-radius, 6px);
    padding: 40px 37px;
    @include for-size(phone-only) {
      height: 64px;
      padding: 24px 18px;
    }
  }

  .sourceImg {
    width: 71px;
    height: 46px;
    @include for-size(phone-only) {
      width: 48px;
      height: 32px;
    }
  }

  .sourceImgChangingSource {
    width: 48px;
    height: 32px;
  }

  .sourceShortInfo {
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: var(--app-text-primary);
    @include for-size(phone-only) {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      padding: 0 15px;
    }
  }

  .defaultText {
    font-size: 18px;
    font-weight: 400;
    color: var(--app-text-secondary);
    @include for-size(phone-only) {
      font-weight: 500;
      font-size: 11px;
    }
  }

  .errorText {
    color: #E34545;
    font-size: 16px;
    font-weight: 400;
    @include for-size(phone-only) {
      font-weight: 500;
      font-size: 10px;
    }
  }

  .errorImg {
    width: 30px;
    height: 30px;
    @include for-size(phone-only) {
      width: 15px;
      height: 15px;
    }
  }

  .paymentArrow {
    width: 20px;
    @include for-size(phone-only) {
      width: 10px;
      height: 32px;
    }
  }

  .skeleton {
    height: 150px;
    max-width: 645px;
    margin-top: 20px;
    @include for-size(phone-only) {
      height: 86px;
      max-width: 370px;
    }
  }
}

.sourceContainer_wallet:first-child {
  padding-top: 0px;
}

.sourceContainer_wallet:last-child {
  @include for-size(phone-only) {
    padding-bottom: 5px;
  }
}

.notSupportedOption {
  font-size: 13px;
  color: var(--app-text-secondary);
  margin-bottom: 16px;
  padding-left: 15px;
  padding-right: 15px;
}

.notSupportedContainer {
  display: flex;
  flex-direction: column;
}

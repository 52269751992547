@import '../../../mixin.scss';

.ppTofModalSignUpContent  {
  width: 500px;
  min-width: 500px;
  padding: 40px;
  font-size: 17px;
  @include for-size(phone-only) {
    width: unset;
    font-size: 15px;
    min-width: unset;
    padding: 16px;
    max-width: 500px;
  }
}

.ppTofModalSignUpButton, .ppTofModalSignUpButton:hover, .ppTofModalSignUpButton:focus {
  width: 100%;
  background-color: var(--app-color);
  color: white;
  margin: 24px 0 10px 0;
  border: none;
  border-radius: var(--app-default-btn-lg-border-radius);
  height: 48px;
  padding: 0 16px;
  cursor: pointer;
}

.ppTofModalCancelButton {
  background-color: white;
  color: var(--app-color);
  border: none;
  border-radius: var(--app-default-btn-sm-border-radius);
  height: 48px;
  padding: 0 16px;
  cursor: pointer;
  width: 100%;
}

.ppTofModalSignUpHeader {
  color: var(--Texts-Primary, #121212);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 20px;
  margin-top: 8px
}

.socialPpTofModalButtonsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.socialPpTofModalContent {
  padding: 15px;
}

.socialPpTofModalCancelButton, .socialPpTofModalCancelButton:hover, .socialPpTofModalCancelButton:focus {
  color: var(--app-color);
  border-color: var(--app-color);
  min-width: 88px;
  border: solid 1px;
  padding: 3px;
  cursor: pointer;
  border-radius: var(--app-default-btn-lg-border-radius);
}

.socialPpTofModalOkButton, .socialPpTofModalOkButton:hover, .socialPpTofModalOkButton:focus {
  background-color: var(--app-color);
  color: white;
  border: none;
  min-width: 88px;
  border: solid 1px;
  border-radius: var(--app-default-btn-sm-border-radius);
  padding: 3px;
  cursor: pointer;
}

.socialPpTofModalOkButton:disabled, .socialPpTofModalOkButton:disabled:hover, .socialPpTofModalOkButton:disabled:focus {
  background-color: #cecece;
  color: white;
  border: none;
}

.socialPpTofModalText {
  text-align: initial;
  padding-right: 10px;
}

.confirmPpTofSwitcherRowsignUp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  font-size: 13px;
  flex-flow: initial;
}

.tcpBannerText {
  p {
    font-size: 17px;
    color: #121212;
    @include for-size(phone-only) {
      font-size: 15px;
    }
  }
}

.datePickerHolder {
  position: relative;
  display: flex;
  align-items: center;
}

.datePickerHolder::after {
  position: absolute;
  left: 13px;
  top: 3px;
  bottom: 3px;
  right: 13px;
  content: attr(data-placeholder);
  pointer-events: none;
  white-space: pre;
  display: flex;
  align-items: center;
  z-index: 1;
}

.datePickerInput, .datePickerInput:hover, .datePickerInput:focus, .datePickerInput:active {
  color: black;
  border: none;
  width: 100%;
  text-align: left;
  outline: none;
  background-color: transparent;
  padding: 0px;
  -webkit-appearance: initial;
  text-indent: 0px;
  position: relative;
  z-index: 2;
}

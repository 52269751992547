@import '../../../../mixin.scss';

.promotionLabelContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  color: black;
  width: 100%;
  height: 100%;
  align-items: center;
  border-radius: 12px;
  overflow: hidden;
}

.mainContent {
  position: absolute;
  z-index: 1;
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: row-reverse;
}

.promoContent {
  display: flex;
  width: 100%;
  padding: 18px;
  z-index: 1;
  align-items: center;

  @include for-size(phone-only) {
    width: 100%;
    min-width: 300px;
    padding: 7px 14px;
  }
}

.bgImage {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  aspect-ratio: var(--promotion-label-banner-aspect-ration);
}

.textContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 25px;
  color: #FFF;

  @include for-size(phone-only) {
    font-size: 17px;
    line-height: 19px;
  }
}

.subtitle {
  font-size: 18px;
  font-weight: 500;

  @include for-size(phone-only) {
    font-size: 13px;
  }
}

.countdownTitle {
  font-weight: 700;
  font-size: 9px;
  line-height: 18px;
}

.countdownContainer {
  display: flex;
  flex-direction: column;
  margin-inline-start: auto;
  background-color: rgba(255, 255, 255, 0.4);
  justify-content: center;
  align-items: center;
  vertical-align: bottom;
  padding: 8px;
  padding-top: 4px;
  border-radius: 10px;

  @include for-size(phone-only) {
    padding: 4px;
  }
}

.countDownTimer {
  display: flex;
  flex-direction: row;
}

.countdown {
  border-radius: 6px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  width: 44px;
  height: 50px;
  line-height: 13px;
  padding: 5px;
  padding-top: 7px;
  background: linear-gradient(to bottom, #fff 0%, #fff 50%, #ECECEC 50%, #ECECEC 100%);

  @include for-size(phone-only) {
    font-size: 18px;
    width: 34px;
    height: 40px;
  }
}

.countdown:first-of-type {
  margin-left: 0;
}

.countdownSubtitle {
  font-size: 8px;
  font-weight: 500;
  padding-top: 5px;

  @include for-size(phone-only) {
    font-size: 7px;
  }
}
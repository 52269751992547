@import '../../mixin.scss';

@mixin feedbackButton($width, $height: null) {
  @if ($height) {
    $height: $height;
  } @else {
    $height: 38px;
  }
  background-color: transparent;
  border: solid 1px lightgray;
  height: $height;
  max-width: 165px;
  width: $width;
  cursor: pointer;
}

.feedbackSwitcherContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.negativeFeedbackButtonDEFAULT {
  $width: 35vw;
  @include feedbackButton($width);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.positiveFeedbackButtonDEFAULT {
  $width: 35vw;
  @include feedbackButton($width);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.negativeFeedbackButtonDEFAULT:focus,
.negativeFeedbackButtonDEFAULT:hover,
.positiveFeedbackButtonDEFAULT:focus,
.positiveFeedbackButtonDEFAULT:hover {
  border-color: lightgray;
}

.negativeFeedbackButtonCHATENDED {
  $width: 120px;
  $height: 44px;
  @include feedbackButton($width, $height);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.positiveFeedbackButtonCHATENDED {
  $width: 120px;
  $height: 44px;
  @include feedbackButton($width, $height);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.negativeFeedbackButtonCHATENDED:focus,
.negativeFeedbackButtonCHATENDED:hover,
.positiveFeedbackButtonCHATENDED:focus,
.positiveFeedbackButtonCHATENDED:hover {
  border-color: lightgray;
}

.smileyIconDEFAULT {
  width: 27px;
  height: 27px;
}

.smileyIconCHATENDED {
  width: 31px;
  height: 31px;
}

.inputArea {
  width: 100%;
  background-color: #fff;
  resize: none;
  max-height: 200px !important;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
}

.inputArea:focus, input:focus{
  border: 1px solid #d9d9d9;
  -webkit-box-shadow: none;
  box-shadow: none;
  resize: none;
  max-height: 200px !important;
}

.inputArea:hover, input:hover{
  border: 1px solid #d9d9d9;
  -webkit-box-shadow: none;
  box-shadow: none;
  resize: none;
  max-height: 200px !important;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #B9B9B9;
}

.rateReviewContainer {
  padding-top: 40px;
  padding-bottom: 30px;
  @include for-size(phone-only) {
    max-width: 313px;
  }
}

.reviewSectionContainer {
  background-color: #F9F9F9;
  padding-left: 1.5em;
  padding-right: 1.5em;
  width: 100%;
}

.rateReviewSectionTitle {
  margin-top: 15px;
  color: var(--app-black);
}

.advisorNameText {
  font-weight: 700;
  color: var(--app-black);
}

.submitButton {
  background-color: var(--app-color);
  width: 100%;
  height: 44px;
  color: #fff;
  border-radius: var(--app-default-btn-sm-border-radius);
  border: none;
  cursor: pointer;
}

.submitButton:focus,
.submitButton:hover {
  color: #fff;
  background-color: var(--app-color);
  border-color: var(--app-color);
}

.starImage {
  width: 65px;
  height: 55.5px;
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  right: 0px;
  position: relative;
  top: 3px;
}

.skipButtonContainer {
  display: flex;
  justify-content: center;
}

.skipButton, .skipButton:hover, .skipButton:focus {
  color: var(--app-color);
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.skipTopButtonContainer {
  position: absolute;
  right: 5px;
  top: 30px;
}

.skipTopButton, .skipTopButton:hover, .skipTopButton:focus  {
  color: var(--app-text-secondary);
  border: none;
  background-color: transparent;
  font-size: 12px;
}

.flexDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rateReviewCHATENDED {
  margin-top: 10px;
  margin-bottom: 10px;
}

.rateSectionDEFAULT {
  color: black;
  margin-left: 20px;
  margin-right: 20px;
}

.rateSectionChatEndedContainer {
  color: black;
  margin-left: 20px;
  margin-right: 20px;
}

.chatEndedTitle {
  font-size: 17px;
  font-weight: 500;
}

.chatEndedSubTitle {
  font-size: 13px;
  color: #949494;
  text-align: center;
}

.smallModalContentContainer {
  color: black;
  margin-top: 5px;
}

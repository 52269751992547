@import '../../mixin.scss';

.navigationStackBarContainer {
    height: 66px;
    min-height: 66px;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    background-color: transparent;
  }

  .navigationStackBarContentHolder {
    width: 100%;
    display: flex;
    @include defaulContentWidth();
    width: 100%;
    position: relative;
    @include for-size(phone-only) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .navigationItemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navigationItemTitle, .navigationItemTitle:hover {
    font-size: 16px;
    font-weight: 700;
    margin-right: 20px;
    height: 44px;
    min-width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--textColor);
    @include for-size(phone-only) {
      font-size: 12px;
      font-weight: 700;
      margin-right: 16px;
    }
  }

  .navigationItemArrow {
    display: flex;
    width: 5px;
    height: 15px;
    margin-right: 20px;
    color: var(--textColor);
    @include for-size(phone-only) {
      width: 4px;
      height: 13px;
      margin-right: 16px;
    }
  }


@import '../../mixin.scss';

.browserContainer {
  position: relative;
  background-color: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 920px;
  max-width: 920px;
  border-radius: 8px;
  flex-direction: column;
  width: 100%;
  @include for-size(phone-only) {
    min-width: unset;
  }
}
.mobileContainer {
  position: relative;
  background-color: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  @include for-size(phone-only) {
    height: 100%;
    min-height: var(--window-height);
  }
}

.popover {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.scrollContainerBrowser {
  position: absolute;
  margin: auto;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scrollContainerMobile {
  position: absolute;
  margin: auto;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentHolder {
  width: 315px;
  padding-top: 30px;
  padding-bottom: 45px;
}

.input {
  width: 100%;
  border: 1px solid #CECECE;
  border-radius: 4px;
  background-color: #FFFFFF;
  text-align: center;
  height: 44px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  min-width: 315px;
}
input,
select,
textarea {
  color: #000000;
}
input:focus {
  color: var(--app-text-primary);
  border: solid 1px var(--app-color);
}
input,
select:focus {
  outline: none;
  outline-width: 0;
  border-color: var(--app-color);

}
::-webkit-input-placeholder {
  color: #ADADAD;
}

:-ms-input-placeholder {
  color: #ADADAD;
}

::placeholder {
  color: #ADADAD;
}
.inputError, .inputError:hover, .inputError:focus {
  border-color: #e05959 !important;
}

.actionButton {
  @include noBackgroundButton;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  border-radius: var(--app-default-btn-sm-border-radius);
  background-color: var(--app-color);
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  margin-top: 40px;
}

.signUpAffiliatePromoactionButton {
  color: white;
  font-size: 18px;
  background-color: var(--app-color) !important;
  border-radius: 41px !important;
  margin-top: 0px !important;
}

.disableActionBtn {
  background: #D5D5D5;
}

.navigationButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.navigationButton {
  @include noBackgroundButton;
  font-size: 13px;
  font-weight: 700;
  color: var(--app-color);
}

.fbButton {
  @include noBackgroundButton;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin-top: 9px;
  font-size: 14px;
  font-weight: 500;
  color: #3c4043;
  font-family: "Google Sans",arial,sans-serif;
  background-color: #FFFFFF;
  outline: none;
  padding-left: 7px;
  padding-right: 7px;
  border: 1px solid #dadce0;
}

.fbButton:hover {
  border-color: #d2e3fc;
  background: linear-gradient(0deg, #FFFFFF 100%, #4285f40a 100%);
}

.fbProcessing {
  background-color: #677dac;
}

.socialDisabled {
  background: #c2c1c1 !important;
}

.googleButton {
  @include noBackgroundButton;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 9px;
  color: #ffffff;
  outline: none;
}

.googleProcessing {
  background-color: #de8a8a;
}

.socialImg {
  margin-right: 5px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImg {
  max-width: 200px;
  max-height: 200px;
  width: 59px;
  height: 59px;
}

.error {
  color: #e05959;
  font-weight: 500;
}

.confirmPpTofTextContainer{
  display: block;
  text-align: center;
  margin-top: 10px;
  font-size: 10px;
  width: 100%;
  color: #A3A3A3;
}

.confirmPpTofTextLink {
  color: var(--app-color);
  white-space: nowrap;
}

.confirmPpTofSwitcherRowsignUp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  font-size: 13px;
  flex-flow: initial;
}

.confirmPpTofSwitcherRowsignUpAffiliatePromo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  font-size: 13px;
  color: #7f80a1;
  flex-flow: initial;
}

.sicialBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialLeftBtnContainer {
  width: 100%;
  max-width: 150px;
}

.socialRightBtnContainer {
  width: 100%;
  margin-left: 8px;
  max-width: 150px;
}

.ppTosSwitcher {
  margin-left: 10px;
}

.rememberMeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 13px;
}

.rememberMeCheckboxInput {
  box-shadow: none;
}

.remeberMeMarginsignIn {
  margin-right: 10px;
  margin-left: 8px;
}

.remeberMeMarginsignUp {
  margin-right: 10px;
  margin-left: 8px;
}

.remeberMeMarginsignUpAffiliatePromo {
  margin-right: 10px;
  margin-left: 8px;
  color: #7f80a1;
}

.cancelContainer {
  position: relative;
  color: var(--app-color);
  top: 27px;
  left: 23px;
  z-index: 10;
  display: flex;
  align-self: flex-start;
  height: 0;
}

.cancelButton {
  @include noBackgroundButton;
}

.cancelButtonImg {
  position: relative;
}

.connectTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: #BFBFBF;
}
.filterOption {

}

.inputsignUpAffiliatePromo, .inputsignUpAffiliatePromo:hover, .inputsignUpAffiliatePromo:focus {
  background-color: #0f0c36 !important;
  border-color: transparent;
  color: white;
  @media (max-width: 1200px){
    margin-top: 0px;
  }
}

@-webkit-keyframes autofill {
  to {
    color: white;
    background: #0f0c36;
  }
}

.inputsignUpAffiliatePromo:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.topLogoContainerBrowser {
  z-index: 10;
}

.topLogoContainerMobile {
  padding-top: 5px;
  z-index: 10;
  background-color: #1b1945;
  width: 100%;
  display: flex;
  justify-items: center;
  justify-content: center;
}

.topLogoImg {
  width: 60px;
  height: 60px;
}

.affiliatePromoHeaderContainer {
  text-align: center;
}

.affiliatePromoHeaderTitle {
  color: white;
  font-size: 35px;
  font-weight: 500;
  margin-top: 15px;
  @media (max-width: 1200px){
    font-size: 20px;
    margin-top: 5px;
  }
  @media(max-width: 375px){
    font-size: 16px;
    margin-top: 10px;
  }
  @media (min-height: 812px){
    font-size: 35px;
    margin-top: 15px;
  }
}

.affiliatePromoHeaderBodyText {
  color: white;
  font-size: 16px;
  margin-top: 15px;
  white-space: nowrap;
  @media (max-width: 1200px){
    font-size: 14px;
    margin-top: 5px;
  }
  @media(max-width: 375px){
    font-size: 12px;
    margin-top: 3px;
  }
  @media (min-height: 812px){
    font-size: 16px;
  }
}

.affiliatePromoHeaderBottomText {
  color: white;
  font-size: 24px;
  font-weight: bold;
  @media(max-width: 375px){
    font-size: 14px;
  }
  @media (max-width: 1200px){
    font-size: 16px;
  }
  @media (min-height: 812px){
    font-size: 24px;
  }
}

.signUpAffiliatePromoLabel {
  color: #7f80a1;
}

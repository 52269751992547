@import '../../../mixin.scss';

.mainContainer {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  left: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.specialtyContentAreaContainer {
  margin-left: 10px;
  margin-right: 10px;
}

.specialtyContentArea {
    width: 100%;
    margin-top: 80px;
    border: solid 1px #eaeaea;
    background-color: #fff;
    padding: 25px 30px;
    border-radius: 20px;
  }

  .articleTitle {
    margin: 0 0 7px 0;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    color: #000;
    @include for-size(phone-only) {
      font-size: 22px;
    }
  }

  .articleSubtitle {
    margin: 0 0 7px 0;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #000;
    @include for-size(phone-only) {
      font-size: 17px;
    }
  }

  .articleTextBox {
    text-align: left;
    color: #565656;
    display: inline-block;
  }

  .showMoreOrLessBtn {
    display: inline;
    border: 0px;
    background:white;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.4;
    text-align: left;
    color: #000;
    cursor: pointer;
    padding: 0;
    @include for-size(phone-only) {
      font-size: 14px;
      line-height: 1;
    }
  }

  .articleText {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.4;
    color: #565656;
    display: block;
    width: 100%;
    @include for-size(phone-only) {
      font-size: 14px;
      line-height: 1;
    }
  }

.messageImage {
  margin-right: 6px;
  use {
    fill: var(--app-color);
  }
}

.messageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.antSliderHandle {
  position: absolute;
  width: 18px;
  height: 18px;
  margin-top: -5px;
  margin-left: -1px;
  background-color: #fff;
  border: solid 1px rgba(0, 0, 0, 0.65);
  border-radius: 50%;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  cursor: pointer;
}

.sliderTrack {
  position: absolute;
  z-index: 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
  height: 8px;
  background-color: var(--app-color);
}

.sliderRoot {
  display: flex;
  width: 100%;
  justify-items: center;
  height: 5px;
  position: relative;
  background-color: #DDD;
  border-radius: 2px;
}

.rail {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 2px;
}

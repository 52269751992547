@import "../../common_mixin.scss";

.text {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  margin-top: 18px;
  white-space: pre-wrap;

  @include for-size(phone-only) {
    font-size: 15px;
    margin-top: 0;
    line-height: 22px;
  }
}

.readMoreBtn {
  font-weight: 700;
  font-size: 20px;
  border: none;
  background-color: transparent;
  padding: 0;
   padding-left: 3px;
  cursor: pointer;
  @include for-size(phone-only) {
    font-size: 15px;
  }
}

.skeleton {
  width: 148px !important;
  margin-left: 9px;
  height: 207px !important;

  @include for-size(phone-only) {
    width: 100px !important;
    margin-left: 9px;
    height: 147px !important;
  }
}

.ellipsisContainer {
  white-space: nowrap;
  overflow: hidden;
}

.ellipsisText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;

  display: -webkit-box;
  -webkit-line-clamp: var(--rows);
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-word;
  * {
    display: inline;
  }
}


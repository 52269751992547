@use "sass:math";

$badgeHeight: 1rem;

.container {
  position: relative;
  padding: 0.4rem 0.7rem;
}

.badgeContainer {
  font-size: 0.7rem;
  min-height: $badgeHeight;
  height: $badgeHeight;
  border-radius: math.div($badgeHeight, 2);
  background: #f00;
}

.badgeContainerAsChild {
  position: absolute;
  right: 0;
  top: 0;
}

.badgeText {
  color: white;
  padding: 0 6px;
}
@import '../../../mixin.scss';

.specialitiesArrow {
  width: 10px;
  height: 5px;
  align-self: center;
}

.specialitiesContainer {
  margin-left: 24px;
}

.specialitiesArrowBtn {
  @include noBackgroundButton();
  border: 1px solid #F0F0F0;
  border-radius: 5px;
  padding: 8px;
  display: flex;
  align-items: center;
}

.specialitiesArrowBtnLogedIn {
  @include noBackgroundButton();
  border: 0;
  padding: 8px 6px;
  display: flex;
  align-items: center;
}

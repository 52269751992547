@import '../../../mixin.scss';

.homeButtonContainer{
  width: 100%;
  max-width: 1000px;
  margin-top: 115px;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
}

.homeButton {
  color: #565553;
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding-left: 10px;
  font-size: 1.5em;
  text-decoration-line: underline;
  outline: 0;
}

.TERMINATED {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;

  .titleContainer {
    display: flex;
    flex-direction: column;
    padding-top: 54px;
    padding-left: 11px;
    padding-bottom: 14px;
  }

  .title {
    color: #9a4cb9;
    @include for-size(phone-only) {
      font-size: 22px;
    }
    @include for-size(all-desktop-up) {
      font-size: 40px;
    }
  }

  .subTitle {
    color: #565553;
    @include for-size(phone-only) {
      font-size: 17px;
      font-weight: normal;
    }
    @include for-size(all-desktop-up) {
      font-size: 24px;
      font-weight: normal;
    }
  }

  .homeButtonContainer {
    display: none;
  }
}

.NOTAVAILABLEFORPG {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;

  .titleContainer {
    display: flex;
    flex-direction: column;
    padding-top: 54px;
    padding-left: 11px;
    padding-bottom: 14px;
  }

  .title {
    color: #9a4cb9;
    @include for-size(phone-only) {
      font-size: 17px;
    }
    @include for-size(all-desktop-up) {
      font-size: 34px;
    }
  }

  .subTitle {
    color: #565553;
    @include for-size(phone-only) {
      font-size: 15px;
      font-weight: normal;
    }
    @include for-size(all-desktop-up) {
      font-size: 24px;
      font-weight: normal;
    }
  }
}

@import '../../../../mixin.scss';

.advisorTitleDescription {
  color: #7E7E7E;
  font-size: 20px;
  font-weight: 400;
  padding-top: 23px;
  padding-bottom: 40px;
  line-height: 26px;

  @include for-size(phone-only) {
    display: none;
  }
}

.advisorDescriptionSkeleton {
  @include skeletonContainer();
  width: 240px;
  height: 20px;
}

.advisorShortDescription {
  display: none;
  color: #7E7E7E;
  font-size: 13px;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 13px;
  line-height: 18px;

  @include for-size(phone-only) {
    display: flex;
  }
}

.advisorShortDescriptionSkeleton {
  @include skeletonContainer();
  width: 240px;
  height: 13px;
}

.seeAllItmeMobile,
.seeAllItmeMobile:hover {
  @include for-size(phone-only) {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7f7f7f;
    cursor: pointer;
  }
}


.sectionMainContainer {
  width: 100%;
}

.advisorsSectionContainer {
  margin-top: 80px;
}

.advisorsContainer {
  display: flex;
  overflow: visible;
}

.advisorsContainerScroller {
  display: flex;
  width: 100%;
  overflow: visible;
}

.advisorsContainerScroller::after {
  content: '*';
  width: 10px;
  max-width: 10px;
  display: flex;
  visibility: hidden;
}

.advisorsContainerScroller :first-of-type {
  margin-left: 0px;
}

.advisorTitleContainer {
  display: flex;
  flex-direction: column;
}

.advisorTitle {
  display: flex;
  align-items: center;
}

.advisorTitleImg {
  width: 50px;
  height: 50px;
}

.advisorTitleText {
  font-size: 38px;
  font-weight: 700;
  color: black;
  margin: 0;
  margin-left: 22px;
}

.advisorTitleTextSkeleton {
  @include skeletonContainer();
  width: 170px;
  height: 38px;
}


.seeAllBtn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  border-radius: var(--app-default-btn-lg-border-radius);
  background-color: white;
  border: 1px solid #EAEAEA;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  box-shadow: inset 0px -4px 0px rgba(202, 202, 202, 0.25);
  min-width: 80px;
}

.seeAllBtnTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.seeAllBtnRightArrow {
  margin-left: 6px;
}

.seeAllBtnTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.seeAllBtnRightArrow {
  margin-left: 6px;
}

.scrollLeftElementContainer {
  display: none;
}

.scrollRightElementContainer {
  display: none;
}

.seeAllItmeMobileContainer {
  display: none;
}

.arrowImg {
  svg {
    fill: #000000;
  }
 }

@include for-size(phone-only) {
  .seeAllBtn {
    display: none;
  }

  .sectionMainContainer {
    @include fullScreenWidth();
    display: flex;
    align-items: center;
    position: relative;
  }

  .advisorsContainer {
    display: flex;
    overflow-x: scroll;
    @include fullScreenWidth();
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
  }

  .advisorsContainerScroller {
    display: flex;
    overflow: unset;
  }

  .advisorTitleContainer {
    display: flex;
    flex-direction: column;
  }

  .advisorTitle {
    display: flex;
  }

  .advisorTitleImg {
    width: 25px;
    height: 25px;
  }

  .advisorTitleText {
    font-size: 17px;
    font-weight: 700;
    color: black;
    margin: 0;
    margin-left: 9px;
  }

  .advisorTitleTextSkeleton {
    @include skeletonContainer();
    width: 170px;
    height: 17px;
  }


  .scrollLeftElementContainer {
    display: none;
  }

  .scrollRightElementContainer {
    display: none;
  }

  .seeAllItmeMobileContainer {
    display: flex;
  }

  .seeAllItmeMobile {
    font-size: 24px;
    font-weight: 500;
  }
}

// all_advisors_section
.all_advisors_section {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 40px;
  transition: all 0.3s ease;
  .advisorTitleDescription {
    display: none;
  }

  .advisorShortDescription{
    display: none;
  }
// all_advisors_sectionMOBILE
  @include for-size(phone-only) {
    margin-top: 40px;
    margin-bottom: 13px;
  }
}

//PERSONAL

.personal {
  width: 100%;
  transition: all .3s ease;
  display: var(--displayRulePersonalBrowser);
  scrollbar-width: none;

  .advisorsSectionContainer {
    margin-top: 80px;
  }

  .sectionMainContainer {
    width: 100%;
  }

  .advisorsContainer {
    width: 100%;
    display: flex;
    overflow-x: hidden;
    overflow-y: visible;
  }

  .advisorsContainerScroller {
    display: flex;
    overflow-x: hidden;
    overflow-y: visible;
    width: 100%;
  }

  .advisorsContainerScroller :first-of-type {
    margin-left: 0px;
  }

  .advisorsContainerScroller::after {
    content: '*';
    width: 8px;
    max-width: 8px;
    display: flex;
    visibility: hidden;
  }

  .advisorTitleContainer {
    display: none;
    flex-direction: column;
  }

  .advisorShortDescription {
    display: none;
  }

  .seeAllBtn {
    display: none;
  }

  .scrollLeftElementContainer {
    display: none;
  }

  .scrollRightElementContainer {
    display: none;
  }

  .seeAllItmeMobile {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7f7f7f;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px;
    border: 1px solid #EAEAEA;
  }

  // PERSONALMobile
  @include for-size(phone-only) {
    display: var(--displayRulePersonalMobile);
    flex-direction: column;

    .sectionMainContainer {
      @include fullScreenWidth();
      display: flex;
      align-items: center;
      position: relative;
    }

    .advisorsContainer {
      display: flex;
      overflow-x: scroll;
      @include fullScreenWidth();
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 10px;
      padding-right: 10px;
    }

    .advisorsContainerScroller {
      display: flex;
      overflow: unset;
    }

    .advisorTitleContainer {
      display: flex;
      flex-direction: column;
    }

    .advisorTitle {
      display: flex;
      align-items: center;
    }

    .advisorTitleImg {
      width: 50px;
      height: 50px;
    }

    .advisorTitleText {
      font-size: 14px;
      font-weight: 700;
      color: black;
      margin: 0;
      margin-left: 0;
    }

    .advisorTitleTextSkeleton {
      @include skeletonContainer();
      width: 170px;
      height: 14px;
    }

    .advisorTitleDescription {
      display: none;
    }

    .seeAllItmeMobile {
      font-size: 18px;
      font-weight: 500;
      border-radius: 10px;
    }
  }
}

//.BADGE
.badge {
  width: 100%;
  margin-top: 80px;
  transition: all .3s ease;
  .advisorsContainerScroller {
    display: flex;
    overflow-x: hidden;
    overflow-y: visible;
    width: 100%;
  }

// BADGEMobile
  @include for-size(phone-only) {
    margin-top: 40px;

    .advisorsContainerScroller {
      display: flex;
      overflow: unset;
    }
  }
}

//.badge_end_to_end
.badge_end_to_end, .recommend_advisors {
  width: 100%;
  margin-top: 60px;
  transition: all .3s ease;

  .sectionMainContainer {
    @include fullScreenWidth();
    display: flex;
    align-items: center;
    position: relative;
  }

  .advisorsContainer {
    display: flex;
    overflow-x: scroll;
    @include fullScreenWidth();
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
  }

  .advisorsContainerScroller {
    display: flex;
    width: 100%;
    overflow: unset;
  }

  .advisorsContainerScroller :first-of-type {
    margin-left: 0px;
  }

  .advisorTitleContainer {
    display: flex;
    flex-direction: column;
  }

  .seeAllBtn {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    border-radius: var(--app-default-btn-lg-border-radius);
    background-color: white;
    border: 1px solid #EAEAEA;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    box-shadow: inset 0px -4px 0px rgba(202, 202, 202, 0.25);
    min-width: 80px;
  }

  .seeAllBtnTextContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .seeAllBtnRightArrow {
    margin-left: 6px;
  }

  .scrollLeftElementContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 42px;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 10;
  }

  .scrollRightElementContainer {
    position: absolute;
    top: 0;
    right: 0;
    width: 42px;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 10;
  }

  .scrollElementImgContainer {
    width: 42px;
    height: 77px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .advisorTitle {
    display: flex;
    align-items: center;
  }

  .advisorTitleImg {
    width: 50px;
    height: 50px;
  }

  .advisorTitleText {
    font-size: 38px;
    font-weight: 700;
    color: black;
    margin: 0;
    margin-left: 22px;
  }

  .advisorTitleTextSkeleton {
    @include skeletonContainer();
    width: 170px;
    height: 38px;
  }

  // badge_end_to_endMobile
  @include for-size(phone-only) {
    margin-top: 40px;

    .seeAllBtn {
      display: none;
    }

    .advisorsContainerScroller {}

    .advisorTitleContainer {
      display: flex;
      flex-direction: column;
    }

    .advisorTitle {
      display: flex;
    }

    .advisorTitleImg {
      width: 25px;
      height: 25px;
    }

    .advisorTitleText {
      font-size: 17px;
      font-weight: 700;
      color: black;
      margin: 0;
      margin-left: 9px;
    }

    .advisorTitleTextSkeleton {
      @include skeletonContainer();
      width: 170px;
      height: 17px;
    }


    .scrollLeftElementContainer {
      display: none;
    }

    .scrollRightElementContainer {
      display: none;
    }

    .seeAllItmeMobile {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

/* width */
.advisorsContainer::-webkit-scrollbar {
  display: none;
}

/* Track */
.advisorsContainer::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.advisorsContainer::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
.advisorsContainer::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.recommend_advisors {
  margin-top: 10px;
  .sectionMainContainer {
    width: 100%;
    margin-left: unset;
    padding: 30px;
  }
  .advisorsContainer {
    width: 100%;
    margin-left: unset;
    padding: 0;
  }
  .advisorsContainerScroller {
    gap: 30px;
    padding-bottom: 15px;
  }
  .scrollRightElementContainer {
    right: -50px;
  }
  .scrollLeftElementContainer {
    left: -50px;
  }
  .scrollElementImgContainer {
    display: flex;
    width: 44px;
    height: 44px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #E4E4E4;
  }
  .advisorTitleContainer {
    padding: 0 30px;
    @include for-size(phone-only) {
      padding: 0;
    }
  }
  .advisorTitleText {
    font-size: 22px;
    @include for-size(phone-only) {
      font-size: 17px;
    }
  }
  .advisorTitleDescription {
    font-size: 18px;
    font-weight: 300;
    padding: 0;
  }
 .advisorShortDescription {
  padding-top: 0;
  font-size: 12px;
 }
 .arrowImg {
    path {
    fill: var(--app-color)
  }
 }
}


@import "../common_mixin.scss";

.wpccContainer {
  border-width: 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  line-height: 1.5em;
  -webkit-transition: opacity 1s ease;
  -moz-transition: opacity 1s ease;
  -ms-transition: opacity 1s ease;
  -o-transition: opacity 1s ease;
  overflow: hidden;
  position: fixed;
  transition: opacity 1s ease;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.wpccContent {
  @include defaulContentWidth();
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  color: black;
  flex-direction: row;
  margin-bottom: 50px;
  padding: 18px;
  align-items: center;
  display: flex;
  align-items: center;
  @include for-size(phone-only) {
    margin: 7px;
    border-radius: 10px;
  }
}

.wpccMessage {
  font-weight: 500;
  font-size: 18px;
  display: block;
  flex: 1 1 auto;
  margin-right: 1em;
  max-width: 100%;
  color: black;
  @include for-size(phone-only) {
    font-weight: 500;
    font-size: 13px;
  }
}

.wpccPrivacy {
  font-weight: 500;
  font-size: 18px;
  display: inline-block;
  opacity: 0.85;
  color: var(--app-color);
  @include for-size(phone-only) {
    font-weight: 500;
    font-size: 13px;
  }
}

.wpccBtn {
  display: block;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 22px;
  text-align: center;
  white-space: nowrap;
  color: black;
  background: #FFFFFF;
  border: 1px solid #D7D7D7;
  box-shadow: inset 0px -4px 0px rgba(202, 202, 202, 0.25);
  border-radius: 8px;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -ms-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  cursor: pointer;
  margin-left: 60px;
  @include for-size(phone-only) {
    font-size: 15px;
    margin-left: 10px;
    padding: 9px 12px;
  }
}

a {
  color: var(--app-color);
}

.wpccIcon {
  display: flex;
  margin-right: 15px;
  width: 30px;
  height: 30px;
  @include for-size(phone-only) {
    width: 24px;
    height: 24px;
    margin-right: 11px;
  }
}

@import "../../../mixin.scss";

$maxWidth: 370px;

.avatarBrowserContainer {
  position: relative;
  width: 270px;
  height: 270px;
}

.rating {
  display: flex;
  background-color: #FFECBC;
  align-items: center;
  padding: 5px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 51px;
  font-weight: 500;
  font-size: 20px;
}

.advsiorReadingsContainer {
  display: flex;
  font-weight: 700;
  font-size: 26px;
  margin-top: 15px;
  align-items: flex-end;
  line-height: 42px;

  @include for-size(phone-only) {
    border: 1px solid #EAEAEA;
    font-size: 13px;
    margin-top: 19px;
    justify-content: space-between;
    padding-left: 13px;
    padding-right: 13px;
    border-radius: 5px;
    height: 55px;
    align-items: center;
    max-width: $maxWidth;
    width: 100%;
    min-width: 300px !important;
    line-height: 1;
  }
}

.topIconsContainer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  z-index: 11;

  @include for-size(phone-only) {
    display: none;
  }
}

.reviewItem {
  display: flex;
  font-size: 26px;
  font-weight: 700;
  align-items: center;
  margin-left: 54px;

  @include for-size(phone-only) {
    margin-left: auto;
    font-size: 13px;
  }
}

.reviewImg {
  margin-right: 9px;
  width: 33px;
  height: 33px;

  @include for-size(phone-only) {
    margin-right: 6px;
    width: 20px;
    height: 20px;
  }
}

.reviewItemAdvisorHeader {
  @include for-size(phone-only) {
    margin-left: auto;
  }
}

.reviewItemReviewsSection {
  .reviewItem {
    font-size: 20px;
    margin-left: 0;

    @include for-size(phone-only) {
      font-size: 15px;
    }
  }

  .reviewImg {
    width: 23px;
    height: 23px;

    @include for-size(phone-only) {
      margin-right: 6px;
    }
  }
}

.fixedContainerBrowser {
  display: var(--display);
  background-color: #FFFFFF;
  position: fixed;
  z-index: 20;
  top: var(--top);;
  left: 0;
  right: 0;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;

  @include for-size(phone-only) {
    display: none;
  }
}

.fixedTopContainerMobile {
  display: none;

  @include for-size(phone-only) {
    display: var(--display);
    background-color: #FFFFFF;
    position: fixed;
    z-index: 20;
    top: var(--top);
    left: 0;
    right: 0;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  }
}

.fixedTopContentMobile {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.fixedBottomContainerMobile {
  display: none;

  @include for-size(phone-only) {
    display: var(--display);
    position: fixed;
    z-index: 20;
    background-color: #FFFFFF;
    bottom: 0px;
    left: 0;
    right: 0;
    justify-content: center;
    padding-bottom: 26px;
    padding-top: 16px;
    padding-left: 13px;
    padding-right: 13px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.fixedContentBrowser {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 18px;
  padding-bottom: 18px;
  @include defaulContentWidth();
}

.fixedContainerAvatarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixedContainerAvatarHolder {
  width: 52px;
  min-width: 52px;
  height: 52px;

  @include for-size(phone-only) {
    width: 44px;
    min-width: 44px;
    height: 44px;
  }
}

.advisorStaticTitleContainer {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: 100%;

  @include for-size(phone-only) {
    margin-left: 9px;
  }
}

.advisorNameContainer {
  overflow: hidden;
}

.advisorNameText {
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;

  @include for-size(phone-only) {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
}

.advisorServiceTitleContainer {
  color: #A5A5A5;
  overflow: hidden;
}

.advisorServiceTitleText {
  color: #A5A5A5;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;

  @include for-size(phone-only) {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
}

.optionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ctaHolder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%
}

.topIconsContaner {
  display: flex;
  flex-direction: row;
  margin-left: 58px;

  @include for-size(phone-only) {
    margin-left: 23px;
  }
}

.ctaSkeletonContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-left: 14px;
  white-space: nowrap;
  max-width: 160px;
   min-width: 160px;
}

.ctaSkeleton {
  @include skeletonContainer();
  width: 160px !important;
  margin-left: 9px;
  height: 51px !important;

  @include for-size(phone-only) {
    width: 160px !important;
    margin-left: 9px;
    height: 39px !important;
  }

  @include for-size(small-phone-only) {
    width: 110px;
  }
}

.ctaSkeletonFullWidth {
  @include for-size(phone-only) {
    max-width: unset !important;
    width: 100% !important;
  }
}

.ctaSkeleton:first-of-type {
  margin-left: 0;
}

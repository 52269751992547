@import '../../mixin.scss';

.noFilterTitle {
  line-height: 30px;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  padding-bottom: 11px;
}

.advisorsContainer ~ .advisorsContainer {
  margin-top: 25px;
  @include for-size(tablet-portrait-up) {
    margin-top: 15px;
  }
}

.advisorsContainer {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 25px;

  @include for-size(tablet-portrait-up) {
    grid-column-gap: 10px;
    grid-row-gap: 13px;
  }
}

.filterButtonContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.cleanFiltersContainer {
  display: flex;
  height: 200px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.bottomButton {
  font-size: 17px;
  font-weight: 500;
  border-radius: var(--app-default-btn-lg-border-radius);
  padding: 13px;
  background-color: #ffffff;
  border: 1px solid #E0E0E0;
  cursor: pointer;
}

.cleanFiltersContainer {
  display: flex;
  height: 200px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.clearFilterIcon {
  padding: 8px;
}

// searchResult
.searchResult {
  width: 100%;

  @include for-size(phone-only) {
    width: 100%;

    .advisorsContainer {
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-column-gap: 10px;
      grid-row-gap: 10px;
    }

    .bottomButton {
      font-size: 15px;
      padding: 10px;
    }
  }

  @media (max-width: 575px) {
    .advisorsContainer {
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 10px;
      grid-row-gap: 10px;
    }
  }
}

//SOME_OTHER_ADVISOR
.someOtherAdvisor {
  width: 100%;
  margin-top: 15px;

  .advisorsContainer {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  @include for-size(phone-only) {
    width: 100%;

    .advisorsContainer {
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-column-gap: 10px;
      grid-row-gap: 10px;
    }
  }

  @media (max-width: 575px) {
    .advisorsContainer {
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 10px;
      grid-row-gap: 10px;
    }
  }
}


// homePageAllAdvisors
.homePageAllAdvisors {
  width: 100%;

  .advisorsContainer {
    margin-top: 8px;
  }

  .bottomButton,
  .bottomButton:hover {
    font-size: 15px;
    font-weight: 700;
    border-radius: var(--app-default-btn-lg-border-radius);
    padding: 10px;
    background-color: var(--app-color);
    border: none;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  @include for-size(phone-only) {
    width: 100%;

    .advisorsContainer {
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 10px;
      grid-row-gap: 10px;
    }
  }
}

@import '../../../../mixin.scss';

.personalSectionsContainer {
  width: 100%;
  margin-top: 96px;

  @include for-size(phone-only) {
    display: none;
  }
}

.personalSectionsMenuItemsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.personalSectionsMenuItemContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.personalSectionsMenuItemSelected {
  display: inline-block;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 700;
  line-height: 50px;
  padding: 0px;
  color: #000000;
  background-color: #ffffff;
  border: none;
  border-bottom: 4px solid var(--app-personal-section-border-color);
}

.personalSectionsMenuItem {
  display: inline-block;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 500;
  line-height: 50px;
  padding: 0px;
  color: #929292;
  background-color: #ffffff;
  border: none;
  border-bottom: 1px solid #E4E4E4;
  cursor: pointer;
  padding-bottom: 3px;
}

.personalSectionsAdvosorsContainer {
  margin-top: 28px;
}

.personalSectionsMenuContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #E4E4E4;
}

.smallDivider {
  width: 35px;
  height: 1px;
  background-color: #E4E4E4;
}

@import '../../../../mixin.scss';

@mixin staffPickTransition() {
    @media only screen and (max-width: 990px) {
        @content
    }
}

.mainContentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    margin-top: 80px;

    @include for-size(phone-only) {
        margin-top: 40px;
    }
}

.sectionHeader {
    display: flex;
    flex-direction: row;
    font-weight: 700;
    font-size: 38px;
    margin-bottom: 30px;
    align-items: center;
    transition: all .3s ease;

    @include for-size(phone-only) {
        font-size: 17px;
    }

    @include staffPickTransition() {
        margin-bottom: 10px;
    }
}

.sectionHeaderSeparator {
    width: 22px;
    transition: all .3s ease;

    @include for-size(phone-only) {
        width: 9px;
    }
}

.sectionIcon {
    width: 50px;
    height: 50px;
    transition: all .3s ease;

    @include for-size(phone-only) {
        width: 25px;
        height: 25px;
    }
}

.advisorContainer {
    border: solid #F1F1F1 1px;
    border-radius: 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    flex-direction: row;
    transition: all .3s ease;
    padding-left: 34px;
    padding-top: 34px;
    padding-bottom: 31px;
    padding-right: 45px;

    @include staffPickTransition() {
        flex-direction: column;
        padding: 10px;
    }

}

.avatarHolder {
    position: relative;
    width: 30%;
    transition: all .3s ease;
    min-height: 100%;
    min-width: 382px;

    @include staffPickTransition() {
        width: 100%;
        min-width: unset;
    }
}

.avatarContainer {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    transition: all .3s ease;

    @include staffPickTransition() {
        width: 100%;
        margin-right: 0px;
    }
}

.advisorInfoContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    margin-left: 31px;
    min-height: 100%;

    @include staffPickTransition() {
        margin-left: 0px;
    }
}

.advisorInfoHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    line-height: 1;
    transition: all .3s ease;

    @include staffPickTransition() {
        display: none;
    }
}

.advisorTitle {
    font-weight: 700;
    font-size: 34px;
    line-height: 51px
}

.advisorSubtitle {
    font-weight: 400;
    font-size: 22px;
    color: #696969;
    line-height: 33px
}

.rating {
    display: flex;
    background-color: #FFECBC;
    padding: 5px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 51px;
    font-weight: 500;
    font-size: 20px;
}

.footer {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    transition: all .3s ease;
    margin-bottom: 31px;

    @include staffPickTransition() {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 5px;
    }

    &.v2 {
        @include staffPickTransition() {
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

.staffPickDescription {
    padding-top: 20px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    transition: all 3s ease;
    word-break: break-all;

    @include staffPickTransition() {
        line-height: 22px;
        font-size: 15px;
        padding-bottom: 0px;
        height: unset;
    }
}

.advisorViewLink {
    width: 100%;
    color: black;
}

.advisorViewLink:hover {
    color: black;
}

.serviceTitleContainer {
  font-size: 15px;
  @include for-size(phone-only) {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0px !important;
  }
}

.previewAdvisorCTAContainer {
    display: flex;
    width: 100%;
    @include for-size(tablet-portrait-up) {
        display: none;
      }
    @include for-size(phone-only) {
        display: none;
      }
}

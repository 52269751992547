@import '../../common_mixin.scss';

.container {
  display: flex;
  padding-top: 35px;
  padding-bottom: 45px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 600px;
  color: black;
  padding: 40px;
  background-color: var(--app-background-secondary-color);
  border: 1px solid var(--app-border-primary-color);
  border-radius: var(--app-payment-sources-border-radius, 20px);
  box-shadow: var(--app-default-box-shadow);
  @include for-size(phone-only) {
    width: inherit;
    max-width: 350px;
    padding: 20px;
  }
}

.applePayButton {
  display: flex;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: var(--app-apple-pay-style);
  height: 44px;
  margin-right: 20px;
  @include for-size(phone-only) {
    margin-right: 10px;
  }
}

.sourceIcon {
  width: 105px;
  height: 68px;
  margin-right: 20px;
  @include for-size(phone-only) {
    width: 48px;
    height: 32px;
    margin-right: 10px;
  }
}

.ccDetailsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 50px;
  font-size: 20px;
  font-weight: 600;
  padding-right: 160px;
  @include for-size(phone-only) {
    font-weight: 700;
    font-size: 12px;
    padding-right: 40px;
    padding-bottom: 30px;
  }
}

.ccLast4 {
  font-size: 35px;
  font-weight: 600;
  color: var(--app-text-primary);
  @include for-size(phone-only) {
    font-weight: 700;
    font-size: 24px;
  }
}

.ccDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--app-text-primary);
}

.ppDetails {
  display: flex;
  width: 100%;
  text-align: left;
  padding-bottom: 80px;
  @include for-size(phone-only) {
    padding-bottom: 48px;
    font-weight: 700;
    font-size: 24px;
  }
}


.isDefault {
  display: flex;
  width: 100%;
  align-self: center;
  color: var(--app-text-secondary);
  font-weight: 500;
  font-size: 19px;
  text-align: left;
  @include for-size(phone-only) {
    font-weight: 500;
    font-size: 14px;
  }
}

.setDefaultButton {
  @include noBackgroundButton();
  color: var(--app-po-mbv-text-color, var(--app-color));
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  font-size: 19px;
  text-decoration-line: underline;
  @include for-size(phone-only) {
    font-weight: 500;
    font-size: 14px;
  }
}

.removeItem {
  @include noBackgroundButton();
  color: #E34545;
  font-size: 14px;
  font-weight: 500;
}

.removeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 45px 30px 35px 30px;
  text-align: center;
  background-color: var(--app-background-secondary-color);
  @include for-size(phone-only) {
    padding: 30px 20px 24px 20px;
    max-width: 340px;
  }
}

.removeButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cancelRemove {
  @include noBackgroundButton();
  color: var(--app-text-primary);
  background: var(--app-background-secondary-color);
  border: 1px solid var(--app-border-primary-color);
  border-radius: var(--app-default-btn-lg-border-radius, 4px);
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding: 13px 0;
}

.confirmRemove {
  @include noBackgroundButton();
  background-color: var(--app-default-btn-color, var(--app-color));
  border-radius: var(--app-default-btn-lg-border-radius, 4px);
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.removeTitle {
  font-weight: 700;
  font-size: 22px;
  color: var(--app-text-primary);
  @include for-size(phone-only) {
    font-size: 16px;
  }
}

.removeText {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  padding: 35px 0 50px 0;
  color: var(--app-text-primary);
  @include for-size(phone-only) {
    padding: 30px 0 45px 0;
    font-size: 16px;
  }
}

.rootWallet {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--app-background-color);
  border: 1px solid #E4E4E4;
  border-radius: 40px;
  padding: 80px 10px;
  @include for-size(phone-only) {
    border: none;
    background-color: var(--app-background-color);
    border-radius: 0;
    padding: 0 20px;
  }
}

.sourceDetailsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 70px;
  @include for-size(phone-only) {
    padding-bottom: 40px;
  }
}

.sourceName {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 26px;
  color: var(--app-text-primary);
  @include for-size(phone-only) {
    font-weight: 700;
    font-size: 16px;
  }
}

.deleteIcon {
  width: 60px;
  @include for-size(phone-only) {
    width: 34px;
  }
}

.ellipsisPayPalName {
  font-weight: 600;
  font-size: 33px;
  word-break: break-all;
  color: var(--app-text-primary);
  @include for-size(phone-only) {
    font-size: 24px;
  }
}

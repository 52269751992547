@import '../../mixin.scss';

.rootContainer {
  display: flex;
  justify-content: center;
}

.container {
  max-width: 920px;
  width: 100%;
}

.contentDiv {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  border-radius: 8px;
  border: 1px solid var(--app-border-primary-color);

  @include for-size(phone-only) {
    border-radius: unset;
    border: unset;
  }
}

.listContent {
  background-color: var(--app-background-color);
  display: block;
}

.listItemContent, .arrowListItem {
  padding: 1em;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  color: var(--app-text-primary);
}

.arrowListItem:hover {
  color: var(--app-color);
  cursor: pointer;
  @include for-size(phone-only) {
    color: unset;
  }
}

.passwordInput {
  text-align: right;
  font-weight: bold;
  background-color: var(--app-background-color);
  font-size: 1rem;
  border: none;
}

.passwordInput:hover {
  text-align: right;
  font-weight: bold;
  background-color: var(--app-background-color);
  font-size: 1rem;
  border: none;
}

.passwordInput:focus {
  background-color: var(--app-background-color);
  border: none;
  outline: 0 !important;
}

.signOutContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.signOutContainerModal {
  display: flex;
  column-gap: 10px;
  // width: 100%;
}

.signOutButton {
  width: 157px;
  height: 50px;
}

.signOutButtonModal {
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  color: #121212;
  cursor: pointer;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  column-gap: 10px;
  border-radius: 10px;
  padding: 10px 15px;
  width: 100%;
}

.signOutButtonModal:hover {
  background: #f7f7f7;
}

.signOutImage {
  width: 24px;
  height: 24px;
  padding: 5px 0;
  margin-left: -8px;

}

.popoverLogoutImage {
  width: 24px;
  height: 24px;
}


.signOutButtonModal {
  .signOutImage {
      width: 20px;
      margin-left: -2px;
  }
}


.signOutImage :focus {
  color: var(--app-orange);
}

.arrow {
  color: var(--app-orange);
  width: 14px;
  height: 14px;
}

.notificationPreferencesSpinerContainer {
  width: 44px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listTitle {
  padding: 1em;
  background-color: var(--background-secondary);
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  border-bottom: solid 1px var(--app-border-primary-color);
  border-top: solid 1px var(--app-border-primary-color);
  @include for-size(phone-only) {
    flex-direction: column;
  }
}

.listContent:first-of-type {
  .listTitle:first-of-type {
    border-top: unset;
    @include for-size(phone-only) {
      border-top: 1px solid var(--app-border-primary-color);
    }
  }
}

.fullDivider {
  background-color: var(--app-border-primary-color);
  height: 1px;
}

.marginDivider {
  background-color: var(--app-border-primary-color);
  height: 1px;
  margin-left: 1em;
}

.signOutYesBtnContainer {
  border-right: solid var(--app-border-primary-color) 1px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
}

.signOutCancelBtnContainer {
  width: 100%;
}

.signOutYesButton, .signOutYesButton:hover, .filterYesButton:focus {
  color: var(--app-color);
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
}

.signOutCancelButton, .signOutCancelButton:hover, .signOutCancelButton:focus {
  color: var(--app-error-red);
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: solid var(--app-border-primary-color) 1px;
  text-align: center;
}

.signOutContentContainer {
  width: 100%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signOutHeader {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-top: 10px;
  font-size: 1.3em;
  color: var(--app-text-primary);;
}

.signOutBody {
  margin-bottom: 15px;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 15px;
}

.linkContainer {
  color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  display: flex;
}

.modalSignOutText {
  width: 100%;
}

.userEmailContainer {
  display: flex;
  align-items: center;
  color: var(--app-text-secondary);
  font-weight: 400;
  font-size: 14px;
  @include for-size(phone-only) {
    justify-content: space-between;
  }
}

.userEmailSentToContainer {
  display: flex;
  align-items: center;
  font-size: 14px;
  @include for-size(phone-only) {
    width: 100%;
  }
}

.userEmailText {
  color: var(--app-text-primary);
  padding-left: 10px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: end;
  @include for-size(phone-only) {
    width: 100%;
  }
}

.userEmailText::before {
  content: " ";
}

@import '../../mixin.scss';

.psychicApplyHerModalContent {
  align-self: center;
}

.psychicApplyHerModalXContent {
  color: #b7bcbd;
  border: none;
  display: flex;
  align-content: center;
  justify-content: flex-end;
}

.psychicApplyHerModalXButton, .psychicApplyHerModalXButton:hover, .psychicApplyHerModalXButton:focus {
  color: #b7bcbd;
  border: none;
  margin-right: 10px;
  margin-top: 10px;
}

.psychicApplyHerModalHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  color: black;
}

.psychicApplyHerModalBody {
  margin-bottom: 15px;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 15px;
}

.psychicApplyHerModalLink{
  color: var(--app-color);
  white-space: nowrap;
}

.psychicApplyHerModalButton, .psychicApplyHerModalButton:hover, .psychicApplyHerModalButton:focus {
  background-color: var(--app-color);
  color: white;
  border: none;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  width: 80%;
}

.menuDividers {
  border: 0;
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
}

.languageSign {
  color: var(--app-color);
  align-self: flex-end;
  text-transform: capitalize;
}

@mixin cashback {
  padding-left: 5px;
  padding-right: 5px;
  color: #fff;
  border-radius: 2px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.cashbackInactive {
  @include cashback();
  background-color: #b7bcbd;
}

.cashbackActive {
  @include cashback();
  background-color: #75cd55;
}

.sideMenu {
  padding-left: 24px;
  padding-top: 12px;
  bottom: 0;
  background-color: white;
  @include for-size(phone-only) {
    padding-left: 10px;
  }
}

.sideMenuHeaderLogoContainer {
  padding-left: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  @include for-size(phone-only) {
    padding-left: 10px;
  }
}

.sideMenuItem, .sideMenuItem:hover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.85);
}

.actionLink {
  color: black;
  cursor: pointer;
  width: 100%;
}

.actionLink:hover {
  color: var(--app-color);
}

.menuItemIcon {
  width: 16px;
  height: 16px;
}

.sideMenuItemTitleContainer {
  display: flex;
  align-items: center;
}

.menuItemTitle{
  color: #616c70;
  padding-left: 10px !important;
  font-size: 15px;
}

.menuItemTitleActive{
  color: var(--app-color);
  padding-left: 10px !important;
  font-size: 15px;
}

.menuItemTitleBold{
  color: #616c70;
  font-size: 14px;
  font-weight: 700;
}

.menuItemTitleBoldActive{
  color: var(--app-color);
  font-size: 14px;
  font-weight: 700;
}

.sideMenuItemContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px 0;
  margin-right: 20px !important;
}

.badgeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.drawer {
  width: 300px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: var(--display);
  flex-direction: column;
  z-index: 1000;
  background-color: white;
  overflow: scroll;
}

/* width */
.drawer::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.drawer::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.drawer::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
.drawer::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.drawerMask {
  display: var(--display);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86) 0s, width 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86) 0s;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}



@import '../../mixin.scss';

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-size(phone-only) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.sectionsMainContainer {
  width: 100%;
  // @include for-size(phone-only) {
  //   margin-top: 15px;
  // }
}

.specialitiesContainer {
  display: flex;
  align-items: center;
  overflow-x: hidden;
  margin-top: 80px;
  align-items: stretch;
  width: 100%;
  border-radius: 20px;
  box-shadow: var(--specialities-shadow);

  @include for-size(phone-only) {
    overflow-x: scroll;
    scrollbar-width: none;
    border-radius: 0px;
    @include fullScreenWidth();
    display: flex;
    align-items: stretch;
    position: relative;
    padding-left: 15px;
    margin-top: 20px;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }
}

// .specialitiesContainer::after {
//   @include for-size(phone-only) {
//     content: '*';
//     width: 10px;
//     max-width: 10px;
//     display: flex;
//     visibility: hidden;
//   }
// }

/* width */
.specialitiesContainer::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.specialitiesContainer::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.specialitiesContainer::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
.specialitiesContainer::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.speciality {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  cursor: pointer;
  line-height: 14px;
  border-radius: 8px;
  min-height: 100%;
  max-height: 217px;
  flex: 1;
}

.specialityName {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 20px;
  line-height: 1;

  @include for-size(phone-only) {
    font-size: 15px;
  }
}

.specialityPG {
  margin-left: 20px;

  @include for-size(phone-only) {
    margin-left: 8px;
  }

  @include for-size(tablet-portrait-up) {
    margin-left: 8px;
  }

  @include for-size(tablet-landscape-up) {
    margin-left: 8px;
  }
  @media only screen and (max-width: 750px) {
    $paddings: 20px;
    border-radius: 4px;
    min-width: calc((100% - 20px) / 3.5);
    max-width: calc((100% - 20px) / 3.5);
  }
}

.specialityKA {
  border-right: 1px solid var(--border-primary);
  border-radius: 0;

  @media only screen and (max-width: 750px) {
    $paddings: 20px;
    border-radius: 4px;
    min-width: calc((100% - 20px) / 3.5);
    max-width: calc((100% - 20px) / 3.5);
  }

}

.specialityKA:last-child {
  border: none;
}

.speciality:first-of-type {
  margin-left: 0;
}

.appBannersContainer {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 80px;
  flex-direction: row;

  @include for-size(phone-only) {
    flex-direction: column;
    align-items: center;
  }
}

.appBanner {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 20px;
  max-width: 100%;

  @include for-size(phone-only) {
    max-width: 666px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.appBanner:first-of-type {
  margin-right: 10px;

  @include for-size(phone-only) {
    margin-bottom: 10px;
  }
}

.poBannerGradient {
  background: linear-gradient(to bottom right, #5238A6, #B36AD6)
}

.ptBannerGradient {
  background: linear-gradient(to bottom right, #744DC1, #4BB3FF)
}

.appBannerTitle {
  color: #FFFFFF;
  font-size: 20px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.openAppLink {
  background-color: #FFFF;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 8px;
  color: #000000;
  font-weight: 500;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.promotionFooter {
  display: none;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--zIndexValue);

  @include for-size(phone-only) {
    display: flex;
  }
}

.allAdvisorsButtonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2.5rem;
  margin-top: 5rem;
  border: 0.063rem solid #EAEAEA;
  border-radius: 1.563rem;
  background: #f7f7f7;

  @include for-size(phone-only) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1.25rem;
    padding: 1rem;
    margin-top: 2rem;
    border-radius: 0.938rem;
  }
}

.allAdvisorsButton {
  width: 12.5rem;
}

.allAdvisorsButtonText {
  display: flex;
  flex-direction: row;
  column-gap: 0.313rem;
  align-items: center;
  color: var(--app-text-primary);

  @include for-size(phone-only) {
    flex-direction: column;
    justify-content: center;
  }
}

.promotionHolder {
  width: 100%;
  border-radius: 12px;
  display: flex;
  margin-top: 40px;

  @include for-size(phone-only) {
    justify-content: center;
    margin-top: 10px;
  }
}
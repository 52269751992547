@import '../common_mixin.scss';

.bluesnapDiv {
  width: 100%;
  height: 44px;
  display: block;
}

.bluesnapDiv::placeholder {
  font-size: 16px;
  font-weight: 400;
}

.bluesnapInputHolder {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: var(--blue-snap-input-holder-margin-top);
}

.expirationSecurityHolder {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: var(--blue-snap-input-holder-margin-top);
}

.expirationSecurityContaienr {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 11px;
}

.labelContainer {
  font-weight: 700;
  font-size: 12px;
  color: var(--blue-snap-label-text-color);
  margin-bottom: 4px;
}

@mixin defaultInput {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--blue-snap-text-color);
  width: 100%;
  height: var(--blue-snap-input-height);
  font-weight: 500;
  font-size: 17px;
  border-radius: var(--blue-snap-input-border-radius);
  padding-left: 13px;
  padding-right: 13px;
  outline-width: 0;
  outline: none;
  background-color: var(--blue-snap-background-color);
}

.defaultInput {
  @include defaultInput();
  border: solid 0.5px var(--blue-snap-input-border-color);
  box-shadow: inset 0px 2px 4px var(--blue-snap-input-border-shadow-color);
  border-bottom: var(--blue-snap-input-bottom-border, solid 0.5px var(--blue-snap-input-border-color));
}

.defaultInputError {
  @include defaultInput();
  border: solid 0.5px var(--blue-snap-input-error-color);
}

.defaultInput:focus {
  border-color: var(--blue-snap-input-border-focus-color);
  border-width: 1px;
}

.defaultInput[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.defaultInput::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: var(--blue-snap-placeholder-text-color);
}

.error {
  margin: 6px 0;
  color: var(--blue-snap-input-error-color);
  font-size: 11px;
}

.blueSnapFormContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.skeleton {
  @include skeletonContainer();
}

.infoButton {
  @include noBackgroundButton;
}

.infoImg {
  width: 22px;
  height: 22px;
}

.creditCardImg {
  width: 48px;
  height: 32px;
  margin-right: 11px;
}

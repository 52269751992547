.bluesnapDiv {
  width: 100%;
  height: 44px;
  display: block;
  color: var(--app-text-primary);
}

.bluesnapDiv::placeholder {
  font-size: 16px;
  font-weight: 400;
}

.checkoutDiv, .checkoutDiv:active, .checkoutDiv:focus, .checkoutDiv:hover {
  width: 100%;
  height: var(--blue-snap-input-height);
  display: block;
  background-color: transparent !important;
  background: transparent !important;
}

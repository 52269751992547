@import '../../../../mixin.scss';
@import '../staff_pick_section/classes.module.scss';
@import '../advisor_modes/classes.module.scss';

.priceSeparator {
    width: 3px;
}

.piceTextContainer {
    display: flex;
    align-items: center;
    margin-left: var(--pice-text-container-margin-left);
    @include for-size(phone-only) {
        margin-left: 0px;
    }
    @include for-size(tablet-portrait-up) {
        margin-left: 0px;
        padding-top: 5px;
    }
    @include for-size(tablet-landscape-up) {
        margin-left: 0px;
        padding-top: 5px;
    }
}

.discountCouponIcon {
  width: 21px;
  height: 21px;
  margin-right: 5px;
}

.textColumnContainer {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    flex-direction: column;
    display: flex;

    .priceSeparator {
       display: none;
    }

    .crosoutPrice {
        font-size: 12px;
        color: #636570;
        font-weight: 400;
        display: flex;
        text-decoration: line-through;

        @include for-size(phone-only) {
            font-size: 10px;
        }
    }
}

.textContainer {
    color: #000;
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    flex-direction: row;
    display: flex;

    @include for-size(phone-only) {
        margin-left: 0px;
        flex-direction: row;
        font-size: 13px;
    }

    @include for-size(tablet-portrait-up) {
        margin-left: 0px;
        flex-direction: row;
        padding-top: 5px;
    }

.minPriceText {
    display: var(--min-price-text-display);
    font-weight: 400;

    @include for-size(phone-only) {
        margin-right: 2px;
    }
}

.crosoutPrice {
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    flex-direction: column;
    display: flex;
    text-decoration: line-through;

    @include for-size(phone-only) {
        flex-direction: row;
        font-size: 13px;
    }
}
}

.staffPick {
    margin-left: 30px;
    &.v2 {
        margin-left: 0;

        .textColumnContainer {
            margin-left: 0;
        }
    }
    .textColumnContainer, .textContainer {
        font-size: 22px;
        font-weight: 700;
        line-height: 25px;
        flex-direction: column;
        display: flex;

        @include for-size(phone-only) {
            line-height: 20px;
          }

        @media only screen and (max-width: 800px) {
          margin-left: 5px;
          font-size: 17px;
        }

        @include staffPickTransition() {
           margin-left: 30px;
        }
    }

    .minPriceText {
        font-weight: 400;
    }
}

.recommendedAdvisor, .recommendedCarousel {
    width: unset;
    .textColumnContainer, .textContainer {
      font-size: 18px;
      line-height: 17px;
      flex-direction: var(--recommended-advisor-price-flex-direction);
      align-items: var(--recommended-advisor-price-align);
      gap: 5px;
      @include for-size(phone-only) {
       font-size: 13px;
       gap: 0px;
      }
    }
    .minPriceText {
        display: var(--min-price-text-display);
    }
}

.salePriceText {
    color: var(--app-sale-price-text-color);
  }

.salePriceTextWithCoupon {
    color: var(--app-color);
  }

@import '../../mixin.scss';

$bubbleCorners: 17px;

.chatContainerMobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F6F6F6;
}

.chatContainerBrowser {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  max-width: 920px;
  min-width: 366px;
  width: 100%;
  height: 75%;
  max-height: 667px;
  border-radius: 8px;
  overflow: hidden;
}

.chatHeaderContainer {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999999;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  flex-shrink: 0;
  padding: 4px 4px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  .avatar {
    margin-right: 0.5em;
    margin-left: 0.5em;
  }
}

.chatHeaderContainerBrowser {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 10;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  flex-shrink: 0;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  .avatar {
    margin-right: 0.5em;
    margin-left: 0.5em;
  }
}

.chatHeader {
  display: flex;
  align-items: center;
  width: 100%;
}

.chatMessagesContainer > :first-child {
  margin-top: auto !important;
}

.chatMessagesContainer {
  width: 100%;
  flex: 12;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  align-self: center;
  justify-self: center;
  border: solid #E7E7E7 1px;
  border-bottom: none;
  background-color: white;
  @include for-size(tablet-landscape-up) {
    max-width: 1000px;
  }
  @include for-size(big-desktop-up) {
    max-width: 1700px;
  }
}

.chatBaseBubbleContainer {
  max-width: 100%;
  display: flex;
  align-items: flex-end;
  border-radius: $bubbleCorners;
}

@mixin messageContainer {
  display: flex;
  flex-shrink: 0;
  position: relative;
  align-items: flex-start;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.chatSystemMessageContainer {
  @include messageContainer();
  max-width: 90%;
  align-self: center;

  @include for-size(phone-only) {
    max-width: 100%;
  }

  .chatSystemBubbleContainer {
    background:  #E8F3F6;
    border: 1.2px dashed #98CBD9;
    color: black;

    p {
      margin-bottom: 0;
      color: black;
    }

    ul {
      display: flex;
      flex-direction: column;
      margin: 0;
    }

    ul :last-child {
      padding-bottom: 0;
    }

    li {
      padding-bottom: 15px;
    }
  }
}

.chatOwnMessageContainer {
  @include messageContainer();
  justify-content: flex-end;
  max-width: 80%;
  align-self: flex-end;

  @include for-size(phone-only) {
    margin-right: 0.5em;
  }

  .chatOwnBubbleContainer {
    background: var(--app-color);
    color: white;
    margin-right: 4px;
  }
}

.chatAdvisorMessageContainer {
  @include messageContainer();
  justify-content: flex-start;
  max-width: 80%;
  align-self: flex-start;

  @include for-size(phone-only) {
    margin-left: 0.5em;
  }

  .chatAdvisorBubbleContainer {
    background: #ededed;
    color: black;
    margin-left: 4px;
  }
}

.avatar {
  flex-shrink: 0;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
}

.bubbdleText {
  max-width: 100%;
  padding: 20px;
  overflow-wrap: break-word;
  white-space: pre-line;
  font-size: 15px;
  @include for-size(phone-only) {
    padding: 15px;
  }
}

@mixin sendBtnStyle {
  display: block;
  flex-shrink: 0;
  margin-right: 1.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 13px;
  color: rgba(0,0,0,.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
  border-radius: var(--app-default-btn-sm-border-radius);
  border: none;
  padding: 5px;
}

.chatSendContainer {
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #f4f4f4;
  flex-shrink: 0;
  border-top: 1px solid #e5e5e5;
  align-self: center;
  justify-self: center;
  @include for-size(tablet-landscape-up) {
    max-width: 1000px;
  }
  @include for-size(big-desktop-up) {
    max-width: 1700px;
  }

  .chatSendArea {
    margin: 1em;
    flex-grow: 1;
    border: 1px solid #d9d9d9;
    outline: 0;
    padding: 10px;
  }

  .chatSendArea:hover {
    margin: 1em;
    flex-grow: 1;
    border: 1px solid #d9d9d9;
  }

  .chatSendArea:focus {
    margin: 1em;
    flex-grow: 1;
    border: 1px solid #d9d9d9;
    box-shadow: none;
  }

  .sendBtn {
    @include sendBtnStyle();
  }
  .sendBtn:enabled {
    @include sendBtnStyle();
    background: var(--app-color);
    color: #fff;
    cursor: pointer;
  }
}

.typingContainer {
  width: 100%;
  min-height: 40px;
  font-size: 10px;
  padding-left: 2.8em;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include for-size(phone-only) {
    padding-left: 0.8em;
  }
}

.nickname {
  margin-left: .5em;
}

.hangupBtn {
  padding: 0.5em 1em;
  border: 1px solid var(--app-color);
  color: var(--app-color);
  border-radius: var(--app-default-btn-xs-border-radius);
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  @include for-size(phone-only) {
    font-size: 11px;
  }
  outline: 0;
}

.hangupImg {
  margin-right: 4px;
}

@mixin timetoken {
  font-size: 12px;
  float: right;
  text-align: end;
  padding-left: 0.3rem;
  padding-bottom: 0.3rem;
  position: relative;
  bottom: -0.5rem;
}

.advisorTimeToken {
  @include timetoken();
  color: #868686;
}

.clientTimeToken {
  @include timetoken();
  color: #d1bcff;
}

.advisorNameText {
  color: #000000;
  font-size: 16px;
}

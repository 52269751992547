
@mixin fixPosition {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  z-index: 1000;
}

.generalLoadingOverlayContainer {
  background: var(--app-overlay-black);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  @include fixPosition();
}

.generalLoadingOverlayLoaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.autoRedemePromoCodeBonusPopupContainer {
  width: 100%;
}

.autoRedemePromoCodeBonusPopupHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  font-size: 1.3em;
  color: black;
  padding-left: 10px;
  padding-right: 10px;
}

.autoRedemePromoCodeBonusPopupDescription {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 15px;
  word-break: break-word;
}

.autoRedemePromoCodeBonusPopupFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: solid gray 1px;
  text-align: center;
}

.autoRedemePromoCodeBonusPopupOkButton {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  border: none;
  background-color: transparent;
  color: var(--app-color);
  cursor: pointer;
}

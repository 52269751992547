.radioGroupContainer {
  width: 100%;
  display: flex;
  align-items: center;
  border: solid 1px #DDDDDD;
  border-radius: 50px;
  overflow: hidden;
}

@mixin radioBtn {
  width: 100%;
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: solid 1px #DDDDDD;
  cursor: pointer;
}

.radioBtn {
  @include radioBtn();
}

.radioBtn:first-child {
  border-left: none;
}

.radioBtnChecked {
  @include radioBtn();
  background: var(--app-color);
}

.radioBtnChecked:first-child {
  border-left: none;
}

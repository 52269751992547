@import '../../../mixin.scss';

.footerHolder {
  background: var(--background-secondary);
  position: relative;
  margin-top: 84px;
  @include fullScreenWidth();
}

.footerContent {
  @include defaulContentWidth();
  margin: 0 auto;
  padding: 70px 0px 50px 0px;
  overflow: hidden;
  position: relative;
  @include for-size(phone-only) {
    margin: 0 10px;
    padding: 30px 0px 55px 0px;
  }
}

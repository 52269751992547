@import '../../mixin.scss';
@import "../home_page/nested_views/staff_pick_section/classes.module.scss";

.imgContainerHolder {
  position: relative;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.statusLabel {
  display: flex;
  color: white;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  padding: 2px 25px 2px 25px;

  @include for-size(phone-only) {
    font-size: 10px;
    padding: 3px 7px 2px 7px;
  }
}

@mixin status($color) {
  width: 100%;
  min-height: 2px;
  border-radius: 31px;
  background-color: $color;
  z-index: 10;
  display: flex;
  justify-content: center;
}

.imgContainerHolder::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.imgContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-self: center;
  align-items: center;
  flex-direction: column;
}

.statusContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
}

.indicatorContainer {
  border-radius: 20px;
  justify-content: center;
  align-self: center;
  z-index: 9;
  position: absolute;
  padding: 3px;
  background-color: #FFFFFF;
}

.onlineIndicator {
  @include status($color: var(--app-online-indicator-color))
}

.offlineIndicator {
  @include status($color: var(--app-offline-indicator-color));
}

.busyIndicator {
  @include status($color: var(--app-busy-indicator-color));
}

.indicatorSkeleton {
  @include skeletonContainer();
  @include status($color: 'transpared');
  min-width: 101px;
  min-height: 24px;
  @include for-size(phone-only) {
    min-width: 52px;
    min-height: 20px;
  }
}

@mixin theme($theme) {
  @if $theme==staff-pic {
    display: none;
  }
}

.newAdvisorText {
  font-size: 13px;
  font-weight: 700;
  color: #FFFFFF;
  padding-left: 3px;
}

.ratingText {
  padding-left: 6px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #000000;
}

.ratingTextSkeleton {
  @include skeletonContainer();
  width: 20px;
  height: 20px;
}

.ratingContainer {
  display: flex;
  align-items: center;
  padding: 5px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 51px !important;
  font-weight: 500;
  font-size: 20px;
  height: 36px;
  min-width: 83px;
  border: 1px solid  #EAEAEA;
  background: var(--app-rating-bg-color);

  &.new {
    background: var(--app-new-advisor-badge-color);
  }

  @include for-size(phone-only) {
    height: 32px;
    font-size: 17px;
    padding-right: 11px;
    padding-left: 11px;
    
  }
}

.ratingContainerSkeleton {
  @include skeletonContainer();
}

.advisorNickNameContainer {
  color: #ffffff;
}

.serviceTitleContainer {
  color: #ffffff;
}

.rateAndFavouriteContainer {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0) 70%);

  .ratingContainer:not(.new) {
    background: var(--app-rating-on-img-bg-color);
  }
}

.playVideoBtnContainerHolder {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 36px;
  @include for-size(phone-only) {
    min-height: 20px;
  }
}

$playVideoImgWidthMobile: 10px;
$playVideoImgWidthBrowser: 23px;

.playVideoBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 44px;
  height: 44px;
  right: calc((44px - #{ $playVideoImgWidthBrowser }) / -2  );
  @include for-size(phone-only) {
    right: calc((44px - #{ $playVideoImgWidthMobile }) / -2  );
  }
}

.playVideoImg {
  width: #{$playVideoImgWidthBrowser};
  height: 30px;

  @include for-size(phone-only) {
    width: #{$playVideoImgWidthMobile};
    height: 14px;
  }
}

.nickNameAndServiseTitleConatiner {
  @include for-size(phone-only) {
    padding-bottom: 14px !important;
    padding-left: 10px !important;
    padding-right: 5px !important;
  }
}

.readingSinceAndCountItemContainer {
  display: flex;
  width: 100%;
}

.starImg {
  width: 21px;
  height: 19px;
}

// PERSONAL
.personal {
  width: 100%;
  height: 100%;

  .statusLabel {
    display: none;
  }

  .statusContainer {
    height: 6px;
  }

  .indicatorContainer {
    top: -6px;
    width: 70%;
  }

  .indicatorSkeleton {
    @include skeletonContainer();
    @include status($color: 'transpared');
    min-width: 62px;
    min-height: 3px;
    @include for-size(phone-only) {
      min-width: 58px;
      min-height: 3px;
    }
  }

  .rateAndFavouriteContainer {
    display: none;
  }

  .nickNameAndServiseTitleConatiner {
    display: none;
  }



  .onlineIndicator {
    height: 3px
  }

  .offlineIndicator {
    height: 3px
  }

  .busyIndicator {
    height: 3px
  }

  .statusContainer {
    height: 3px;
  }

  .indicatorContainer {
    top: -4px;
    width: 70%;
  }

  // PERSONALMobile
  @include for-size(phone-only) {
    .rateAndFavouriteContainer {
      position: absolute;
      top: 0px;
      padding: 5px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      z-index: 11;
      width: 100%;
      border-radius: 10px;
    }

    .playVideoBtnContainer {
      display: none;
    }

    .ratingContainer {
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 3px;
      padding-bottom: 3px;
      border-radius: 51px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 15px;
      min-width: 42px;
    }

    .newAdvisorText {
      font-size: 8px;
      font-weight: 700;
      color: #FFFFFF;
      padding-left: 3px;
      line-height: 1;
    }

    .starImg {
      width: 8px;
      height: 8px;
    }

    .ratingText {
      padding-left: 2px;
      font-size: 10px;
      font-weight: 500;
      line-height: 1;
      color: #000000;
    }

    .favouriteContainer {
      display: none;
    }

    .nickNameAndServiseTitleConatiner {
      display: none;
    }

    .statusContainer {
      height: 4px;
    }

    .indicatorContainer {
      top: -4px;
    }
  }
}

// BADGE
.badge {
  width: 100%;

  .imgContainerHolder {
    position: relative;
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-radius: 0;
    overflow: hidden;
  }

  .image {
    width: 100%;
    border-radius: 0px;
    overflow: hidden;
  }

  .rateAndFavouriteContainer {
    position: absolute;
    top: 0px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 11;
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .ratingContainer {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 51px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 36px;
    min-width: 83px;
  }

  .starImg {
    width: 18px;
    height: 18px;
  }

  .ratingText {
    padding-left: 6px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    color: #000000;
  }

  .newAdvisorText {
    font-size: 13px;
    font-weight: 700;
    color: #FFFFFF;
    padding-left: 3px;
    line-height: 1;
  }

  .favouriteContainer {
    display: none;
  }

  .favoriteImg {
    width: 28px;
    height: 26px;
  }

  .nickNameAndServiseTitleConatiner {
    position: absolute;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 25px;
    line-height: 24px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%);
    padding-top: 10px;
  }

  .nickNameContainer {
    width: 100%;
    max-width: 100%;
    display: unset;
  }

  .nickNameText {
    width: 100%;
    max-width: 100%;
    font-size: 26px;
    font-weight: 700;
    color: #ffffff;
    line-height: normal;
  }

  .serviceTitleContainer {
    width: 100%;
    max-width: 100%;
    display: unset;
  }

  .serviceTitleText {
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: #E4E4E4;
  }


  // BADGEMobile
  @include for-size(phone-only) {
    .imgContainerHolder {
      position: relative;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-radius: 0;
      overflow: hidden;
    }

    .image {
      width: 100%;
      border-radius: 0px;
      overflow: hidden;
    }

    .rateAndFavouriteContainer {
      position: absolute;
      top: 0px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      z-index: 11;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .ratingContainer {
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 6px;
      padding-bottom: 6px;
      border-radius: 51px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 20px;
      min-width: 44px;

      @include staffPickTransition() {
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }

    .starImg {
      width: 10px;
      height: 10px;

      @include staffPickTransition() {
        width: 10px;
        height: 10px;
      }
    }

    .ratingText {
      padding-left: 6px;
      font-size: 10px;
      font-weight: 500;
      line-height: 1;
      color: #000000;

      @include staffPickTransition() {
        padding-left: 3px;
        font-size: 10px;
      }
    }

    .newAdvisorText {
      font-size: 8px;
      line-height: 1;
    }

    .favoriteImg {
      width: 13px;
      height: 11px;
    }

    .advisorNickNameContainer {
      display: none;
    }

    .serviceTitleContainer {
      display: none;
    }
  }
}

// STAFF_PICK
.staff_pick {
  width: 100%;
  height: 100%;

  .rateAndFavouriteContainer {
    position: absolute;
    top: 0px;
    padding: 18px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 11;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .imgContainerHolder {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  };

  .favouriteContainer {
    display: none;
  }

  .ratingContainer {
    display: none;
  }

  .newAdvisorText {
    display: none;
  }

  .favoriteImg {
    width: 28px;
    height: 26px;
  }

  .nickNameAndServiseTitleConatiner {
    display: none;
  }

  .image {
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
  }

  // STAFF_PICKMobile
  @include for-size(phone-only) {
    display: flex;
    align-items: center;
    justify-content: center;

    .mainContainer {
      width: 100%;
      max-width: 355px;
      max-height: 355px;
    }

    .imgContainerHolder {
      position: relative;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      @include staffPickTransition() {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        max-width: 355px;
        max-height: 355px;
      }

      overflow: hidden;
    }

    .image {
      width: 100%;
      border-radius: 10px;
      overflow: hidden;

      @include staffPickTransition() {
        border-radius: 0px;
      }
    }

    .rateAndFavouriteContainer {
      position: absolute;
      top: 0px;
      padding: 18px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      z-index: 11;
      width: 100%;
      border-radius: 10px;
    }

    .ratingContainer {
      @include staffPickTransition() {
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 3px;
        padding-bottom: 3px;
        border-radius: 51px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 31px;
        min-width: 72px;
      }
    }

    .newAdvisorText {
      display: flex;
      font-size: 8px;
      font-weight: 700;
      color: #FFFFFF;
      padding-left: 3px;
    }

    .favouriteContainer {
      display: none;
    }

    .favoriteImg {
      width: 32px;
      height: 29px;
    }

    .nickNameAndServiseTitleConatiner {
      display: none;

      @include staffPickTransition() {
        position: absolute;
        z-index: 2;
        width: 100%;
        line-height: 1;
        display: flex;
        flex-direction: column;
        padding-left: 18px !important;
        padding-right: 18px !important;
        padding-bottom: 25px !important;
        padding-top: 10px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%);
      }
    }

    .nickNameContainer {
      width: 100%;
      max-width: 100%;
      display: unset;
    }

    .nickNameText {
      width: 100%;
      max-width: 100%;
      font-size: 22px;
      font-weight: 700;
      color: #ffffff;
      line-height: normal;
    }

    .serviceTitleContainer {
      width: 100%;
      max-width: 100%;
      display: unset;
      padding-top: 5px;
    }

    .serviceTitleText {
      width: 100%;
      max-width: 100%;
      font-size: 17px;
      font-weight: 400;
      color: #C6C6C6;
    }

    .readingSinceAndCountItemContainer {
      display: none;
    }

    .statusLabel {
      display: flex;
      color: white;
      font-size: 13px;
      font-weight: 700;
      text-align: center;
      padding: 2px 25px 2px 25px;
    }

    .playVideoImg {
      width: 23px;
      height: 30px;
    }
  }

  @include staffPickTransition() {
    .ratingContainer {
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 6px;
      padding-bottom: 6px;
      border-radius: 51px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .starImg {
      width: 16px;
      height: 16px;
    }

    .ratingText {
      padding-left: 6px;
      font-size: 17px;
      font-weight: 500;
      line-height: 1;
      color: #000000;
    }
  }
}

// badge_end_to_end
.badge_end_to_end {
  width: 100%;
  height: 100%;

  .statusLabel {
    display: none;
  }

  .imgContainerHolder {
    position: relative;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
  }

  .image {
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
  }

  .rateAndFavouriteContainer {
    position: absolute;
    top: 0px;
    padding: 18px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 11;
    width: 100%;
    border-radius: 15px;
  }

  .ratingContainer {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 51px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 24px;
    min-width: 56px
  }

  .starImg {
    width: 13px;
    height: 12px;
  }

  .ratingText {
    padding-left: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: #000000;
  }

  .newAdvisorText {
    display: flex;
    font-size: 13px;
    font-weight: 700;
    color: #FFFFFF;
    padding-left: 3px;
    line-height: 1;
  }

  .favouriteContainer {
    display: none;
  }

  .favoriteImg {
    width: 28px;
    height: 26px;
  }

  .nickNameAndServiseTitleConatiner {
    position: absolute;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 25px;
    padding-top: 10px;
    line-height: 24px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .nickNameContainer {
    width: 100%;
    max-width: 100%;
    display: unset;
  }

  .nickNameText {
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    line-height: normal;
  }

  .serviceTitleContainer {
    width: 100%;
    max-width: 100%;
    display: unset;
  }

  .serviceTitleText {
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #dadada;
  }

  .onlineIndicator {
    height: 6px;

    @include for-size(phone-only) {
      height: 4px;
    }
  }

  .offlineIndicator {
    height: 6px;

    @include for-size(phone-only) {
      height: 4px;
    }
  }

  .busyIndicator {
    height: 6px;

    @include for-size(phone-only) {
      height: 4px;
    }
  }

  .statusContainer {
    height: 6px;

    @include for-size(phone-only) {
      height: 4px;
    }
  }

  .indicatorContainer {
    top: -6px;
    width: 70%;

    @include for-size(phone-only) {
      top: -4px;
    }
  }

  .indicatorSkeleton {
    @include skeletonContainer();
    @include status($color: 'transpared');
    min-width: 152px;
    min-height: 6px;
    @include for-size(phone-only) {
      min-width: 60px;
      min-height: 4px;
    }
  }

  .playVideoBtnContainerHolder {
    min-height: 25px
  }

  .playVideoImg {
    width: 20px;
    height: 25px;
  }

  // badge_end_to_endMobile
  @include for-size(phone-only) {
    .imgContainerHolder {
      position: relative;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
    }

    .image {
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
    }

    .rateAndFavouriteContainer {
      position: absolute;
      top: 0px;
      padding: 5px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      z-index: 11;
      width: 100%;
      border-radius: 10px;
    }

    .playVideoBtnContainer {
      display: none;
    }

    .ratingContainer {
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 3px;
      padding-bottom: 3px;
      border-radius: 51px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 15px;
      min-width: 35px;
    }

    .starImg {
      width: 8px;
      height: 8px;
    }

    .ratingText {
      padding-left: 3px;
      font-size: 10px;
      font-weight: 500;
      line-height: 1;
      color: #000000;
    }

    .newAdvisorText {
      font-size: 8px;
      line-height: 1;
    }

    .favouriteContainer {
      display: none;
    }

    .favoriteImg {
      width: 13px;
      height: 11px;
    }

    .nickNameAndServiseTitleConatiner {
      display: none;
    }

    .statusContainer {
      height: 4px;
    }

    .indicatorContainer {
      top: -4px;
    }
  }
}

// SEARCH_RESULT
.searchResult,
.someOtherAdvisor,
.homePageAllAdvisors,
.recommendedCarousel {
  width: 100%;

  .imgContainerHolder {
    position: relative;
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-radius: 0;
    overflow: hidden;
  }

  .image {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    overflow: hidden;
  }

  .rateAndFavouriteContainer {
    position: absolute;
    top: 0px;
    padding: 18px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 11;
    width: 100%;
  }

  .ratingContainer {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 51px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 36px;
    min-width: 83px;
  }

  .starImg {
    width: 18px;
    height: 18px;
  }

  .ratingText {
    padding-left: 6px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    color: #000000;
  }

  .newAdvisorText {
    display: flex;
    font-size: 13px;
    font-weight: 700;
    color: #FFFFFF;
    padding-left: 3px;
    line-height: 1;
  }

  .favouriteContainer {
    display: none;
  }

  .favoriteImg {
    width: 28px;
    height: 26px;
  }

  .nickNameAndServiseTitleConatiner {
    position: absolute;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 25px;
    padding-top: 10px;
    line-height: 24px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%);
  }

  .nickNameContainer {
    width: 100%;
    max-width: 100%;
    display: unset;
  }

  .nickNameText {
    width: 100%;
    max-width: 100%;
    font-size: 26px;
    font-weight: 700;
    color: #ffffff;
    line-height: normal;
  }

  .serviceTitleContainer {
    width: 100%;
    max-width: 100%;
    display: unset;
  }

  .serviceTitleText {
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: #E4E4E4;
  }


  // SEARCH_RESULTMobile
  @include for-size(phone-only) {
    .imgContainerHolder {
      position: relative;
      width: 100%;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      overflow: hidden;
    }

    .image {
      width: 100%;
      border-radius: 0px;
      overflow: hidden;
    }

    .rateAndFavouriteContainer {
      position: absolute;
      top: 0px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      z-index: 11;
      width: 100%;
    }

    .ratingContainer {
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 3px;
      padding-bottom: 3px;
      border-radius: 51px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 21px;
      min-width: 50px;
    }

    .starImg {
      width: 10px;
      height: 10px;
    }

    .ratingText {
      padding-left: 3px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      color: #000000;
    }

    .newAdvisorText {
      font-size: 8px;
      line-height: 1;
    }

    .favoriteImg {
      width: 13px;
      height: 11px;
    }

    .advisorNickNameContainer {
      display: none;
    }

    .serviceTitleContainer {
      display: none;
    }
  }
}

.recommendedAdvisor {
  .ratingContainer {
    padding: 7px;
    padding-right: 10px;
    padding-left: 10px;
    min-width: unset;
    height: unset;
  }
  .ratingText {
    font-size: 16px;
    font-weight: 600;
  }
  .starImg {
    width: 17px;
    height: 16px;
  }
}

.recommendedCarousel {
  .imgContainerHolder {
    border-radius: 20px 0px 0px 20px;
  }
  .image {
    border-radius: 20px 0px 0px 20px;
  }
  @include for-size(phone-only) {
    .statusLabel {
      font-size: 8px;
      padding: 1px 7px 1px 7px;
    }
    .imgContainerHolder {
      border-radius: 0;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .image {
      border-radius: 0px;
    }
  }
}

// PROFILE_SMALL_AVATAR
.profileSmallAvatar {
  width: 100%;

  .imgContainerHolder {
    position: relative;
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
  }

  .image {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    overflow: hidden;
  }

  .rateAndFavouriteContainer {
    display: none;
  }

  .favoriteImg {
    width: 1px;
    height: 1px;
  }

  .nickNameAndServiseTitleConatiner {
    display: none;
  }

  .statusContainer {
    display: none;
  }

  // PROFILE_SMALL_AVATARMobile
  @include for-size(phone-only) {}
}


// ACTIVITY_AVATAR
.activityAvatar {
  width: 100%;

  .imgContainerHolder {
    width: 100%;
    border-radius: 6px;
  }

  .image {
    width: 100%;
    height: 100%;
    border-radius: 0;
    overflow: hidden;
  }

  .statusLabel {
    display: none;
  }

  .statusContainer {
    height: 8px;
  }

  .indicatorContainer {
    top: -6px;
    width: 70%;
  }

  .onlineIndicator {
    height: 9px
  }

  .offlineIndicator {
    height: 9px
  }

  .busyIndicator {
    height: 9px
  }

  .statusContainer {
    height: 0px;
  }

  .indicatorContainer {
    top: -7px;
    width: 60%;
  }

  .rateAndFavouriteContainer {
    display: none;
  }

  .ratingContainer {

  }

  .nickNameAndServiseTitleConatiner {
    display: none;
  }
}

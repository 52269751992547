@import '../../../../mixin.scss';

 .downloadAppPopupContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  max-width: 355px;
}

.downloadAppPopupTitle {
  font-weight: 700;
  font-size: 20px;
  margin-top: 30px;
}

.downloadAppPopupTitle {
  font-weight: 700;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImg {
  width: 62px;
  height: 62px;
}

.mobileBonusPopupBackgroundImgContainer {
  padding-top: 20px;
}

.mobileBonusPopupBackgroundImg {
  width: 100%;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 75%, transparent 100%);
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 75%, transparent 100%);
}

.downloadAppPopupText {
  white-space: pre-wrap;
  text-align: center;
  margin: 0 20px;
}

.boldText {
  font-weight: 700;
}

.normalText {
  font-weight: 400;
}

.purpleText {
  font-weight: 700;
  color: var(--app-color);
}

.downloadAppPopupCloseContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.downloadAppPopupCloseBtn {
  @include noBackgroundButton();
  width: 44px;
  height: 44px;
  cursor: pointer;
  margin-top: 0;
}


.downloadAppPopupCloseImg {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.downloadAppPopupCloseImg:hover {
    cursor: pointer;
  }

@mixin downloadAppPopupLink {
  @include defaultButton();
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}

.desktopContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 23px;
}

.qrCodeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.backgroundImg {
  width: 100%;
}

.qrCodeIcon {
  width: 19px;
  padding-right: 7px;
}

.qrCodeText {
  font-size: 13px;
}

.qrCodeTextContainer {
  white-space: pre-wrap;
  display: flex;
  align-items: center;
}

.downloadAppPopupLink, .downloadAppPopupLink:hover, .downloadAppPopupLink:active, .downloadAppPopupLink:focus {
  @include downloadAppPopupLink();
  width: 100%;
  margin-top: 60px;
  margin-bottom: 25px;
}

.bonusDownloadAppPopupLink, .bonusDownloadAppPopupLink:hover, .bonusDownloadAppPopupLink:active, .bonusDownloadAppPopupLink:focus {
  @include downloadAppPopupLink();
  position: absolute;
  bottom: 40px;
  max-width: 300px;
  @include for-size(phone-only) {
    bottom: 20px;
  }
}

@import '../../mixin.scss';

.container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  z-index: 1000;
  background-color: rgba($color: #2B2135, $alpha: 0.7);
  justify-content: center;
  align-items: center;
  display: flex;
}

.closeContainer {
  width: 50px;
  height: 50px;
  right: 0;
  top: 0;
}
.dialogContainerMobile {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--app-background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  text-align: center;
  color: var(--app-text-primary);
  z-index: 900;
  padding-left: 10px;
  padding-right: 10px;
}

.dialogContainer {
  width: 100%;
  min-height: 300px;
  background: var(--app-background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  text-align: center;
  max-width: 400px;
  min-height: 300px;
  min-width: 375px;
  padding-left: 20px;
  padding-right: 20px;
  color: var(--app-text-primary);
  @include for-size(phone-only) {
    min-height: 280px;
    min-width: 360px;
  }
}

.dialogContainerBr {
  content: '*';
  margin: 5px
}

.topImage {
  width: 57px;
  height: 57px;
}

.topImageAffiliatePromoPopup{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  border: solid 10px white;
}

.message {
  font-size: 17px;
  white-space: pre-wrap;
}

.smallInfoContainer{
  height: calc(100% - 44px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.smallInfoContainerAffiliatePromoPopup {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.smallInfo {
  font-size: 11px;
  color: black;
}

.confirmButton {
  background-color: var(--app-color);
  border-radius: var(--app-default-btn-sm-border-radius);
  height: 44px;
  color: #FFF;
  width: 80%;
  border: none;
  cursor: pointer;
  margin-bottom: 15px;
}

.confirmButtonA {
  background-color: var(--app-color);
  border-radius: var(--app-default-btn-sm-border-radius);
  height: 44px;
  color: #FFF;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center
}

.errorButtonContainer {
  background: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorA {
  background: none;
  border: none;
  display: flex;
  width: 100%;
  justify-content: center;
}

.botomContainer {
  width: 100%;
}

.redirectButton {
  color: var(--app-color);
  background: none;
  border: none;
  cursor: pointer;
}

.title {
  color: black;
  font-size: 17px;
}

.subtitle {
  font-size: 12px;
  color: #929292;
}

.cancelButton {
  color: var(--app-color);
  border-color: var(--app-color);
  border-width: 1px;
  border-radius: 4px;
  background: none;
  height: 100%;
  width: 100%;
}

.button {
  display: flex;
  border-radius: 4px;
  background: none;
  width: 50%;
  height: 100%;
  padding: 3px;
  cursor: pointer;
  border-radius: 4px;
}

.confirmRef {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--app-color);
  color: white;
  border-radius: 4px;
  height: 100%;
  width: 100%;
}

.buttonsContainer {
  justify-content: center;
  align-items: center;
  height: 77px;
  width: 100%;
  padding-top: 20px;
}

.closeButtonContent {
  border: none;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  width: 100%;
}

.closeButtonXButton, .closeButtonXButton:hover, .closeButtonXButton:focus {
  color: #b7bcbd;
  border: none;
  background-color: white;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  width: 44px;
  height: 44px;
}

.bottomRow{
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.bottomRowMobile {
  width: 100%;
  display: flex;
  justify-content: center;
}

.affiliatePromoPopupContactUsText {
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
}

.affiliatePromoPopupContactUsLink, .affiliatePromoPopupContactUsLink:hover {
  cursor: pointer;
  color: var(--app-color);
}

.affiliatePromoPopupBalance {
  font-size: 24px;
  font-weight: bold;
  margin-top: 30px;
}

.purpleText{
  color: var(--app-color);
  display:inline-block;
}

.normalText {
  color: #000000;
  font-weight: 400;
  margin: 0;
}

.affiliatePromoPopupMessage {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.spiner {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@use 'sass:math';
@import '../../../../mixin.scss';

.advisorViewLink {
  width: 100%;
  height: 100%;
}

.advisorServiceTitleContainer {
  display: none !important;
  @include for-size(phone-only) {
    width: 100%;
    max-width: 100%;
    display: inline-block !important;
    color: black
  }
}

.advisorServiceTitle {
  @include for-size(phone-only) {
   font-size: 12px;
   font-weight: 400;
  }
}

.serviceDescriptionContainer {
  width: 100%;
  max-width: 100%;
  min-height: 100px;
  margin-top: 13px !important;
  margin-bottom: 20px !important;
  @include for-size(phone-only) {
    line-height: 12px;
    min-height: 70px;
    margin-top: 5px !important;
    margin-bottom: 8px !important;
  }
}

.customTextStyle {
  @include for-size(phone-only) {
    line-height: 12px;
  }
}

.advisrServiceText{
  color: #565656;
  font-weight: 400;
  font-size: 18px;
  line-height: unset;
  @include for-size(phone-only) {
    font-size: 10px;
    line-height: 14px;
  }
}

.advisorNickNameContainer {
  @include for-size(phone-only) {
    line-height: 18px;
  }
}

.advisrNickName {
  @include for-size(phone-only) {
    line-height: 0px;
  }
}

.advisorServiceTitle {
  @include for-size(phone-only) {
    line-height: 0px;
  }
}

.advisorServiceTitleContainer {
  @include for-size(phone-only) {
    line-height: 18px;
  }
}

.advisornickNameAndServiceContainer {
  display: flex;
  flex-direction: column;
}

.advisorReadingSinceAndCountItemContainer {
  display: flex;
}

//PERSONAL
.personal {
  $phoneOnlyElementsCount: 2.5;
  $count: var(--advisorCount);

  $tabletPortraitUpElementsCount: $count;
  $tabletLandScapeUpElementsCount: $count;
  $desktopUpElementsCount: $count;
  $bigDesktopUpElementsCount: $count;
  $marginLeft: 8px;

  margin-left: $marginLeft;
  width: 97px;
  max-width: 97px;
  flex-shrink: 0;
  margin-left: $marginLeft;

  .advisorView {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: none;
    overflow: hidden;
    background-color: white;
    cursor: pointer;
  }

  .advisorView:first-of-type {
    margin-left: 0px;
  }

  .advisrServiceContainer {
    display: none;
  }

  .advisorNickNameContainer {
    display: none;
  }

  // PERSONALMobile
  @include for-size(phone-only) {
    $phoneOnlyElementsCount: 9.5;
    $marginLeft: 8px;

    $width: calc((100% - ((#{math.round($phoneOnlyElementsCount)} - 1) * #{$marginLeft})) / #{$phoneOnlyElementsCount});
    width: $width;
    min-width: $width;
    max-width: $width;

    .advisorView {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-left: $marginLeft;
      border: none;
      background-color: white;
    }

    .advisrServiceContainer {
      display: none;
    }

    .advisorNickNameContainer {
      width: 100%;
      max-width: 100%;
      display: inline-block;
    }
  }

  @media only screen and (max-width: 950px) {
    $phoneOnlyElementsCount: 8.5;
    $width: calc((100% - ((#{math.round($phoneOnlyElementsCount)} - 1) * #{$marginLeft})) / #{$phoneOnlyElementsCount});
    width: $width;
    min-width: $width;
    max-width: $width;
  }

  @media only screen and (max-width: 850px) {
    $phoneOnlyElementsCount: 7.5;
    $width: calc((100% - ((#{math.round($phoneOnlyElementsCount)} - 1) * #{$marginLeft})) / #{$phoneOnlyElementsCount});
    width: $width;
    min-width: $width;
    max-width: $width;
  }

  @media only screen and (max-width: 750px) {
    $phoneOnlyElementsCount: 6.5;
    $width: calc((100% - ((#{math.round($phoneOnlyElementsCount)} - 1) * #{$marginLeft})) / #{$phoneOnlyElementsCount});
    width: $width;
    min-width: $width;
    max-width: $width;
  }

  @media only screen and (max-width: 650px) {
    $phoneOnlyElementsCount: 5.5;
    $width: calc((100% - ((#{math.round($phoneOnlyElementsCount)} - 1) * #{$marginLeft})) / #{$phoneOnlyElementsCount});
    width: $width;
    min-width: $width;
    max-width: $width;
  }

  @media only screen and (max-width: 550px) {
    $phoneOnlyElementsCount: 4.5;
    $width: calc((100% - ((#{math.round($phoneOnlyElementsCount)} - 1) * #{$marginLeft})) / #{$phoneOnlyElementsCount});
    width: $width;
    min-width: $width;
    max-width: $width;
  }

  .bottomSection {
    display: none;
  }
}

//.BADGE
.badge {
  $phoneOnlyElementsCount: 2.5;
  $count: var(--advisorCount);

  $tabletPortraitUpElementsCount: $count;
  $tabletLandScapeUpElementsCount: $count;
  $desktopUpElementsCount: $count;
  $bigDesktopUpElementsCount: $count;
  $marginLeft: 20px;
  margin-left: $marginLeft;
  flex-shrink: 0;

  @include for-size(tablet-portrait-up) {
    $width: calc((100% - ((#{$tabletPortraitUpElementsCount} - 1) * #{$marginLeft})) / #{$tabletPortraitUpElementsCount});
    width: $width;
    max-width: $width;
  }

  @include for-size(tablet-landscape-up) {
    $width: calc((100% - ((#{$tabletLandScapeUpElementsCount} - 1) * #{$marginLeft})) / #{$tabletLandScapeUpElementsCount});
    width: $width;
    max-width: $width;
  }

  @include for-size(desktop-up) {
    $width: calc((100% - ((#{$bigDesktopUpElementsCount} - 1) * #{$marginLeft})) / #{$bigDesktopUpElementsCount});
    width: $width;
    max-width: $width;
  }

  @include for-size(big-desktop-up) {
    $width: calc((100% - ((#{$bigDesktopUpElementsCount} - 1) * #{$marginLeft})) / #{$bigDesktopUpElementsCount});
    width: $width;
    max-width: $width;
  }

  .advisorView {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 20px;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    background-color: white;
  }

  .advisorView:hover {
    box-shadow: 0px 4px 20px rgba(71, 74, 78, 0.08), 0px 2px 20px rgba(71, 74, 78, 0.14);
  }

  .advisorView:first-of-type {
    margin-left: 0px;
  }

  .advisrServiceContainer {
    width: 100%;
    padding-left: 20px;
    padding-right: 10px;
    margin-top: 25px;
    margin-bottom: 22px;
    overflow: hidden;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: #565656;
    display: inline-block;
  }

  .bottomSection {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .advisorServiseActionsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
  }

  .advisorServiseActionsIconsContainer {
    display: flex;
  }

  .advisorServiseActionsIcon {}

  .advisorServiseActionsMinPriceText {
    font-size: 18px;
    font-weight: 400;
  }

  .advisorServiseActionsMinPriceBoltText {
    font-size: 18px;
    font-weight: 700;
    color: #FF4949;
  }

  .advisorNickNameContainer {
    display: none;
  }

  // BADGEMobile
  @include for-size(phone-only) {
    $phoneOnlyElementsCount: 3.5;
    $marginLeft: 8px;
    margin-left: $marginLeft;

    $width: calc((100% - ((#{math.round($phoneOnlyElementsCount)} - 1) * #{$marginLeft})) / #{$phoneOnlyElementsCount});
    width: $width;
    min-width: $width;
    max-width: $width;

    .advisorView {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      border-radius: 10px;
      border: 1px solid #EAEAEA;
      box-sizing: border-box;
      background-color: white;
    }

    .advisorView:hover {
      box-shadow: none;
    }

    .advisrServiceContainer {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 14px;
      margin-bottom: 0px;
      overflow: hidden;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      color: #565656;
      display: inline-block;
    }

    .bottomSection {
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
    }


    .advisorServiseActionsContainer {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
    }

    .advisorServiseActionsIconsContainer {
      display: flex;
    }

    .advisorServiseActionsIcon {
      width: 48px;
      height: 48px;
      margin-left: 7px;
    }

    .advisorServiseActionsMinPriceText {
      font-size: 13px;
      font-weight: 400;
    }

    .advisorServiseActionsMinPriceBoltText {
      font-size: 13px;
      font-weight: 500;
      color: #000000;
    }

    .advisrNickName {
      width: 100%;
      max-width: 100%;
      font-size: 15px;
      font-weight: 700;
      color: #000000;
    }

    .advisorNickNameContainer {
      width: 100%;
      max-width: 100%;
      display: inline-block;
    }
}

  @media (max-width: 575px) {
    $phoneOnlyElementsCount: 2.5;
    $width: calc((100% - ((#{math.round($phoneOnlyElementsCount)} - 1) * #{$marginLeft})) / #{$phoneOnlyElementsCount});
    width: $width;
    min-width: $width;
  }
}

//.badge_end_to_end
.badge_end_to_end {
  $count: var(--advisorCount);

  $tabletPortraitUpElementsCount: $count;
  $tabletLandScapeUpElementsCount: $count;
  $desktopUpElementsCount: $count;
  $bigDesktopUpElementsCount: $count;
  $marginLeft: 20px;
  flex-shrink: 0;

  @include for-size(tablet-portrait-up) {
    width: calc((100% - ((#{$tabletPortraitUpElementsCount} - 1) * #{$marginLeft})) / #{$tabletPortraitUpElementsCount});
    max-width: 227px;
  }

  @include for-size(tablet-landscape-up) {
    width: calc((100% - ((#{$tabletLandScapeUpElementsCount} - 1) * #{$marginLeft})) / #{$tabletLandScapeUpElementsCount});
    max-width: 227px;
  }

  @include for-size(desktop-up) {
    width: calc((100% - ((#{$bigDesktopUpElementsCount} - 1) * #{$marginLeft})) / #{$bigDesktopUpElementsCount});
    max-width: 227px;
  }

  @include for-size(big-desktop-up) {
    width: calc((100% - ((#{$bigDesktopUpElementsCount} - 1) * #{$marginLeft})) / #{$bigDesktopUpElementsCount});
    max-width: 227px;
  }

  margin-left: $marginLeft;

  .advisorView {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 15px;
    border: none;
    box-sizing: border-box;
    background-color: white
  }

  .badge_end_to_end:first-of-type {
    margin-left: 0px;
  }

  .advisrServiceContainer {
    display: none;
  }

  .advisorServiseActionsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
  }

  .advisorServiseActionsIconsContainer {
    display: flex;
  }

  .advisorServiseActionsIcon {
    // margin-left: 10px;
  }

  .advisorServiseActionsMinPriceText {
    font-size: 18px;
    font-weight: 400;
  }

  .advisorServiseActionsMinPriceBoltText {
    font-size: 18px;
    font-weight: 700;
    color: #FF4949;
  }

  .advisorNickNameContainer {
    display: none;
  }

  .bottomSection {
    display: none;
  }

  // badge_end_to_endMobile
  @include for-size(phone-only) {
    $phoneOnlyElementsCount: 9.5;
    $marginLeft: 8px;

    $width: calc((100% - ((#{math.round($phoneOnlyElementsCount)} - 1) * #{$marginLeft})) / #{$phoneOnlyElementsCount});
    width: $width;
    min-width: $width;
    max-width: $width;
    margin-left: $marginLeft;

    .advisorView {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      border-radius: 10px;
      border: none;
      box-sizing: border-box;
      background-color: white;
    }

    .advisrServiceContainer {
      display: none;
    }

    .advisorServiseActionsContainer {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
    }

    .advisorServiseActionsIconsContainer {
      display: flex;
    }

    .advisorServiseActionsIcon {
      width: 48px;
      height: 48px;
      margin-left: 7px;
    }

    .advisorServiseActionsMinPriceText {
      font-size: 13px;
      font-weight: 400;
    }

    .advisorServiseActionsMinPriceBoltText {
      font-size: 13px;
      font-weight: 500;
      color: #000000;
    }

    .advisrNickName {
      width: 100%;
      max-width: 100%;
      font-size: 15px;
      font-weight: 700;
      color: #000000;
    }

    .advisorNickNameContainer {
      display: none;
    }
}

  @media only screen and (max-width: 950px) {
    $phoneOnlyElementsCount: 8.5;
    $width: calc((100% - ((#{math.round($phoneOnlyElementsCount)} - 1) * #{$marginLeft})) / #{$phoneOnlyElementsCount});
    width: $width;
    min-width: $width;
    max-width: $width;
  }

  @media only screen and (max-width: 850px) {
    $phoneOnlyElementsCount: 7.5;
    $width: calc((100% - ((#{math.round($phoneOnlyElementsCount)} - 1) * #{$marginLeft})) / #{$phoneOnlyElementsCount});
    width: $width;
    min-width: $width;
    max-width: $width;
  }

  @media only screen and (max-width: 750px) {
    $phoneOnlyElementsCount: 6.5;
    $width: calc((100% - ((#{math.round($phoneOnlyElementsCount)} - 1) * #{$marginLeft})) / #{$phoneOnlyElementsCount});
    width: $width;
    min-width: $width;
    max-width: $width;
  }

  @media only screen and (max-width: 650px) {
    $phoneOnlyElementsCount: 5.5;
    $width: calc((100% - ((#{math.round($phoneOnlyElementsCount)} - 1) * #{$marginLeft})) / #{$phoneOnlyElementsCount});
    width: $width;
    min-width: $width;
    max-width: $width;
  }

  @media only screen and (max-width: 550px) {
    $phoneOnlyElementsCount: 4.5;
    $width: calc((100% - ((#{math.round($phoneOnlyElementsCount)} - 1) * #{$marginLeft})) / #{$phoneOnlyElementsCount});
    width: $width;
    min-width: $width;
    max-width: $width;
  }
}

//SEARCH_RESULT

.searchResult,
.someOtherAdvisor,
.homePageAllAdvisors {
  $phoneOnlyElementsCount: 2.5;
  $advisorCount: var(--advisorCount);
  $marginLeft: 20px;

  .advisorView {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 15px;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    background-color: white;
  }

  .advisorView:hover {
    box-shadow: 0px 4px 20px rgba(71, 74, 78, 0.08), 0px 2px 20px rgba(71, 74, 78, 0.14);
  }

  .advisorView:first-of-type {
    margin-left: 0px;
  }

  .advisrServiceContainer {
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 25px;
    margin-bottom: 22px;
    overflow: hidden;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: #565656;
    display: inline-block;
    position: relative;
  }

  .bottomSection {
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 18px;
    align-items: flex-end;
    @include for-size(phone-only) {
      align-items: flex-start;
  }

  @include for-size(tablet-portrait-up) {
      align-items: flex-start;
  }

  @include for-size(tablet-landscape-up) {
    align-items: flex-start;
}
  }

  .advisorServiseActionsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
  }

  .advisorServiseActionsIconsContainer {
    display: flex;
  }

  .advisorServiseActionsIcon {
    // margin-left: 10px;
  }

  .advisorServiseActionsMinPriceText {
    font-size: 18px;
    font-weight: 400;
  }

  .advisorServiseActionsMinPriceBoltText {
    font-size: 18px;
    font-weight: 700;
    color: #FF4949;
  }

  .advisorNickNameContainer {
    display: none;
  }

  // SEARCH_RESULTMobile
  @include for-size(phone-only) {
    .advisorView {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      border-radius: 10px;
      border: 1px solid #EAEAEA;
      box-sizing: border-box;
      background-color: white
    }

    .advisorView:hover {
      box-shadow: 0px 4px 20px rgba(71, 74, 78, 0.08), 0px 2px 20px rgba(71, 74, 78, 0.14);
    }

    .advisrServiceContainer {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 14px;
      margin-bottom: 0px;
      overflow: hidden;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      color: #565656;
      display: inline-block;
      position: relative;
    }

    .bottomSection {
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
    }

    .advisorServiseActionsContainer {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
    }

    .advisorServiseActionsIconsContainer {
      display: flex;
    }

    .advisorServiseActionsIcon {
      width: 48px;
      height: 48px;
      margin-left: 7px;
    }

    .advisorServiseActionsMinPriceText {
      font-size: 13px;
      font-weight: 400;
    }

    .advisorServiseActionsMinPriceBoltText {
      font-size: 13px;
      font-weight: 500;
      color: #000000;
    }

    .advisrNickName {
      width: 100%;
      max-width: 100%;
      font-size: 15px;
      font-weight: 700;
      color: #000000;
    }

    .advisorNickNameContainer {
      width: 100%;
      max-width: 100%;
      display: inline-block;
    }
 }

  @media (max-width: 575px) {}
}

//Recommended advisor carousel

.recommendedCarousel {
    min-width: 140px;
    width: 100%;
    max-width: 150px;
    height: 100%;
    overflow: hidden;
    border-radius: 15px;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    background-color: white;

  .advisrServiceContainer {
    width: 100%;
    padding-left: 9px;
    padding-right: 9px;
    margin-top: 15px;
    overflow: hidden;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: #565656;
    display: inline-block;
    position: relative;
  }

  .bottomSection {
    padding-left: 9px;
    padding-right: 9px;
    padding-bottom: 9px;
  }

  .serviceDescriptionContainer {
    display: none;
  }

  .previewAdvisorCTAContainer {
    display: none;
  }

  .advisorServiseActionsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
  }

  .advisorServiseActionsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }

  .advisorServiseActionsIconsContainer {
    display: flex;
  }

  .advisorServiseActionsIcon {
    width: 48px;
    height: 48px;
    margin-left: 7px;
  }

  .advisorServiseActionsMinPriceText {
    font-size: 13px;
    font-weight: 400;
  }

  .advisorServiseActionsMinPriceBoltText {
    font-size: 13px;
    font-weight: 500;
    color: #000000;
  }

  .advisrNickName {
    width: 100%;
    max-width: 100%;
    font-size: 15px;
    font-weight: 700;
    color: #000000;
  }

  .advisorNickNameContainer {
    width: 100%;
    max-width: 100%;
    display: inline-block;
  }
}

.advisorFreeMinutes {
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  padding: 2px 5px;
  align-items: center;
  border-radius: var(--advisor-preview-xfm-label-border-radius);
  background: var(--advisor-preview-xfm-label-background);
  width: fit-content;
  display: flex;
  text-wrap: nowrap;
  svg {
    margin-right: 5px;
  }
  @include for-size(phone-only) {
    font-size: 8px;
    line-height: 15px;
  }
}

.wrapperFreeMinutes {
  width: 100%;
}


.bottomSection {
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  @include for-size(tablet-portrait-up) {
    flex-direction: column;
    align-items: flex-start;
  }
  @include for-size(phone-only) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.modesInfoContainer {
  display: flex;
  flex-direction: column;
}


.advisrService {
  display: flex;
  flex-direction: column;
}

.modesInfoContainer {
  display: flex;
  gap: 3px;
  flex-direction: column;
}

.notifyMeButton {
  @include noBackgroundButton();
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 10px;
  border: 1px solid #EFF1F9;
  gap: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  text-wrap: nowrap;
  white-space: nowrap;
  @include for-size (tablet-landscape-up) {
    padding-left: 10px;
    padding-right: 10px;
    gap: 5px;
  }
  @include for-size(tablet-portrait-up) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    gap: 5px;
  }
  @include for-size(phone-only) {
    width: 100%;
    font-size: 12px;
    gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 10px;
  }
}

.priceText {
  font-size: 16px;
  font-weight: 600;
  @include for-size(phone-only) {
    font-size: 12px;
  }
}

.previewAdvisorCTAContainer {
  width: fit-content;
  @include for-size(tablet-portrait-up) {
    width: 100%;
  }
  @include for-size(phone-only) {
    width: 100%;
  }
}

.modesInfoContainerWithIcons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
}

.previewAdvisorCTA,
.previewAdvisorCTA:hover {
  @include noBackgroundButton();
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 10px;
  gap: 10px;
  background-color: var(--app-color);
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  @include for-size (tablet-landscape-up) {
    padding-left: 10px;
    padding-right: 10px;
    gap: 5px;
  }
  @include for-size(tablet-portrait-up) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    gap: 5px;
  }
  @include for-size(phone-only) {
    width: 100%;
    font-size: 12px;
    gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 10px;
  }
}

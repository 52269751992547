@import "../../mixin.scss";

.orderCellContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 20px;
  height: 80px;
  border-radius: 20px;
  background-color: #ffffff;
  @include for-size(phone-only) {
    border-radius: 15px;
  }
}

.borderDefault {
  border: 1px solid var(--border-primary);
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.05);
}

.kaBorderChat {
  border: 1px solid var(--live-chat-channels);
  box-shadow: 0px 0px 10px 0px rgba(225, 225, 225, 0.50);
}

.kaBorderVoice {
  border: 1px solid var(--voice-call-channels);
  box-shadow: 0px 0px 10px 0px rgba(225, 225, 225, 0.50);
}

.orderCellContentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  justify-content: center;
}

.orderTitleLabelsContainer {
  display: flex;
  flex-direction: row;
}

.orderCellTitleContainer {
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.orderCellSubjectContainer {
  margin-left: 5px;
}

.labelsHolderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.orderCellLabelContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}

.orderDataContainer {
  width: max-content;
  display: flex;
  flex-direction: row;
  @include for-size(phone-only) {
    display: flex;
    justify-content: center;
  }
}

.orderCreatedAtContainer {
  color: #636570;
}

.orderDescriptionContainer {
  display: flex;
  align-items: center;
  color: #636570;
}

.orderDescriptionIcon {
  width: 20px;
  height: 20px;
  color: #7c7f89;
  margin-right: 5px;
}

.rightArrowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.orderCellIcon {
  width: 24px;
  height: 24px;
}

.orderSubjectName {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  @include for-size(tablet-landscape-up) {
    font-size: 18px;
  }
  @include for-size(tablet-portrait-up) {
    font-size: 16px;
  }
  @include for-size(phone-only) {
    font-size: 16px;
  }
}

.orderDescription {
  font-size: 17px;
  color: #636570;
  font-weight: 400;
  @include for-size(tablet-landscape-up) {
    font-size: 16px;
  }
  @include for-size(phone-only) {
    font-size: 16px;
  }
}

.orderRightDataContainer {
  display: flex;
  flex-direction: row;
}

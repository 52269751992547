@import '../common_mixin.scss';

.optionHolder {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.error {
  display: flex;
  color: #E34545;
  font-size: 10px;
  margin-top: 5px;
}
.noOptionsButton {
  @include noBackgroundButton;
  border: solid #CBCBCB 0.5px;
  height: 44px;
  border-radius: 4px;
  margin-bottom: 12px;
  background-color: var(--bgColor);
  color: white;
  font-weight: 500;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.availableOptionButton {
  @include noBackgroundButton;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: var(--app-background-secondary-color);
  border: 0.5px solid var(--app-border-primary-color);
  box-shadow: 0px 8px 16px rgb(0 0 0 / 3%);
  border-radius: 6px;
  padding: 22px;
  display: flex;
  justify-content: flex-end;
}

.availableOptionButtonTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
  font-size: 15px;
  font-weight: 700;;
}

.paymentArrow {
  width: 10px;
  height: 32px;
}

.optionName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  width: max-content;
  color: var(--app-text-primary);
}

.paypalImg {
  width: 68px;
  height: 32px;
  padding-right: 14px
}

.iapImg {
  width: 15px;
  height: 22px;
  padding-right: 14px
}

.selectOptions {
  text-align: center;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.container {
  background-color: var(--app-background-color);
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 40px;
  align-self: center;
  padding-left: 40px;
  padding-right: 40px;
  align-items: center;
  @include for-size(phone-only) {
    padding-bottom: 20px;
    align-self: center;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.buyButtonContainer {
  width: 100%;
  padding-top: 20px;
}

.buyButton {
  @include noBackgroundButton;
  color: white;
  background-color: var(--app-color);
  font-weight: 500;
  border-radius: var(--app-default-btn-sm-border-radius);
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  font-size: 16px;
}

.buyButtonAFFILIATE_PROMO_POPUP {
  height: 50px;
}

.disabledBuyButton {
  background-color: #ACAFB6;
}

.method {
  display: flex;
  color: black;
  font-size: 13px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.priceInfo {
  font-size: 10px;
  color: #5F5F5F;
  text-align: center;
  display: flex;
  padding-bottom: 25px;
}

.priceInfoStar {
  color: #FD9246;
  font-size: 17px;
  font-weight: 500;
  padding-right: 3px;

  display: flex;
  margin: 0 auto;
}

.priceInfoText {
  display: flex;
  margin: 0 auto;
}

.sourceImg {
  margin-right: 10px;
}

.cardImage {
  border: 1px #C9C9C9 solid;
  border-radius: 5px;
  background-color: white;
  margin-right: 15px;
}

.noThanksLink {
  border: none;
  background-color: var(--app-background-color);
  cursor: pointer;
  color: var(--app-color);
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
}


.offerTermsLink {
  border: none;
  background-color: white;
  cursor: pointer;
  color: #8a11ba;
  font-size: 14px;
  font-weight: 500;
}

.offerTermsLink:hover{
  color: #8a11ba;
}

.offerExpiresDateContainer {
  column-gap: 4px;
  margin-top: 15px;
  font-size: 13px;
  text-align: center;
  width: 100%;
  color: var(--app-text-primary);
  line-height: 42px;
  background-color: var(--app-offer-expired-background-secondary-color);
  border: 1px solid var(--app-offer-expired-border-color);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 15px;
  line-height: 18px;
}

.offerExpiresDateBoldText {
  font-weight: 700;
}

.offerExpiresBrIfNeed {
  display: none;
  @include for-size(small-phone-only) {
    display: flex;
  }
}

.buttonTitleContainer {
  text-align: center;
}

.buttonTitleText {
  color: white;
  font-size: 20px;
  font-weight: 500;
}

.buttonTitleBonus {
  color: white;
  font-size: 12px;
}

.addPaymentOption {
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
}

.paymentOption {
  background-color: var(--app-background-secondary-color);
  border: 1px solid var(--app-border-primary-color);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}


.applePayNotSupported {
  color: #707070;
  font-size: 12px;
  text-align: center;
  padding-top: 20px;
}

.addApplePayOption {
  color: var(--app-black);
  border: 1px solid var(--app-black);
  border-radius: 8px;
  padding: 5px;
  width: 100%;
  font-weight: 600;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  height: 44px;
  text-align: center;
}

.paymentPopupDialogContainer {
  width: 100%;
  height: 100%;
  // min-height: 300px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  text-align: center;
  max-width: 400px;
  min-height: 300px;
  min-width: 375px;
  padding-left: 20px;
  padding-right: 20px;
  color: '#000000';
  @include for-size(phone-only) {
    // min-height: 280px;
    // min-width: 360px;
  }
}

.addApllePayButton {
  @include noBackgroundButton();
  width: 100%;
}

@import '../../../../mixin.scss';

@mixin defaultPaddings {
    padding-left: 160px;
    padding-right: 160px;

    @include for-size (phone-only) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 29px;
    padding-top: 80px;
    border: 1px solid #E4E4E4;
    border-radius: 40px;
    margin-left: 30px;
    margin-right: 30px;

    @include for-size (phone-only) {
        border: none;
        border-radius: 0;
        padding-top: 0;
        margin-top: 25px;
        margin-left: 0;
        margin-right: 0;
    }
}

.descriptionHolder {
    @include defaultPaddings();
    font-weight: 700;
    font-size: 22px;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 40px;

    @include for-size (phone-only) {
        margin-top: 25px;
        font-size: 17px;
        line-height: 24px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.descriptionHolder:first-of-type {
    margin-top: 0;
}

.description {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin-top: 18px;
    white-space: pre-wrap;
    word-break: break-word;

    @include for-size(phone-only) {
        font-size: 15px;
        margin-top: 0;
        line-height: 22px;
    }
}

.specialitiesContainer {
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin-top: 36px;
    gap: 15px;
    flex-wrap: wrap;
    background-color: var(--app-background-color);

    @include for-size (phone-only) {
        flex-direction: var(--advispr-specialities-mobile-direction);
        margin-top: 14px;
        gap: 8px;
    }
}

.speciality {
    display: flex;
    flex-direction: column;
    margin-left: 14px;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    max-width: 96px;
    text-align: center;
    line-height: 20px;

    @include for-size (phone-only) {
        margin-left: 8px;
        font-size: 10px;
        line-height: 12px;
        max-width: 58px;
    }
}

.speciality:first-of-type {
    margin-left: 0;
}

.specialityIcon {
    width: 96px;
    height: 96px;
    border-radius: 10px;

    @include for-size(phone-only) {
        width: 58px;
        height: 58px;
    }
}

.specialitiesSeparator {
    height: 13px;
    color: var(--app-color);
    @include for-size(phone-only) {
        height: 6px;
    }
}

.speciality a {
    color: var(--app-color);
}

.specialitySkeletonContainer {
  display: flex;
  width: 100%;
}

.specialitySkeleton {
    margin-left: 8px;
    height: 58px !important;
    width: 58px !important;
}

.specialitySkeleton:first-of-type {
    margin-left: 0;
}


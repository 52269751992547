@use 'sass:math';
@import '../../../../mixin.scss';

.advisorRecommendContainer {
    display: flex;
    width: 100%;
    max-width: 600px;
    flex-direction: row;
    border-radius: 20px;
    align-items: flex-start;
    flex: 1 0 0;
}

.advisorInfo {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 0px 20px 20px 0px;
  border: 1px solid #EAEAEA;
  background: #FFF;
  align-items: flex-start;
  flex: 1 0 0;
  color: #000000;
}

.basicInfoContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 30px;
    align-items: flex-start;
    justify-content: space-between;
    // margin-top: 10px;
}

.ratingContainer {
    display: flex;
}

.advsiorTitleContainer {
    display: flex;
    flex-direction: column;
  }

.avatarHolder {
    width: 205px;
    height: 205px;
  }

.advisorViewLink {
  width: 100%;
  height: 100%;
}

.advisrNickName {
  font-size: 22px;
  font-weight: 700;
}

.advisorServiceTitleContainer {
    color: #9D9D9D;
    font-size: 18px;
    font-weight: 400;
}

.bottomSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 5px;
    flex-direction: column;
}

.recommendedCarouselContainer {
  display: flex;
  justify-content: center;
}

.recommendedCarousel {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
  @include for-size(phone-only) {
    display: none;
}
  }

  .recommendedCarouselMobile {
    display: none;
    padding: 0 15px;
    @include for-size(phone-only) {
      display: flex;
      flex-direction: column;
      overflow: scroll;

    }
    .recommendedAdvisors {
      display: flex;
      flex-direction: row;
      gap: 15px;
      overflow: scroll;
    }
  }

.recommendAdvisor {
    .iconsSectionContainer {
      display: none;
    }

    .badgeContainer{
      display: none;
    }
  }

  .wrapperFreeMinutes {
    width: 100%;
  }

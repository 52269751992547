.loaderConteiner {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
}

.loaderConteinerHide {
  display: none
}
.loader {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: var(--loaderContainerSize);
  height: var(--loaderContainerSize);
}

.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: var(--loaderSize);
  height: var(--loaderSize);
  margin: 10px;
  border: var(--loaderBorder) solid var(--loaderColor);
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--loaderColor) transparent transparent transparent;
}

.loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.popupOverlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.65);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.mobileContainer {
  width: 100%;
  height: 100%;
  position: fixed;
}

.popupContainer {
  width: 63%;
  height: 65%;
  position: fixed;
}


.header {
  width: 100%;
  display: flex;
  text-align: left;
  border-bottom: lightgray 1px solid;
  align-items: center;
  height: 50px;
  margin-bottom: 1px;
  background-color: white;
  justify-content: center;
}

.cropperFrame {
  position: relative;
  width: 100%;
  height: calc(100% - 100px); // footer and header height
}

.mobileCroperFrame {
  position: relative;
  width: 100%;
  height: calc(100% - 100px);
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: white;
  align-items: center;
}

.confirmButton {
  justify-content: center;
  text-align: center;
  color: white;
  background-color: var(--app-color);
  border: none;
  border-radius: var(--app-default-btn-sm-border-radius);
  cursor: pointer;
}

.cancelButton {
  justify-content: center;
  text-align: center;
  color: white;
  background-color: #ACAFB6;
  border: none;
  margin-right: 10px;
  border-radius: var(--app-default-btn-sm-border-radius);
  cursor: pointer;
}

@import '../../../mixin.scss';

.container {
  min-width: 100%;
}


.chatEndedContainer {
  width: 100%;
  height: 100%;
  min-width: 500px;
}


.titleContainer {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    color: white;
  }
}

.discountBlock {
  background-color: #F5F5F5;
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  margin: 0 auto 30px;

}

.titleContainerBrowser {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    color:#020202;
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 0px;
  }
}

.hangupErrorBlock {
  text-align: center;
  color: var(--app-error-red);
  border: 1px solid var(--app-error-red);
  width: 90%;
  padding: 4px;
  border-radius: 30px;
  margin: 20px auto 0;
}

.hangupError {
  color: var(--app-error-red);
  font-size: 14px;
  padding: 5px
}

.alertBlock {
  text-align: center;
}

.alert {
  color: var(--app-error-red);
  font-size: 17px;
}

.alertHint {
  opacity: 0.8;
  font-size: 13px;
}

.totalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.totalLabel {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.totalValue {
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 41px;
}

.totalHint {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.totalHintHighlighted {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  color: var(--mbw-success-color);
}

.descriptionDataBrowser{
  font-weight: 700;
}

.smallBlockLine {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: 15px;
}

.smallBlockLabel {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.smallBlockValue {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: var(--mbw-success-color);
}

.chatInfoContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
  font-size: 2rem;

  div {
    line-height: 1.6rem;
    margin-bottom: 8px;
  }
}

.chatInfoContainerBrowser {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
  font-size: 24px;
}

.doneButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.doneButtonContainerBrowser {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
}

.closeAction {
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.descriptionText {
  font-size: 10px;
}

.descriptionTextBrowser {
  font-size: 14px;
}

.doneBtn {
  padding: 0.5em 7em;
  border: 1px solid white;
  border-radius: 4px;
  background: #fff0;
  outline: none;
}

.doneBtnBrowser {
  border: none;
  outline: none;
  border-radius: var(--app-default-btn-lg-border-radius);
  background: var(--app-color);
  color: white;
  margin: 20px;
  width: 100%;
  cursor: pointer;
  height: 44px;
  outline: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

@import "../../../common/common_mixin.scss";

.container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 344px;
  min-width: 344px;
  display: flex;
  flex-direction: column;
}


.loadingErrorContainer {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-bottom: 1px solid var(--app-border-gray);
}

.titleText{
  color: var(--app-text-primary);
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 344px;
  min-width: 344px;
}

.textAndSwitchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.textContainer {
  color: var(--app-text-secondary);
  font-size: 14px;
  font-weight: 400;
}

.switchContainer {
  display: flex;
  margin-left: 20px;
}

.btnsContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.cancelBtn {
  @include defaultButton();
  padding: 5px;
  margin-right: 20px;
}

.okBtn {
  @include defaultButton();
  padding: 5px;
}

@import '../../../../mixin.scss';

$optionWidth: 80%;
$radius: 6px;

.carouselArrowPrevContainer {
    width: 70px;
    left: 0;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 15;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .carouselArrowPrevContainer:focus {
    display: none;
  }

  .carouselArrowPrevContent {
    width: 100%;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrowImgContainer {
    border: 1px solid var(--border-primary);
    padding: 10px;
    border-radius: 50%;
    background-color: #FFFFFF;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
    display: flex;
    svg {
        width: 22px !important;
        height: 22px !important;
        color: var(--mbw-select-duration-arrows-color)
    }
  }

  .carouselArrowNextContainer {
    width: 70px;
    right: 0;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 15;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .carouselArrowNextContent {
    width: 100%;
    height: 184px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carouselItem {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 10px;
  }

  .durationItem {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 190px;
    max-height: -webkit-fill-available;
    background: #FFFFFF;
    border-radius: 25px;
    cursor: pointer;
  }

  .durationItemContextCol {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .durationItemMinutesCountContainer {
    font-size: 80px;
    line-height: 60px;
    font-weight: 300;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #D7BDFF;
    text-shadow: 0px 2px 2px rgba(126, 78, 196, 0.67), 0px 4px 4px #E3D0FF;
  }

  .durationItemMinutesTextContainer {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }

  .durationItemNewUserPaygPayContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .durationItemNewUserPaygText {
    font-size: 22px;
    line-height: 1;
  }

  .durationItemPayAsYouGoText {
    font-size: 12px;
  }

  .durationOptinsContainer {
    position: relative;
    width: 100%;

    margin-top: 20px;
    flex-grow: 1;
    min-height: 190px;

    @include for-size(phone-only) {
      max-width: 400px
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .durationItem {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 190px;
      max-height: -webkit-fill-available;
      background: #FFFFFF;
      cursor: pointer;
    }
  }

  .durationItemPriceTextContainer {
    font-weight: 600;
    font-size: 17px;
    padding: 0px 17px;
    background: #7E4EC4;
    color: #ffffff;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 11px;
  }

  .mostPopularContainer {
    display: flex;
    background: #FFFFFF;
    border-radius: 25px;
    padding: 3px 10px;
    margin-bottom: 10px;
  }

  .mostPopularIcon {
    width: 13px;
    display: flex;
    align-items: center;
  }

  .mostPopularText {
    font-size: 12px;
    padding-left: 3px;
    font-weight: 600;
    color:  #7E4EC4;
  }

  .tapHeroToUseYourBalanceForLabelContainer {
    font-size: 12px;
    font-weight: 600;
  }

  .carouselSelectedItem {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 25px;
    max-height: -webkit-fill-available;
    padding-top: 10px;
  .durationItem {
    height: 255px;
  }
  .durationItemPriceTextContainer {
    font-size: 20px;
    background: #FFFFFF;
    color: #93B722;
    margin-top: 10px;
  }
  .durationItemMinutesCountContainer {
    color: #F0FFC4;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px 0px 4px #e6ff9b;
    font-weight: 300;
    font-size: 100px;
    line-height: 80px;
    margin-top: 10px;
  }
  .mostPopularContainer {
    top: 15px;
  }
  .durationItemPriceContainerDefault {
    background-color: var(--duration-item-selected-price-container-background-color);
  }
}

.mostPopularHeartInCircleContainer {
  position: absolute;
  display: flex;
  transform: translate(+35%, -35%);
  top: 0;
  right: 0;
}

.newUserDurationOptions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @include for-size(phone-only) {
      gap: 10px;
    }

    .durationItemContextCol {
      padding-top: 30px;
    }

    .mostPopularContainer {
      display: flex;
      padding: 4px 6px;
      position: absolute;
      top: 20px;
    }

    .mostPopularIcon {
      width: 10px;
    }

    .mostPopularText {
      font-size: min(0.55rem, 9px);;
      padding-left: 2px;
      padding-top: 1px;
      line-height: 1;
    }

    .mostPopularHeartInCircleIcon {
      width: 20px;
      height: 20px;
    }

    .carouselItem {
      max-width: 100px;
      max-height: 150px;
    }

    .durationItem {
      width: 110px;
      height: 160px;
      @include for-size(phone-only) {
        height: 155px;
        width: 100px;
      }
    }

    .durationItemPriceTextContainer {
      font-size: 13px;
      font-weight: 600;
      margin-top: 13px;
      margin-bottom: 10px;
      padding: 0px 10px;
      line-height: 21px;
    }

    .durationItemMinutesCountContainer {
      font-size: 48px;
      font-weight: 300;
      line-height: 42px;
      margin-top: 10px;
    }

    .durationItemMinutesTextContainer {
      font-weight: 700;
      font-size: 12px;
    }

    .carouselSelectedItem {
      margin: 0 5px;
      padding-bottom: 0;
      .durationItem {
        width: 115px;
        height: 175px;
      }
      .durationItemMinutesCountContainer {
        font-size: 70px;
        line-height: 56px;
        margin-top: 10px;
      }
      .durationItemMinutesTextContainer {
        font-size: 14px;
      }
      .durationItemPriceTextContainer {
        margin-top: 10px;
        font-size: 14px;
        margin-top: 7px;
        line-height: 21px;
      }
      .mostPopularContainer {
        top: 10px;
      }
      .mostPopularHeartInCircleIcon {
        width: 25px;
        height: 25px;
      }
      .durationItemPriceContainerNewUser {
        background-color: var(--duration-item-selected-price-container-background-color);
      }
    }

    .freeMinLabelContainer {
      margin: 10px 0;
      @include for-size(phone-only) {
        margin: 5px 0 0 0;
      }
    }

    .freeMinTextContainer {
      font-size: 13px;
      @include for-size(phone-only) {
        font-size: 12px;
      }
    }

    .freeMinIcon {
      width: 15px;
      height: 15px;
      @include for-size(phone-only) {
        width: 15px;
        height: 15px;
      }
    }
  }

.newUserPaygContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.newUserPaygImgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newUserPaygImg {
  width: 175px;
  height: 125;
}

.newUserPaygTitle {
  margin-top: 8px;
  color: var(--app-color);
  font-size: 34px;
  font-weight: 700;
}

.newUserPaygPayAsYouGoText {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}

.durationItemPriceContainerDefault {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  border-radius: var(--duration-item-price-container-border-radius);
  background-color: var(--duration-item-price-container-background-color);
  padding: 3px 3px;
  color: app(--app-text-primary);
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 5px;
  padding-bottom: 5px;

  .durationItemPriceContainerPrice {
    font-size: 20px;
    font-weight: 700;

    color: var(--app-color);
  }

  .durationItemPriceContainerSalesPrice {
    font-size: 20px;
    font-weight: 700;
    color: var(--app-color);
  }

  .durationItemPriceContainerSaleCrosPrice {
    margin-left: 5px;
    font-size: 15px;
    font-weight: 600;
    text-decoration: line-through;
    color: var(--app-text-primary);
  }

  .durationItemPriceContainerMinText {
    font-size: 15px;
    font-weight: 600;
    color: var(--app-text-primary);
  }
}

.durationItemPriceContainerNewUser {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  border-radius: var(--duration-item-price-container-border-radius);
  background-color: var(--duration-item-price-container-background-color);
  color: app(--app-text-primary);
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;

  .durationItemPriceContainerPrice {
    font-size: 14px;
    font-weight: 700;

    color: var(--app-color);
  }

  .durationItemPriceContainerSalesPrice {
    font-size: 11px;
    font-weight: 700;
    color: var(--app-color);
  }

  .durationItemPriceContainerSaleCrosPrice {
    margin-left: 2px;

    font-size: 9px;
    font-weight: 600;
    text-decoration: line-through;
    color: var(--app-text-primary);
  }

  .durationItemPriceContainerMinText {
    font-size: 10px;
    font-weight: 600;
    color: var(--app-text-primary);
  }
}


.newUserPaygPriceContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  border-radius: 8px;
  background-color: var(--background-secondary);
  padding: 3px 16px;
  color: app(--app-text-primary);
  margin-bottom: 30px;
}

.newUserPaygPrice {
  font-size: 20px;
  font-weight: 700;

  color: var(--app-color);
}

.newUserPaygMinText {
  font-size: 15px;
  font-weight: 600;

  color: var(--app-text-primary);
}

.newUserPaygSalesPrice {
  font-size: 20px;
  font-weight: 700;
  color: var(--app-color);
}

.newUserPaygSaleCrosPrice {
  margin-left: 5px;
  font-size: 15px;
  font-weight: 600;
  text-decoration: line-through;
}

.durationItemDiscountLabelContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  gap: 5px;;
}

.durationItemDiscountLabelTitle {
  font-size: 14px;
  font-weight: 400;
  color: var(--mbw-text-primary);
}

.durationItemMaxDiscountLabelImgTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--app-color);
  border-radius: 16px;
  padding: 4px 6px 4px 6px;
}

.durationItemMaxDiscountLabelTitle {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: var(--app-white);
}

.durationItemMaxDiscountLabelImg {
  width: 14px !important;
  height: 14px;
  margin-right: 2px;
}

.durationItemDiscountLabelImgTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.durationItemDiscountLabelImg {
  width: 16px !important;
  height: 16px;
  margin-right: 2px;
}

.durationItemDiscountLabelText {
  font-size: 12px;
  font-weight: 700;
  color: var(--app-color);
  line-height: 16px;
}

@mixin newUserPaygBonusLabelsHolder {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 0.5px solid var(--border-primary);
  width: 90%;
  padding: 24px;
  margin-bottom: 20px;
  gap: 12px;
  @include for-size(phone-only) {
    padding: 16px;
    margin-bottom: 15px;
  }
}

.newUserPaygBonusLabelsHolder {
  @include newUserPaygBonusLabelsHolder()
}

.newUserPaygBonusLabelsHolderCentered {
  @include newUserPaygBonusLabelsHolder();
  align-items: center;
}

.newUserPaygBonusLabelContainer {
  display: flex;
  align-items: center;
}

.newUserPaygBonusLabelImg {
  width: 30px;
  height: 30px;
}

.newUserPaygBonusLabelTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
}

.newUserPaygBonusLabelText {
  color: var(--app-color);
  font-size: 16px;
  font-weight: 700;
  @include for-size(phone-only) {
    font-size: 15px;
  }
}

.newUserPaygBonusLabelSubText {
  color: #8C909C;
  font-size: 12px;
  font-weight: 400;
}

.freeMinLabelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px 0 0 0;
  inline-size: max-content;
  background-color: transparent;
}

.freeMinTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  gap: 2px;
  color: var(--duration-option-xfm-label-color);
}

.freeMinIcon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include for-size(phone-only) {
    width: 16px;
    height: 16px;
  }
}

@import '../../mixin.scss';

@mixin borders {
  border-radius: 10px;

  @include for-size(phone-only) {
    border-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

}

.profileContainer {
  @include borders();
  position: relative;
  width: 100%;
}

.profileContainer::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.avatarContainer {
  position: absolute;
  height: 100%;
  width: 100%;
}

.advisorProfile {
  position: absolute;
  width: 100%;
  height: 100%;
}

.profileImg {
  @include borders();
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.transparentOverlay {
  @include borders();
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0) 30%, rgba(0, 0, 0, 0.0) 70%, rgba(0, 0, 0, 0.5) 100%);
  position: absolute;
  top: 0;
  bottom: 0;
}

.videoContainer {
  cursor: pointer;
}

.text {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}

.playButtonContainer {
  display: flex;
  flex-direction: row;
  z-index: 9;
  margin-top: 2px;
}

.playButtonImg {
  width: 8;
  height: 9;
  margin-right: 3px;
}

.statusContainer {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin status($color) {
  width: 100%;
  border-radius: 20px;
  background-color: $color;
  z-index: 10;
}


.indicatorContainer {
  border-radius: 20px;
  justify-content: center;
  align-self: center;
  z-index: 9;
  position: absolute;
  padding: 3px;
  background-color: #FFFFFF;
}

.statusTitle {
  color: #FFFFFF;
  font-weight: 700;
  font-size: 13px;
  // padding: 2px 25px 2px 25px;
  padding: 4px 40px 4px 40px;

  @include for-size(phone-only) {
    font-size: 14px;
  }
}

.onlineIndicator {
  @include status($color: var(--app-online-indicator-color))
}

.offlineIndicator {
  @include status($color: var(--app-offline-indicator-color));
}

.busyIndicator {
  @include status($color: var(--app-busy-indicator-color));
}

.indicatorSkeleton {
  @include skeletonContainer();
  @include status($color: 'transpared');
  min-width: 130px;
  min-height: 28px;
}

.profileImageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  flex-direction: column;
}

.badgeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--app-badge-border-radius);
  padding: 3px;

  @include for-size(phone-only) {
    padding: 0;
    margin-bottom: 10px;
  }
}

.highRatedContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  left: 20px;
  bottom: 30px;
  z-index: 11;
  background-color: #B05DD1;
  border-radius: 3px;

  @include for-size(phone-only) {
    display: none;
  }

}

.ratedImg {
  width: 15px;
  height: 15px;
  margin-right: 4px;
  margin-left: 4px;
}

.badgeText {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 3px;
  padding-right: 5px;

  @include for-size(phone-only) {
    font-size: 11px;
  }
}

.textRated {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  padding: 3px;
  padding-right: 5px;
}

.avatar {
  width: 100%;
  height: 100%;
}

.topItemsContainer {
  position: absolute;
  padding: 15px;
  display: none;
  width: 100%;
  height: 50px;
  justify-content: space-between;
  flex-direction: row;
  z-index: 11;

  @include for-size(phone-only) {
    display: flex;
  }
}

.ratingContainer {
  background: var(--app-rating-on-img-bg-color);
}

.starImg {
  width: 16px;
  height: 16px;
}

.ratingText {
  padding-left: 6px;
  font-size: 17px;
  font-weight: 500;
  line-height: 1;
}

.notifyContainer {
  padding-left: 6px;
  padding-right: 8px;
}

.favouriteContainer {
  padding-left: 6px;
  padding-right: 8px;
}

.shareContainer {
  padding-left: 6px;
  padding-right: 8px;
}


.nameSectionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  display: none;
  line-height: 25px;

  @include for-size(phone-only) {
    display: flex;
  }

}

.nickNameContainer {
  color: #ffffff
}

.nickNameText {
  width: 100%;
  max-width: 100%;
  font-size: 26px !important;
  font-weight: 700 !important;
  color: #ffffff !important;
  line-height: normal;
}

.serviceTitleContainer {
  width: 100%;
  max-width: 100%;
  display: unset;
  color: #E4E4E4;
}

.serviceTitleText {
  width: 100%;
  max-width: 100%;
  font-size: 18px;
  font-weight: 400;
  color: #E4E4E4;
}


.iconsSectionContainer {
  display: flex;
  flex-direction: row;
}


.notifyFavorite,
.notifyFavorite:focus {
  @include noBackgroundButton
}

.notifyFavorite:hover {
  cursor: pointer;
}

.container {
  margin: 1.5rem 0rem;
  font-size: 1rem;
}

.radioBtnContainer {
  border-radius: 4px;
  margin-top: 1rem;
}

@mixin radioBtn {
  background: rgb(12, 173, 79);
  color: white;
  padding: 0.5rem;
  flex-grow: 1;
  outline: none;
  border: 1px solid #e5e5e5;
}

.radioBtnClone {
  @include radioBtn();
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.radioBtnSelected {
  background: gray;
  color: white;
  border: 1px solid gray;
}

.advisorInfo {
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  position: absolute;
  width: 100%;
  padding: 15px;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 30px;

  @include for-size(phone-only) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
  }
}

//terminatedAdvisor
.terminatedAdvisor {

  .iconsSectionContainer {
    display: none;
  }

  .badgeContainer{
    display: none;
  }
}

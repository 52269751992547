@import '../../mixin.scss';


.headerContainer {
  height: 64px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.headerContent {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  @include for-size(phone-only) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.contentHolder {
  display: flex;
  @include defaulContentWidth();
  width: 100%;
  justify-content: space-between;
  position: relative;
}

a {
  color: #586164;
}

a:hover {
  color: var(--app-color);
}

.title {
  font-size: 1.2em;
  color: #000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
}

.headerTitle {
  display: table;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  padding-left: 12px;
  font-weight: 700;
  font-size: 22px;
  margin: 0;
  letter-spacing: 0.02em;

  @include for-size(small-phone-only) {
    padding-left: 10px;
    font-size: 14px;
  }

  @media only screen and (max-width: 320px) {
    font-size: 12px;
    padding-left: 5px;
  }
}

.headerTitleTM {
  display: table-cell;
  font-size: 7px;
  vertical-align: top;
}

.profilePicture {
  width: 45px;
  height: 45px;
  border-radius: 17px;
  object-fit: cover;
}

.balanceLabel {
  background-color: var(--app-color);
  border-radius: 2px;
  color: white;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 0.8rem;
}

.popoverContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.popoverItem {
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  color: #121212;
  display: flex;
  column-gap: 10px;
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
}

.popoverItem:hover {
  background: #f7f7f7;
  color: #121212;
}

.divider {
  height: 0.5px;
  background-color: #c0c0c0;
  margin-top: 25px;
}

.backArrow {
  color: var(--app-color);
  font-size: 1em;
}

.transparentHeader {
  position: sticky;
  width: 100%;
  padding-left: 10px;
  background: linear-gradient(black, transparent 100%);
  height: 60px;
  z-index: 13;
}

.whiteBackArrow {
  color: white;
  font-size: 26px;
}

.badgeContainerRow>* {
  margin-right: 10px;
}

.left.innerWrapper,
.right.innerWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  min-width: -webkit-min-content;
  /* Workaround to Chrome bug */
}

.centerTitle {
  display: flex;
  align-items: center;
}

.right.innerWrapper {
  justify-content: flex-end;
}

.signInButtonsContainer {
  display: flex;
  flex-direction: row;
}

.loginButtonContainer {
  display: flex;
  padding-left: 18px;
  padding-right: 18px;
  color: white;
  outline: 0;
  font-size: 18px;
  font-weight: 500;
  height: 44px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include for-size(phone-only) {
    font-size: 14px;
    height: 38px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.signIn {
  font-size: 18px;
  font-weight: 500;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 21px;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  @include for-size(phone-only) {
    font-size: 14px;
    height: 38px;
    margin-right: 10px;
  }
}

.loginButtonContainer:hover {
  cursor: pointer;
}

.avatar,
.avatar:hover,
.avatar:focus {
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  align-self: center;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  object-fit: cover;
  width: 44px;
  height: 44px;
  border-radius: 22px;
}

.popoverAvatar {
  width: 90px;
  height: 90px;
  border-radius: 50px;
  display: flex;
  align-self: center;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  object-fit: cover;
  border-radius: 50%;
}

.profileInfoContainer {
  @include noBackgroundButton();
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
}

.profileInfoContainerDivider {
  width: 12px;
}

.infoContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  min-width: -webkit-min-content;
  /* Workaround to Chrome bug */
}

.badge {
  position: absolute;
  top: -8px;
  right: -3px;
}

.menuButton {
  background: transparent;
  cursor: pointer;
  border: none;
  outline: 0;
  padding: 0;
  position: relative;
}

.menuButton:focus {
  background: transparent;
  cursor: pointer;
  border: none;
  outline: 0;
}

.menuButton:hover {
  background: transparent;
  cursor: pointer;
  border: none;
  outline: 0;
}

.menuButtonImg {
  width: 22px;
  height: 18px;
}

.headerLink {
  display: flex;
  align-items: center;
  margin-left: 16px;
  line-height: inherit;
}

.profileInfoPopoverContainer {
  position: absolute;
  right: 0px;
  flex-direction: column;
  display: var(--display);
  justify-content: center;
}

.profileInfoPopoverInnerContainer {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  top: 14px;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.profileInfoPopoverTopArrow {
  right: 16px;
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  -webkit-box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.profileInfoPopoverInner {
  background-color: $app-white;
  background-clip: padding-box;
  border-radius: 8px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
  margin-top: 10px;
  padding: 27px 22px 33px 25px;
}

.profileInfoPopoverTitle {
  min-width: 244px;
  margin-bottom: 25px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
}

.profileInfoPopoverInnerContent {
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
}

.profilePopoverHeaderAvatarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popoverItemText {
  width: 100%;
}

.headerLogoContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap:'nowrap';
}

.headerLogoH1Container {
  margin: 0;
}

.logoImg {
  width: 40px;
  height: 40px;
}

.searchContainer {
  background-color: #FFFFFF;
  display: flex;
  height: 100%;
  flex-direction: row;
  font-size: 18px;
  font-weight: 500;
  padding-left: 18px;
  border-radius: 8px;
  width: 100%;
  margin-right: 10px;

  @include for-size(phone-only) {
    font-size: 15px;
    margin-bottom: 9px;
    padding-left: 10px;
  }
}


.searchField,
.searchField:hover,
.searchField:active,
.searchField:focus {
  display: flex;
  border: none;
  width: 100%;
  line-height: 0;
  outline: none;
}

.searchField::placeholder {
  color: #ABABAB;
  font-size: 17px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  @include for-size(phone-only) {
    font-size: 13px;
  }
}

.searchField:placeholder-shown {
  text-overflow: ellipsis;
}

.inputSearchIconContainer {
  display: flex;
  align-items: center;
  padding-right: 11px;
  @include for-size(phone-only) {
    display: none;
  }
}

.inputSearchIcon {
  display: flex;
  width: 21px;
  height: 20px;
  @include for-size(phone-only) {
    display: none;
  }
}

.searchButton {
  @include noBackgroundButton;
  display: flex;
  height: 100%;
  margin: 0;
  padding: 8px;
  padding-left: 17px;
  padding-right: 17px;
  color: #FFFFFF;
  background-color: var(--app-color);
  font-weight: 500;
  line-height: 0;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 54px;

  @include for-size(phone-only) {
    height: 44px;
  }
}

.searchButtonTitle {
  display: flex;
  width: 100%;
  white-space: nowrap;
  padding-left: 25px;
  padding-right: 25px;

  @include for-size(phone-only) {
    display: none;
  }
}

.searchButtonIcon {
  display: none;
  width: 21px;
  height: 20px;

  @include for-size(phone-only) {
    display: flex;
  }
}

.searchInput {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  padding-left: 0px;
  line-height: 0;

  @include for-size(phone-only) {
    height: 44px;
    font-size: 15px;
    padding: 0;
  }
}

.headerSearchAndProfileContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headerSearchMainContainer {
  display: flex;

  @include for-size(phone-only) {
    display: none;
  }
}

.headerSearchButton {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  background-color: #FFFFFF;
  border: 1px solid #D7D7D7;
  margin-right: 21px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px -4px 0px rgba(202, 202, 202, 0.25);
}

.headerSearchButton:hover {
  box-shadow: inset 0px -4px 0px rgba(202, 202, 202, 0.25);
}

.headerSearchButtonImg {
  width: 20px;
  height: 20px;
}

.headerSearchLineContainer {
  width: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  z-index: 15;
}

.headerSearchLineSearchIconAndInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
}

.headerSearchLineDivider {
  width: 100%;
  height: 1px;
  background-color: #CACACA;
}

.headerSearchLineForm {
  width: 100%;
  margin-left: 14px;
}

.headerSearchLineInput,
.headerSearchLineInput:hover,
.headerSearchLineInput:focus {
  width: 100%;
  color: black;
  border: none;
  height: 40px;
  outline: none;
}

.headerSearchLineInput::placeholder {
  color: #ABABAB;
  font-size: 18px;
  font-weight: 500;
}

.headerSearchLineButton {
  display: flex;
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  height: 44px;
  background-color: var(--app-color);
  border-radius: var(--app-default-btn-lg-border-radius);
  border: none;
  margin-left: 15px;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.headerSearchLineCloseButton {
  padding: 0;
  border: 0;
  background-color: #FFFFFF;
  margin-left: 22px;
  cursor: pointer;
}

.headerSearchLineCloseButtonImg {
  width: 23px;
  height: 23px;
}

.headerSearchLineInputContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.headerSearchLineSearchCloseButtonContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  margin-right: 20px;
}

.headerSearchGreyIcon {
  width: 20px;
  height: 20px;
}

.headerAffiliatePromoBannerContainer {
  width: 100%;
}

.profilePopoverHeaderWrapper {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 20px;
}

.profilePopoverHeaderRightSide {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatarIconContainer {
  position: relative;
  bottom: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--app-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nicknameContainer {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #121212;
  max-width: 200px;
  word-wrap: break-word;

  &.empty {
    font-size: 14px;
    line-height: 19.6px;
    color: #616C6F;
  }
}

.addCreditsButton {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  height: 26px;
  padding: 0px 5px;
  background: var(--app-color);
  width: max-content;
  gap: 1px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 20px;

  &:hover {
    color: #fff;
  }

  span {
    padding: 1px 3px;
  }
}
@import '../../../../mixin.scss';
@import '../../../home_page/nested_views/staff_pick_section/classes.module.scss';

.countContainer {
}

.readingsSinceContainer{
  white-space: nowrap;
}

.readingsSinceMobileContainer{
  white-space: nowrap;
}

// default
.default {
  align-items: flex-end;
  overflow: visible;

  @include for-size(phone-only) {
    flex-direction: column;
    align-items: flex-start;
    display: flex !important;
  }

  .countContainer {
    align-items: flex-end;
    margin-right: 5px;
    font-weight: 600;

    @include for-size(phone-only) {
      line-height: 14px;
      display: flex;
    }
  }

  .readingsSinceContainer {
    flex-direction: row;
    font-weight: 400;
    font-size: 20px;

    @include for-size(phone-only) {
      font-size: 13px;
      width: auto;
      margin-left: 0;
      margin-right: 20px;
    }
  }

  .readingsSinceMobileContainer {
    display: none !important;
  }
}

//onHomePageSecriceContainer
.onHomePageSecriceContainer {
  color: #000000;
  width: 100%;
  max-width: 100%;
  @include for-size(phone-only) {
    display: none !important;
  }

  .countContainer {
    margin-right: 5px;
    font-size: 20px;
    font-weight: 700;
  }

  .readingsSinceContainer {
    font-size: 20px;
    font-weight: 400;
  }

  .readingsSinceMobileContainer {
    display: none !important;
  }

  @media only screen and (max-width: 1439px) {
    .readingsSinceContainer {
      font-size: 18px;
    }
    .countContainer {
      margin-right: 5px;
      font-size: 18px;
      font-weight: 650;
    }
  }

  @media only screen and (max-width: 1279px) {
    .readingsSinceContainer {
      display: none !important;
    }
    .readingsSinceMobileContainer {
      display: unset !important;
      font-size: 18px;
      font-weight: 400;
    }
    .countContainer {
      margin-right: 5px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}


//onHomePageAdvisorAvatar
.onHomePageAdvisorAvatar{
  display: none !important;
  color: #FFFFFF;
  width: 100%;
  max-width: 100%;
  text-align: center;
  @include for-size(phone-only) {
    display: unset !important;
  }

  .countContainer {
    font-size: 10px;
    font-weight: 700;
  }


  .readingsSinceContainer {
    display: none !important;
  }

  .readingsSinceMobileContainer {
      font-size: 10px;
      font-weight: 400;
      margin-left: 2px;
  }
}

// staffPick
.staffPick {
  width: 100%;
  display: flex !important;
  border-left: solid #EAEAEA 1px;
  line-height: 1;
  flex-direction: column;
  transition: all .3s ease;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 4%;
  justify-content: center;
  overflow: unset;

  @media only screen and (max-width: 990px) {
      display: none !important;
      border-left: none;
      margin-top: 23px;
      padding-left: 0px;
      margin-left: 0px;
  }

  .readingsSinceContainer {
    display: none !important;
  }

  @include staffPickTransition() {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      border-left: none;
      margin-top: 23px;
      padding-left: 0px;
      margin-left: 0px;
  }


  .countContainer {
    margin-right: 5px;
    font-size: 26px;
    font-weight: 700;

    @media only screen and (max-width: 990px) {
      font-size: 17px;
    }
  }

  .readingsSinceContainer {
    font-size: 20px;
    font-weight: 400;
    transition: all .3s ease;
    margin-left: 0px;
    line-height: 21px;

    @media only screen and (max-width: 1280px) {
        font-size: 17px;
    }

    @include staffPickTransition() {
        font-size: 17px;
    }
  }
  .readingsSinceMobileContainer {
    white-space: pre-wrap;
    @media only screen and (max-width: 1080px) {
      white-space: nowrap;
    }
  }
}

//staffPickMobile
.staffPickMobile {
  display: none !important;

  .readingsSinceMobileContainer {
    display: none !important;
  }

  @media only screen and (max-width: 990px) {
    display: flex !important;
  }
  .countContainer {
    margin-right: 5px;
    font-size: 26px;
    font-weight: 700;

    @media only screen and (max-width: 990px) {
      font-size: 17px;
    }
  }

  .readingsSinceContainer {
    font-size: 20px;
    font-weight: 400;
    transition: all .3s ease;
    margin-left: 0px;

    @media only screen and (max-width: 1280px) {
        font-size: 17px;
    }

    @include staffPickTransition() {
        font-size: 17px;
    }
  }
}

// Advisor Recommend Desktop
.recommendAdvisor {
  align-items: flex-start;
  overflow: visible;

  .countContainer {
    align-items: flex-start;
    margin-right: 0px;
    font-weight: 600;
    font-size: 18px;
    margin-right: 5px;
  }

  .readingsSinceContainer {
    white-space: nowrap;
    flex-direction: row;
    font-weight: 400;
    font-size: 18px;
  }

  .readingsSinceMobileContainer {
    display: none;
  }
}

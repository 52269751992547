@import "../common_mixin.scss";

$borderRadius: 12px;
$infoColor: #FD9246;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: var(--app-text-primary);
  background-color: var(--app-background-color);
  min-width: 475px;
  @include for-size(phone-only) {
    min-width: auto;
  }
}

.containerWithIcon {
  width: 100%;
  height: 100%;

  @include for-size(phone-only) {
    max-width: auto;
  }
}

.dialogContainer {
  width: 100%;
  height: 100%;
  background: var(--app-background-color);
  border-radius: $borderRadius;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.priceHolder {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  @include for-size(phone-only) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.flexCenteredDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flexCenteredDiv:first-of-type {
  border: var(--app-price-holder-border);
  background-color: var(--app-price-holder-background-color);
}

.detailsButton,
.detailsButton:focus,
.detailsButton:hover,
.detailsButton:active {
  border: none;
  background: none;
  cursor: pointer;
  outline: 0;
  margin-top: 15px;
  margin-bottom: 30px;
}

.detailsButtonText {
  color: var(--app-po-mbv-text-color, var(--app-color));
  font-size: 15px;
  border: 2px var(--app-po-mbv-text-color, var(--app-color));
  border-bottom-style: dashed;
}

.topImageContainer {
  width: 100%;
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: center;
  z-index: 101;

  .topImage {
    width: 56px;
    height: 56px;
  }
}

.details-enter {
  opacity: 0;
  background-color: red
}

.details-enter-active {
  opacity: 1;
  background-color: red;
  transition: opacity 500ms ease-in;
}

.details-exit {
  opacity: 1;
  background-color: gray
}

.details-exit-active {
  opacity: 0;
  background-color: gray;
  transition: opacity 500ms ease-in;
}

.bottomContainer {
  width: 100%;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  padding: 40px;
}

.bottomContainer:before {
  height: 10px;
  width: 10px;
  top: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  content: ""
}

.accountInfoTitle {
  color: #666666;
  font-size: 11px;
}

.buyButton {
  background-color: var(--color);
  border-radius: var(--app-default-btn-sm-border-radius);
  padding: 10px;
  margin-top: 10px;
}

.priceTitle {
  font-size: 48px;
  font-weight: 700;
  padding-top: 25px;
  padding-bottom: 25px;
  color: var(--app-text-primary);
}

.priceTitleSkeleton {
  @include skeletonContainer();
  width: 80px;
  height: 40px;
}

.detailsView {
  font-size: 14px;
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.detailsViewText {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  padding-top: 10px;
  letter-spacing: 0;
  text-align: right;
}

.divider {
  height: 1px;
  border: 1px #D9D9D8;
  border-bottom-style: dotted;
  margin-top: 5px;
}

.infoStar {
  color: $infoColor;
  font-size: var(--size)
}

.info {
  color: $infoColor;
  font-size: 11px;
  padding-bottom: 10px;
}

.backButton {
  top: 0;
  left: 0;
  z-index: 150;
  padding: 10px;
  background: none;
  border: none;
  position: absolute;
}

.sideMenuBottom {
  height: 30px;
}

.example-enter {
  background: yellow;
  transition: background 1s ease-in;
}

.example-enter.example-enter-active {
  background: white;
}

.example-leave {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.example-leave.example-leave-active {
  opacity: 0.01;
}

.purpleText {
  color: #8a11ba;
  display: inline-block;
}

.affiliatePromoPopupBodyAFFILIATE_PROMO_POPUP {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  margin-top: 15px;
  display: block;
}

.titleContainer {
  width: 100%;
}

@mixin title {
  font-size: 22px !important;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.titleAFFILIATE_PROMO_POPUP {
  @include title();
}

.titleSIDE_MENU {
  @include title();
}


.closeBtn {
    @include noBackgroundButton();
    align-self: flex-end;
    display: flex;
    margin-right: 20px;
}

.closeImg {
    width: 15px;
}

.purchaseFeesLable {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--app-text-secondary);
  font-size: 12px;
  padding: 5px;
}

.purchaseExtraPriceLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--app-text-secondary);
  font-size: 14px;
}

.marketingBanner {
  padding-left: 40px;
  padding-right: 40px;

  @include for-size(phone-only) {
    padding-left: 20px;
    padding-right: 20px;
  }
  display: flex;
  width: 100%;
}

.affiliationBannerContainer {
  width: 100%;
  min-height: 93px;
}

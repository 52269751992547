@import '../../../mixin.scss';

.ratingContainer {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #FFECBC;
  border-radius: 51px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.starImg {
  width: 16px;
  height: 16px;
}

.ratingText {
  padding-left: 6px;
  font-size: 17px;
  font-weight: 500;
  line-height: 1;
}

.notifyContainer {
  padding-left: 6px;
  padding-right: 8px;
}

.favouriteContainer {
  padding-left: 6px;
  padding-right: 8px;
}

.shareContainer {
  padding-left: 6px;
  padding-right: 8px;
}

.topIconImgBlack {
  display: flex;
  width: 38px;
  height: 38px;
  margin-right: 20px;

  @include for-size(phone-only) {
    display: none;
    margin-right: 10px;
  }
}

.topIconImgWhite {
  display: none;
  width: 38px;
  height: 38px;

  @include for-size(phone-only) {
    display: flex;
  }
}

.notifyMeTooltipContainer {
  position: absolute;
  top: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 12;


  //* Base styles for the entire tooltip */
  &:before, &:after {
    position: absolute;
    visibility: hidden;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    transition: opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    transform: translate3d(0, 0, 0);
    pointer-events: none;
  }

  //* Show the entire tooltip on hover and focus */
  &:hover, &:focus {
    &:before, &:after {
      visibility: visible;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1;
    }
  }

  //* Base styles for the tooltip's directional arrow */
  &:before {
    z-index: 1001;
    border: 6px solid transparent;
    background: transparent;
    content: "";
  }

  //* Base styles for the tooltip's content area */
  &:after {
    z-index: 1000;
    padding: 8px 10px 8px 10px;
    background-color: #000;
    background-color: hsla(0, 0%, 20%, 0.9);
    color: #fff;
    white-space: nowrap;
    content: attr(data-tooltip);
    font-size: 14px;
    line-height: 1.2;
  }

  //*** Directions ***/
  //* Bottom */
  &, &.tooltip-bottom {
    &:before, &:after {
      top: 100%;
      bottom: auto;
      left: 0
    }

    &:before {
      margin-top: -12px;
      margin-bottom: 0;
      margin-left: 12px;
      border-top-color: transparent;
      border-bottom-color: #000;
      border-bottom-color: hsla(0, 0%, 20%, 0.9);
    }

    &:focus, &:hover {
      &:before, &:after {
        transform: translateY(8px);
      }
    }
  }
}

.nickNameText {
  width: 100%;
  max-width: 100%;
  font-size: 26px;
  font-weight: 700;
  line-height: normal;
  color: #ffffff;
}

.serviceTitleContainer {
  width: 100%;
  max-width: 100%;
  display: unset;
}

.serviceTitleText {
  width: 100%;
  max-width: 100%;
  font-size: 18px;
  font-weight: 400;
  color: #E4E4E4;
}


.iconsSectionContainer {
  display: flex;
  flex-direction: row;
}

.iconButton {
  @include noBackgroundButton();
  position: relative;
}

.iconButton:hover {
  cursor: pointer;
}

.popoverOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
}

.sticky_icons {
  .topIconImgBlack {
    display: flex;
    @include for-size(phone-only) {
      width: 30px;
      height: 34px;
      margin-right: 5px;
    }
  }

  .topIconImgWhite {
    display: none;
  }
}

.terminatedAdvisor{
  .iconsSectionContainer {
    display: none;
  }
}

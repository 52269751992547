@import '../../../../mixin.scss';

.fullWidth {
  @include for-size(phone-only) {
    max-width: unset !important;
    width: 100% !important;
  }
}

.modeUnavailablePoppupContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  font-size: 18px;
  font-weight: 400;
}

.modeUnavailablePoppupBtn {
  @include noBackgroundButton();
  color: var(--app-color);
  margin-top: 5px;
}

//default
.default {
  @include noBackgroundButton();
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 148px;
  width: 148px;
  height: 207px;
  margin-left: 14px;
  padding-top: 30px;
  padding-bottom: 20px;

  @include for-size(phone-only) {
    border-radius: 6px;
    padding: 5px;
    height: 100%;
    min-height: 147px;
    padding-top: 16px;
    padding-bottom: 10px;
    margin-left: 9px;
  }

  .modeTitle {
    font-weight: 700;
    font-size: 18px;

    @include for-size(phone-only) {
      font-size: 13px;
    }
  }

  .modePrice {
    font-size: 16px;
    font-weight: 400;
    min-height: 20px;

    .sale {
      text-decoration: line-through;
      font-size: 12px;
    }

    @include for-size(phone-only) {
      font-size: 13px;
      font-weight: 500;

      .sale {
        font-size: 10px;
      }
    }
  }

  .modeStatus {
    font-weight: 500;
    font-size: 14px;
    background-color: #FFFFFF;
    border-radius: 41px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 13px;

    @include for-size(phone-only) {
      font-size: 11px;
    }
  }

  .pricesContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    line-height: 20px;
  }

  .modeImage {
    width: 76px;
    height: 76px;

    @include for-size(phone-only) {
      width: 59px;
      height: 59px;
    }
  }

  .freeMinContainer {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
  }
}

.default:first-of-type {
  margin-left: 0;
}

//advisorFixContainer
.advisorFixContainer {
  @include noBackgroundButton();
  padding-bottom: 2px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 5px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-left: 14px;
  white-space: nowrap;
  max-width: 160px;
  min-width: 160px;

  @include for-size(phone-only) {
    border-radius: 6px;
    padding: 5px;
    padding-left: 8px;
    padding-right: 10px;
    padding-top: 7px;
    padding-bottom: 5px;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
  }

  @include for-size(small-phone-only) {
    min-width: 110px;
  }

  .advisorFixContainer:first-of-type {
    margin-left: 0px;
  }

  .advisorFixContainer:first-child {
    margin-left: 0px;
  }

  .modeTitle {
    font-weight: 600;
    font-size: 16px;

    @include for-size(phone-only) {
      font-size: 12px;
      margin-left: 5px;
    }

    @include for-size(small-phone-only) {
      font-size: 12px;
      margin-left: 3px;
    }
  }

  .modePrice {
    display: none;
  }

  .modeStatus {
    display: none;
  }

  .pricesContainer {
    display: flex;
    align-items: center;
    padding-bottom: 4px;
    width: 100%;
    justify-content: space-evenly;

    @include for-size(phone-only) {
      padding-bottom: 4px;
      width: unset;
    }
  }

  .modeImage {
    width: 39px;
    height: 39px;

    @include for-size(phone-only) {
      width: 27px;
      height: 27px;
    }
  }
}

.advisorFixContainer:first-of-type {
  margin-left: 0px;
}

.advisorFixContainer::after {
  content: "";
}

.previewAdvisorCTA {
  @include noBackgroundButton();
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 10px;
  gap: 10px;
  background-color: var(--app-color);
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  @include for-size (tablet-landscape-up) {
    padding-left: 10px;
    padding-right: 10px;
    gap: 5px;
  }
  @include for-size(tablet-portrait-up) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    gap: 5px;
  }
  @include for-size(phone-only) {
    width: 100%;
    font-size: 12px;
    gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 10px;
  }
}

.previewCtaIcon {
  width: 18px;
  @include for-size(phone-only) {
    width: 12px;
  }
  .staffPick {
      width: 100%;
  }
}

.freeMinContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $app-white;
  border-radius: 10px;
  border: 1px solid var(--free-min-color);
  padding: 3px 5px 3px 5px;

  @include for-size(phone-only) {
    border-radius: 6px;
    padding: 1px 3px 1px 3px;
  }
}

.freeMinIcon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include for-size(phone-only) {
    width: 14px;
    height: 14px;
  }
}

.freeMinTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: var(--free-min-color);
  margin-left: 3px;

  @include for-size(phone-only) {
    font-size: 10px;
    margin-left: 2px;
  }
}


.container {
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #F6F6F6;
}

.innerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  font-weight: 500;
  color: black;
  font-size: 44px;
}

.img {
  padding-top: 22px;
  padding-bottom: 22px;
}

.notFound {
  color: black;
  font-size: 24px;
}

.subtitle {
  color: #868686;
  font-size: 16px;
  padding-top: 25px;
  padding-bottom: 39px;
  white-space: break-spaces;
  text-align: center;
}

.serviceUnavailableText {
  color: black;
  font-size: 24px;
  padding-bottom: 39px;
}

.serviceUnavailableErrorCode {
  padding-top: 22px;
  padding-bottom: 22px;
  font-size: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #BBBBBB;
  line-height: 105px;
}

.link {
  font-weight: 500;
  font-size: 16px;
  color: white;
  background-color: var(--app-color);
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
}

@import '../../../../mixin.scss';

.bannerContainer {
  position: relative;
  @include fullScreenWidth();
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: right center !important;
  padding-left: 0px;
  margin-top: -64px;

  @include for-size(phone-only) {
    height: 516px;
    padding-bottom: 25px;
    background-position: -580px center !important;
    justify-content: flex-end;
    align-items: flex-end;
    padding-left: 10px;
  }

  @media only screen and (min-width: 835px) and (max-width: 1080px) {
    background-position: -300px center !important;
  }

  @include for-size(tablet-portrait-up) {
    height: 516px;
    align-items: center;
    padding-bottom: 25px;
  }

  @include for-size(desktop-up) {}
}

.bannerVideoContainer {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bannerVideoPlayer {
  object-fit: cover;
}

.bannerContent {
  position: relative;
  z-index: 2;
  @include defaulContentWidth();
  font-size: 47px;
  line-height: 68px;
  width: 100%;
  color: #fff;
  font-weight: 400;
  padding-top: 32px;

  @include for-size(phone-only) {
    font-size: 40px;
    line-height: 50px;
    padding-left: 0;
  }
  @media (max-width: 300px) {
    font-size: 35px;
    line-height: 35px;
  }

  @media (min-width: 835px) and (max-width: 1280px) {
    padding-top: 100px;
  }
}

.bannerTitleContainer {
  margin-bottom: 30px;
  @include for-size(phone-only) {
    margin-bottom: 60px;
  }
}

.bannerTitle {
  margin-bottom: 0;
  font-size: 47px;
  line-height: 68px;
  color: #fff;
  font-weight: 400;

  @include for-size(phone-only) {
      display: none;
  }
}

.bannerTitleMobile {
  margin-bottom: 0;
  display: none;

  @include for-size(phone-only) {
    display: inherit;
    max-width: 330px;
    color: #fff;
    font-size: 40px;
    line-height: 50px;
    padding-left: 0;
  }
}

.findYourWayContainer {
  margin-top: 20px;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 28px;
  color: #fff;
  line-height: 36px;
  @include for-size(phone-only) {
    display: none;
  }
}

.findYourWayMobileContainer {
  display: none;
  @include for-size(phone-only) {
    margin-top: 12px;
    margin-bottom: 0;
    display: inherit;
    font-weight: 400;
    font-size: 22px;
    color: #fff;
    line-height: 30px;
  }
}

.boldBanner {
  font-weight: 700
}

.searchContainer {
  display: flex;
  width: 666px;
  max-width: 100%;
  height: 54px;

  @include for-size(phone-only) {
    height: 44px;
  }
}

.promotionHolder {
  display: flex;
  margin-top: 20px;
  border-radius: 8px;
  max-width: 656px;
  margin-right: 10px;
  display: flex;
  height: 91px;

  @include for-size(tablet-portrait-up) {
    margin-top: 15px;
  }

  @include for-size(phone-only) {
    justify-content: center;
  }
}

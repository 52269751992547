@import './common_variables.scss';
// $breakpoints: (
//   small-phone: 30rem,
//   // 480px
//   phone: 48rem,
//   // 768px
//   tablet: 80rem,
//   // 1280
//   laptop: 105rem,
//   //1680
//   desktop: 105rem,
//   //1680
// );

// @mixin for-size($size) {
//   @if $size==phone-only {
//     @media only screen and (max-width: map-get($breakpoints, 'phone')) {
//       @content;
//     }
//   }

//   @else if $size==tablet-portrait-up {
//     @media only screen and (min-width: map-get($breakpoints, 'phone')) and (max-width: map-get($breakpoints, 'tablet')) {
//       @content;
//     }
//   }

//   @else if $size==desktop-up {
//     @media only screen and (min-width: map-get($breakpoints, 'tablet')) and (max-width: map-get($breakpoints, 'laptop')) {
//       @content;
//     }
//   }

//   @else if $size==big-desktop-up {
//     @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
//       @content;
//     }
//   }

//   @else if $size==all-desktop-up {
//     @media only screen and (min-width: map-get($breakpoints, 'phone')) {
//       @content;
//     }
//   }

//   @else if $size==small-phone-only {
//     @media only screen and (max-width: map-get($breakpoints, 'small-phone')) {
//       @content;
//     }
//   }
// }

// @mixin defaulContentWidth {
//   @include for-size(small-phone-only) {
//     max-width: 100%;
//   }

//   @include for-size(phone-only) {
//     max-width: 100%;
//   }

//   @include for-size(tablet-portrait-up) {
//     max-width: calc(#{map-get($breakpoints, 'tablet')} - 100px);
//   }

//   @include for-size(desktop-up) {
//     max-width: calc(#{map-get($breakpoints, 'laptop')} - 100px);
//   }

//   @include for-size(big-desktop-up) {
//     max-width: #{map-get($breakpoints, 'laptop')}
//   }
// }

@mixin for-size($size) {
  @if $size==phone-only {
    @media only screen and (max-width: #{ $phone-only-up-size }px) {
      @content;
    }
  }

  @else if $size==tablet-portrait-up {
    @media only screen and (min-width: #{ $phone-only-up-size }px) and (max-width: #{ $tablet-portrait-up-size }px) {
      @content;
    }
  }

  @else if $size==tablet-landscape-up {
    @media only screen and (min-width: #{ $tablet-portrait-up-size }px) and (max-width: #{ $tablet-landscape-up-size }px) {
      @content;
    }
  }

  @else if $size==desktop-up {
    @media only screen and (min-width: #{ $tablet-landscape-up-size }px) and (max-width: #{ $desktop-up-size }px) {
      @content;
    }
  }

  @else if $size==big-desktop-up {
    @media only screen and (min-width: #{ $desktop-up-size }px) {
      @content;
    }
  }

  @else if $size==all-desktop-up {
    @media only screen and (min-width: #{ $phone-only-up-size }px) {
      @content;
    }
  }

  @else if $size==small-phone-only {
    @media only screen and (max-width: #{ $small-phone-only-up-size }px) {
      @content;
    }
  }
}

@mixin defaulContentWidth {
  @include for-size(small-phone-only) {
    max-width: 100%;
  }

  @include for-size(phone-only) {
    max-width: 100%;
  }

  @include for-size(tablet-portrait-up) {
    max-width: calc(#{$phone-only-up-size}px - 100px);
  }

  @include for-size(tablet-landscape-up) {
    max-width: calc(#{$tablet-portrait-up-size}px - 100px);
  }

  @include for-size(desktop-up) {
    max-width: calc(#{$tablet-landscape-up-size}px - 100px);
  }

  @include for-size(big-desktop-up) {
    max-width: #{$tablet-landscape-up-size}px;
  }
}

@mixin getThemeVars($theme) {
  @each $var-name, $value in $theme {
    #{$var-name}: #{$value};
  }
}

@mixin fullScreenWidth() {
  $width: calc(100vw - var(--scrollbar-width) - var(--invisible-scrollbar-width));
  width: $width;
  margin-left: calc((100% - #{$width})/2);
}

@mixin skeletonContainer {
  background-color: var(--app-skeleton-primary-color) !important;
  background-image: linear-gradient( 90deg,var(--app-skeleton-primary-color),var(--app-skeleton-secondary-color),var(--app-skeleton-primary-color) ) !important;
  background-size: 200px 100% !important;
  background-repeat: no-repeat !important;
  border-radius: 4px;
  display: flex;
  line-height: 1;
  width: 100%;
  max-width: 100%;
  -webkit-animation: animation-bzdot9 1.2s ease-in-out infinite;
  animation: animation-bzdot9 1.2s ease-in-out infinite;
  pointer-events: none;
  content-visibility: hidden;
  contain-intrinsic-size: auto 1px auto 1px;
  * {
    display: none;
  }
}

@keyframes animation-bzdot9 {
  0%{
    background-position:-200px 0;
  }
  100%{
    background-position:calc(200px + 100%) 0;
  }
}

@mixin noBackgroundButton {
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  padding: 0;
}

@mixin defaultButton {
  @include noBackgroundButton;
  color: white;
  background-color: var(--app-default-btn-color, var(--app-color));
  border-radius: var(--app-default-btn-lg-border-radius, 4px);
  width: 100%;
  height: 44px;
  font-weight: 500;
  font-size: 15px;
  padding: 0;
}

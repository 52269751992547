@import "../chat_overlay/classes.module.scss";
@import "./chat.scss";

.addCreditContainer {
  background: var(--app-overlay-black);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: static;
  z-index: 999999;
}

.advisorImage {
  width: 118px;
  height: 118px;
  left: 131px;
  top: 166px;
  border: 4px solid #FFFFFF;
  filter: drop-shadow(0px 10px 20px rgba(181, 149, 232, 0.5));
  border-radius: 8px;
  margin-bottom: -33px;
}

@import '../../mixin.scss';

.inputContent {
    font-size: 1rem;
    width: 100%;
    border: none;
    border-bottom: 1px solid #c9c9c9;
    border-radius: 0px;
    text-align: center;
    height: 55px;
    margin-top: 15px;
  }

  .inputContent:hover {
    border: none;
    border-bottom: 1px solid #c9c9c9;
    border-radius: 0px;
    outline: none;
  }

  .inputContent:focus {
    border: none;
    border-bottom: 1px solid #c9c9c9;
    border-radius: 0px;
    outline: none;
    outline-style: none;
    box-shadow: none;
  }

  .submitButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44px;
    border-radius: 4px;
    background-color: var(--app-color);
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    outline: none;
    border: none;
  }

  .submitButton:hover {
    background-color: var(--app-color);
    color: white;
    outline: none;
  }

  .submitButton:focus {
    background-color: var(--app-color);
    color: white;
    outline: none;
    box-shadow: none;
  }

  .text {
    font-size: 1rem;
    text-align: center;
  }

  .modalFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .promoCodeModalContainer {
    width: 100%;
  }

  .promoCodeModalHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    font-size: 1.3em;
    color: black;
    padding-left: 10px;
    padding-right: 10px;
  }

  .promoCodeModalBody {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 5px;
    margin-left: 15px;
    margin-right: 15px;
    word-break: break-word;
  }

  .promoCodeModalButtonContainer {
    width: 100%;
  }

  .promoCodeModalButton, .promoCodeModalButton:focus, .promoCodeModalButton:active, .promoCodeModalButton:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-top: solid gray 1px;
    width: 100%;
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    color: var(--app-color);
    cursor: pointer;
    background-color: transparent;
  }

@import "../classes.module.scss";

$pressedPurple: #7943e9;

.startBtnContainer {
  margin-top: 10px;
  margin-bottom: 1.5em;
  width: 90%;
}

.nextBtnContainer {
  margin-top: 10px;
  margin-bottom: 1.5em;
  width: 90%;
}

.tooltipsContainer {
  width: calc(100% + var(--tooltipsExtraWith));
  position: absolute;
  top: var(--tooltipsContainerHeight);
  left: var(--tooltipsLeftValue);
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  background-color: var(--blue-snap-tooltips-background-color);
  padding: 10px;
  border-radius: var(--blue-snap-input-border-radius);
  border: 0.5px solid var(--blue-snap-tooltips-border-color);
  box-shadow: 0px 7px 16px var(--blue-snap-tooltips-border-shadow-color);
  z-index: 1;
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.tooltipsBottomArrow {
  right: var(--tooltipsBottomArrowRight);
  bottom: -4px;
  border-top-color: var(--blue-snap-tooltips-border-color);
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: var(--blue-snap-tooltips-border-color);
  -webkit-box-shadow: 0px 7px 16px var(--blue-snap-tooltips-border-shadow-color);
  box-shadow: 0px 7px 16px var(--blue-snap-tooltips-border-shadow-color);
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;
  background: var(--blue-snap-tooltips-background-color);
  border-style: solid;
  border-width: 0.5px;
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

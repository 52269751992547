@import '../../../mixin.scss';

.dialogTitle {
  width: 100%;
  padding: 1.2em;
  text-align: center;
  color: black;
  border-bottom: 1px solid #ddd;
}

.dialogText {
  width: 100%;
  padding: 1.2em;
  text-align: center;
  color: black;
  word-break: break-word;
}

.cancelButton {
  width: 100%;
  border-top: 1px solid #ddd;
  display: flex;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: .6em;
  justify-content: center;
  align-items: center;
  color: #1ea8df;
  cursor: pointer;
}

.link {
  color: var(--app-color);
}

.okBtn {
  color: var(--app-color);
}

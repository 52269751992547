@import '../../mixin.scss';

.appRoot {
  
  flex-direction: column;
  background-color: var(--app-background-color);
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
}

.drawerHolder {
  margin-top: -64px;
  height: 100vh;
  max-height: 100vh;
}

.rootHeader {
  width: 100%;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  flex: 1;
  z-index: 13;
}

.rootContent {
  position: relative;
  width: 100%;
  justify-content: center;
  display: flex;
  background-color: #ffffff;
  background: linear-gradient(180deg, var(--topGradientColor), #FFF);
  background-size: 100% 300px;
  background-repeat: no-repeat;
}

.container {
  width: 100%;
  max-width: 100%;
  align-self: center;
  justify-self: center;
  margin-left: 0px;
  margin-right: 0px;
  position: inherit;
  @include defaulContentWidth();
}

.fullHeightContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(#{var(--window-height)} - #{var(--header-height)});
  justify-content: space-between;
}

.childrenContainer {
  height: 100%;
}

.closeButton {
  background: transparent;
  cursor: pointer;
  border: none;
  outline: 0;
}

.headerTitle {
  color: #000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  padding-left: 12px;
  font-weight: 500;
}

.transparentOverlay {
  min-height: 100%;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 1000;
}

.drawer {
  width: 300px;
  position: fixed;
  left: 0;
  top: 0;
  display: var(--display);
  flex-direction: column;
  z-index: 1000;
  background-color: white;
}

.drawerMask {
  display: var(--display);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86) 0s, width 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86) 0s;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.invisibleScrollBar {
  background-color: #f0f0f0;
  position: relative;
  z-index: 13;
  width: var(--invisible-scrollbar-width);
}

.smartBannerContainer {
  right: 0px;
  margin-right: 20px;
  margin-bottom: 17px;
  position: fixed;
  justify-content: flex-end;
  text-align: center;
  display: flex;
  bottom:0;
  z-index: 1000;
}

.smartBanner {
  width: 98px;
  height: 98px;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
  background-image: var(--smart-banner-background);
  border-radius: 100%;
  border: none;
  outline: 0;
}

.smartBannerTitle {
  fill: #FFFFFF;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.iosIcon {
  position: absolute;
}

.androidIcon {
  position: absolute;
  padding-left: 8px;
  padding-top: 5px;
}

.cancelBtnContainer {
  width: 26px;
  height: 26px;
  position: absolute;
  padding: 5px;
  bottom: 25px;
  right: -5px;
  visibility: hidden;
  border: none;
  outline: 0;
}

.cancelButton {
  width: 21px;
  height: 20px;
  flex-grow: 0;
  background: url(./smart_banner/img/cancel_button.svg);
  visibility: visible;
  border: none;
  outline: 0;
}

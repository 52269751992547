@import "../../mixin.scss";

@mixin fixPosition {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  z-index: 100;
}

.layout {
  @include fixPosition();
}

.callingScreen {
  background: var(--app-overlay-black);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

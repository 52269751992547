@import '../common/common_main.css';

@font-face {
  font-family: "Poppins";
  src: local("poppins"),
  url('./fonts/poppins/poppins-v21-latin-regular.woff2') format('woff2'), url('./fonts/poppins/poppins-v19-latin-regular.eot') format('embedded-opentype'), url('./fonts/poppins/poppins-v19-latin-regular.woff') format('woff'), url('./fonts/poppins/poppins-v19-latin-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("poppins"),
  url('./fonts/poppins/poppins-v21-latin-500.woff2') format('woff2'), url('./fonts/poppins/poppins-v19-latin-500.eot') format('embedded-opentype'), url('./fonts/poppins/poppins-v19-latin-500.woff') format('woff'), url('./fonts/poppins/poppins-v19-latin-500.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("poppins"),
  url('./fonts/poppins/poppins-v21-latin-700.woff2') format('woff2'), url('./fonts/poppins/poppins-v19-latin-700.eot') format('embedded-opentype'), url('./fonts/poppins/poppins-v19-latin-700.woff') format('woff'), url('./fonts/poppins/poppins-v19-latin-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face { font-family: "Inter"; font-style: normal; font-weight: 400; font-display: swap; src: url("./fonts/inter/Inter-Regular.woff2") format("woff2"), url('./fonts/inter/Inter-Regular.ttf') format('truetype'); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 500; font-display: swap; src: url("./fonts/inter/Inter-Medium.woff2") format("woff2"), url('./fonts/inter/Inter-Medium.ttf') format('truetype'); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 600; font-display: swap; src: url("./fonts/inter/Inter-SemiBold.woff2") format("woff2"), url('./fonts/inter/Inter-SemiBold.ttf') format('truetype'); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 700; font-display: swap; src: url("./fonts/inter/Inter-Bold.woff2") format("woff2"), url('./fonts/inter/Inter-Bold.ttf') format('truetype'); }

@font-face { font-family: "Fira Sans"; font-style: normal; font-weight: 400; font-display: swap; src: url("./fonts/fira/FiraSans-Regular.woff2") format("woff2"), url('./fonts/fira/FiraSans-Regular.ttf') format('truetype'); }
@font-face { font-family: "Fira Sans"; font-style: normal; font-weight: 500; font-display: swap; src: url("./fonts/fira/FiraSans-Medium.woff2") format("woff2"), url('./fonts/fira/FiraSans-Medium.ttf') format('truetype'); }
@font-face { font-family: "Fira Sans"; font-style: normal; font-weight: 600; font-display: swap; src: url("./fonts/fira/FiraSans-SemiBold.woff2") format("woff2"), url('./fonts/fira/FiraSans-SemiBold.ttf') format('truetype'); }
@font-face { font-family: "Fira Sans"; font-style: normal; font-weight: 700; font-display: swap; src: url("./fonts/fira/FiraSans-Bold.woff2") format("woff2"), url('./fonts/fira/FiraSans-Bold.ttf') format('truetype'); }
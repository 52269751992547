@import "../../common_mixin.scss";

.saveBtnContainer {
  display: flex;
  flex-direction: column
}

.guaranteedSafeAndAddCreditCardBtnContainer {
  padding: 15px;
  border-radius: var(--app-guaranteed-safe-border-radius, 15px);
  border: 1px solid var(--app-border-primary-color);
  margin-top: 20px;
}

.guaranteedSafeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.guaranteedSafeLockContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.guaranteedSafeLockImgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.guaranteedSafeLockImg {
  width: 18px;
  height: 18px;
}

.guaranteedSafeText {
  color: var(--app-text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
}

.guaranteedSafeImgsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.guaranteedSafeImgs {
  width: 63px;
  height: 24px;
}

.saveButton {
  @include defaultButton;
  background-color: var(-app-default-btn-color);
  margin-top: 20px;
  font-weight: 700;
  font-size: 16px;
}

.disabledSaveButton {
  @include defaultButton;
  margin-top: 20px;
  font-weight: 700;
  font-size: 16px;
  background-color: #ACAFB6;
}

.errorContainer {
  padding-left: 42px;
  padding-right: 42px;
  padding-top: 40px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorTitleContainer {
  color: var(--app-text-primary);
  font-weight: 500;
  font-size: 16px;
}

.errorTextContainer {
  color: var(--app-text-primary);
  font-size: 13px;
  padding-top: 50px;
  padding-bottom: 38px;
  text-align: center;
  word-break: break-word;
}

.errorOkBtn {
  @include defaultButton;
}

.addCreditCardHolder {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 475px;
  min-width: 475px;
  @include for-size(phone-only) {
    max-width: unset;
    min-width: unset;
  }
}

.addCreditCardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 0px;
  padding-bottom: 40px;
  @include for-size(phone-only) {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0px;
    padding-bottom: 34px;
  }
}

.addCreditCardTitleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 38px;
  padding-bottom: 10px;
  @include for-size(phone-only) {
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 25px;
    padding-bottom: 20px;
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

.addCreditCardTitle {
  font-size: 20px;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  color: var(--app-text-primary);
  @include for-size(phone-only) {
    font-size: 16px;
  }
}

.addCreditCardCloseBtnContainer {
  position: absolute;
  top: 0px;
  right: 0px;
}

.addCreditCardCloseBtn {
  @include noBackgroundButton();
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
}

.addCreditCardCloseImg {
  width: 12px;
  height: 12px;
}

.zenModeLabelConainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--add-credit-card-zen-mode-container-background-color);
  border-radius: 10px;
  padding: 15px;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 10px;
  @include for-size(phone-only) {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.zenModeLabelText {
  color: var(--app-text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

@import "../../common_mixin.scss";

.addCardHeaderBtnContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 40%;
  @include for-size(phone-only) {
    display: none;
  }
}

.addCardHeaderBtnContainerContent {
  display: flex;
}

.addCardHeaderBtnContainerContentSkeleton {
  width: 328px !important;
  height: 58px !important;
  @include skeletonContainer();
}

.skeleton {
  @include skeletonContainer();
}

// WALLET
.wallet {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  @include for-size(phone-only) {
    margin-top: 0px;
  }

  .titlePaymentMethods {
    display: flex;
    color: black;
    font-weight: 700;
    font-size: 54px;
    width: 100%;
    white-space: nowrap;
    @include for-size(phone-only) {
      display: none;
    }
  }

  .backArrow {
    @include noBackgroundButton;
    width: 50px;
    height: 50px;
    margin-right: 20px;
    border-radius: 8px;
    border: 1px solid var(--app-border-gray);
    box-shadow: inset 0px -4px 0px var(--app-border-shadow-gray);
    background: url("./img/back_arrow_default.svg");
    background-size: 10px 18px;
    background-repeat: no-repeat;
    background-position: center;
    @include for-size(phone-only) {
      display: flex;
      width: 76px;
      height: 34px;
      background: url("./img/back_arrow_default_with_back_text.svg");
      background-size: 54px 14px;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 0;
      margin-left: 20px;
      margin-top: 20px;
    }
  }

  .closeBtn{
    display: none;
  }

  .titleRowItems {
    display: flex;
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    @include for-size(phone-only) {
      justify-content: flex-start;
    }
  }
}

// PURCHASE
.purchase {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 25px 35px 0px 35px;
  position: sticky;
  top: 0px;
  background-color: var(--app-background-color);
  color: var(--app-text-primary);
  @include for-size(phone-only) {
    padding: 0 15px;
    padding-top: 20px;
    padding-bottom: 15px;
  }

  .titlePaymentMethods {
    display: flex;
    font-weight: 700;
    font-size: 22px;
    width: 100%;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    @include for-size(phone-only) {
      font-size: 16px;
    }
  }

  .backArrowContainer {
    display: flex;
    align-items: center
  }

  .backArrow {
    @include noBackgroundButton;
    width: 34px;
    height: 34px;
    border-radius: 8px;
    border: 1px solid var(--app-border-gray);
    box-shadow: inset 0px -4px 0px var(--app-border-shadow-gray);
    background: url("./img/back_arrow_default.svg");
    background-size: 6px 12px;
    background-repeat: no-repeat;
    background-position: center;
    @include for-size(phone-only) {
      border: none;
      box-shadow: none;
      background: url("./img/back_arrow.svg");
      background-size: 24px 24px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .closeBtncontainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .closeBtn {
    @include noBackgroundButton();
    display: flex;
    width: 35px;
    height: 35px;
    position: absolute;
    justify-content: center;
}

  .closeImg{
    width: 17px;
    height: 17px;
    @include for-size(phone-only) {
      width: 14px;
      height: 14px;
    }
  }

  .titleRowItems {
    display: flex;
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    height: 50px;
    @include for-size(phone-only) {
      height: 35px;
    }
  }
}

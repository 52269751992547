@import '../../../mixin.scss';

$optionWidth: 80%;
$radius: 6px;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 8;

  @include for-size(phone-only) {
    font-size: 12px;
  }
}

.containerWithIcon {
  width: 100%;
  height: 100%;
  max-width: 400px;

  @include for-size(phone-only) {
    max-width: unset;
  }
}

.newUserContainerWithIcon {

  width: 100%;
  height: 100%;
  max-width: 500px;

  @include for-size(phone-only) {
    max-width: unset;
  }
}

.topImageContainer {
  width: 100%;
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: center;

  .topImage {
    width: 50px;
    height: 50px;
  }
}

.dialogContainer {
  width: 100%;
  height: 100%;
  border-radius: $radius;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @include for-size(phone-only) {
    border-radius: 0;
  }
}

.closeBtn,
.closeBtn:hover,
.closeBtn:focus {
  position: absolute;
  top: 12px;
  right: 12px;
  border: none;
  color: var(--app-color);
  background-color: unset;
  font-size: 16px;
  cursor: pointer;
}

.closeIcon {
  width: 20px;
}

.paygLabelText {
  width: $optionWidth;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: #531FA5;
}

.descriptionText {
  width: $optionWidth;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #636570;
}

.infoBottomContainer {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.selectTitleContainer {
  margin: 40px 20px 0 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
}

.additionalOption {
  background: #FFFFFF;
  border-radius: 50px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  padding: 5px 15px;
  width: fit-content;
  cursor: pointer;
  white-space: pre-line;
}

.clientCreditBalanceContainer {
  display: flex;
  color: #000000;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
}

.clientAvailableCreditSkeleton {
  @include skeletonContainer();
  width: 40px;
  height: 20px;
}

.divider {
  display: flex;
  height: 1px;
  max-height: 1px;
  margin-top: 20px;
  width: 100%;
  background: #D7BDFF;
  border: #D7BDFF;
}

.additionalOptionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  justify-self: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

.carouselMain {
  background-color: white;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.continueCallText {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: var(--app-color);
}

.advisorNameText {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: var(--app-color)
}

.saveUpLabelContainer {
  width: 122px;
  height: 122px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  margin: 3px;
  border-top-left-radius: 20px;
}
.saveUpLabelContainer:after {
  content: '';
  background: #7BD137;
  width: 142%;
  height: 142%;
  position: absolute;
  transform: rotate(45deg) translate(-71%,0);
  top: 0;
  left: 0;
}

.saveUpLabelTextContainer {
  width: 100%;
  position: relative;
  z-index: 10;
  transform: rotate(-45deg) translate(-25%, 5%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.saveUpLabelText {
  font-size: 12px;
  font-weight: 400;
  color: var(--app-white);
  line-height: 22px;
}

.saveUpLabelAmount {
  font-size: 22px;
  font-weight: 700;
  color: var(--app-white);
  line-height: 22px;
}

.saveUpDiscountLabelText {
  font-size: 15px;
  font-weight: 600;
  color: var(--app-white);
  line-height: 22px;
}

.saveUpDiscountLabelAmount {
  font-size: 12px;
  font-weight: 400;
  color: var(--app-white);
  line-height: 22px;
}

.zenModeLabelContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 48px;
  margin-right: 48px;
  margin-bottom: 21px;
  font-size: 12px;
  font-weight: 400;
  color: var(--app-text-secondary);
}

.newUserPaygYourAvailableBalanceContainer {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 24px;
}

.colorAdditionalText {
  color: var(--app-color);
}

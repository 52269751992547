@import '../../../mixin.scss';

.creditCardValidateContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--app-background-color);
}

.creditCardValidateBuyBtnContainer {
  @include defaultButton();
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  color: var(--app-white);
  height: 50px;
  font-weight: 700;
  font-size: 16px;
}

.creditCardValidateDisableBuyButton {
  background-color: var(--app-disabled-btn-color);
  pointer-events: none;
}

.skeleton {
  @include skeletonContainer();
}

.creditCardValidateErrorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.creditCardValidateErrorImgContainer {
  display: flex;
  align-self: center;
  justify-content: center;
}

.creditCardValidateErrorImg {
  width: 44px;
  height: 44px;
}

.creditCardValidateErrorTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 700;
  margin-top: 8px;
  text-align: center;
}

.creditCardValidateErrorText {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  margin-top: 16px;
  text-align: center;
}

.container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    display: flex;
}

.squares {
    width: 64px;
    height: 64px;
    pointer-events: none;
}

.squareGif {
  width: 64px;
  height: 64px;
  pointer-events: none;
  background-image: url('./img/square.gif');
  background-size: cover;
}


@import "../../../mixin.scss";

.chatContainer {
  display: flex;
  flex-direction: column;
}

.couponBubbleWrapper {
  max-width: 500px;
  display: flex;
  flex-direction: row;
  align-self: 'flex-start';
  @include for-size(phone-only) {
    max-width: 100%;
   }
}

.couponBubbleContainer {
  padding: 10px 15px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  position: relative;
  min-height: 34px;
  background: var(--background-secondary);
  color: #121212;
  border-bottom-left-radius: 0;
}

.couponBubbleLabelContainer {
  width: 268px;
  height: 70px;
  display: flex;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  @include for-size(phone-only) {
    width: 238px;
  }
}

@mixin couponBubbleLabelBadgeContainerAbsolute {
  position: absolute;
  top: 0%;
  right: 0%;
  transform: translateY(-50%)  translateX(5%);
  z-index: 1;
  border-radius: 10px;
  padding: 2px 8px 2px 8px;
  font-size: 12px;
  line-height: 16px;
}

.couponBubbleLabelBadgeContainerExpired {
  @include couponBubbleLabelBadgeContainerAbsolute();
  background-color: var(--app-white);
  color: #707070;
  border: 0.5px solid #E1E1E1;
}

.couponBubbleLabelBadgeContainerReached {
  @include couponBubbleLabelBadgeContainerAbsolute();
  background-color: var(--mbw-green-accent-color);
  color: var(--app-white);
}

.couponBubbleMessage {
  padding: 0 8px 0 8px;
}

.couponBubbleMessageText {
  font-size: 14px;
  font-weight: 400;
  word-break: break-word;
}

.couponBubbleNoteMessage {
  font-size: 12px;
  color: #8C909C;
  border-top: 1px solid var(--border-primary);
  margin-top: 8px;
  padding-top: 8px;
}

.couponBubbleTime {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
}

.couponBubbleMessageTimeText {
  font-size: 10px;
  font-weight: 400;
}

.couponBubbleAvatarContainer {
  display: flex;
  align-items: flex-end;
  padding-right: 7px;
  min-width: 41px;
}

@import '../../../../mixin.scss';

$maxWidth: 370px;

.advisorHeaderContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  border-radius: 40px;
  background-color: #FFF;
  padding: 30px;
  padding-top: 44px;

  @include for-size(phone-only) {
    flex-direction: column;
    padding: 0;
    align-items: center;
    border-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.avatarHolder {
  width: 30%;
  min-width: 382px;
  transition: all .3s ease;

  @include for-size(phone-only) {
    width: 100%;
    max-width: 400px;
    max-height: 400px;
    height: calc(100vw - 20px);
    min-width: auto;
  }
}

.advisorInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-left: 30px;

  @include for-size(phone-only) {
    flex-direction: column-reverse;
    margin-left: 0;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.basicInfoContainer {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 10px;

  @include for-size(phone-only) {
    display: none;
  }
}

.advsiorTitleContainer {
  display: flex;
}

.topIconsAndRatingContainer {
  display: flex;
}


.ratingHolder {
  margin-left: 10px;
}

.advisorModesContainer {
  display: flex;
  width: 100%;
  margin-top: 19px;
  position: relative;

  @include for-size(phone-only) {
    justify-content: center;
    max-width: $maxWidth;
    align-self: center;
  }
}

.advisorTitleContainer {
  display: grid;
  flex-direction: column;
  width: 100%;
}

.advisorTitle {
  display: flex;
  flex-direction: column;
  font-size: 34px;
  font-weight: 600;
  line-height: 33px;
  word-break: break-all;
  margin: 0;
  margin-bottom: 0 !important;
  overflow: hidden;
}

.advisorTitleText {
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 5px;
}

.advisorSubtitle {
  color: #9D9D9D;
}

.advisorSubtitleText {
  font-size: 22px;
  font-weight: 300;
  color: #9D9D9D;
}

.reviewsContainer {
  display: flex;
  width: 100%;
  align-items: center;

  @include for-size(phone-only) {
    max-width: 200px;
  }
}

.noReviews {
  color: #B7B7B7;
  font-size: 20px;
  margin-left: 50px;

  @include for-size(phone-only) {
    font-size: 12px;
    margin-left: auto;
  }
}

.advsiorReadingsContainer {
  display: flex;
  font-weight: 700;
  font-size: 26px;
  margin-top: 25px;
  align-items: flex-end;
  line-height: 42px;

  @include for-size(phone-only) {
    border: 1px solid #EAEAEA;
    font-size: 13px;
    margin-top: 19px;
    justify-content: space-between;
    padding-left: 13px;
    padding-right: 13px;
    border-radius: 5px;
    height: 55px;
    align-items: center;
    max-width: $maxWidth;
    width: 100%;
    min-width: 300px !important;
    line-height: 1;
  }
}

.advisorCTAs {
  position: relative;
  display: flex;
  justify-content: center;
  @include for-size(phone-only) {
    max-width: 370px;
    width: 100%;
    min-width: 300px !important;
  }
}

.ctaSkeletonContainer {
  display: flex;
  width: 100%;
}

.ctaSkeleton {
  @include skeletonContainer();
  width: 148px !important;
  margin-left: 9px;
  height: 207px !important;

  @include for-size(phone-only) {
    width: 100px !important;
    margin-left: 9px;
    height: 147px !important;
  }
}

.ctaSkeletonFullWidth {
  @include for-size(phone-only) {
    max-width: unset !important;
    width: 100% !important;
  }
}

.ctaSkeleton:first-of-type {
  margin-left: 0;
}

.busyLableContainer {
  background-color: var(--app-busy-label-color);
  position: absolute;
  height: 21px;
  font-weight: 700;
  left: 20px;
  right: 20px;
  bottom: 15px;
  border-radius: 41px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;

  @include for-size(phone-only) {
    font-size: 11px;
    bottom: 10px;
    height: 16px;
    left: 15px;
    right: 15px;
  }
}

.couponLabelContainerBrowser {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  @include for-size(phone-only) {
    display: none;
  }
}

.couponLabelContainerMobile {
  display: none;
  @include for-size(phone-only) {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
  }
}

//terminatedAdvisor
.terminatedAdvisor {
  .reviewsContainer{
      display: none;
  }

  .advsiorReadingsContainer {
      display: none;
  }

  .aboutMyServicesContainer {
    font-weight: 700;
    font-size: 22px;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 40px;

    @include for-size (phone-only) {
      margin-top: 25px;
      font-size: 17px;
      line-height: 24px;
    }
  }

  .aboutMyServicesTitle {

  }

  .aboutMyServicesText {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    margin-top: 18px;
    white-space: pre-wrap;
    word-break: break-word;

    @include for-size(phone-only) {
      font-size: 15px;
      margin-top: 0;
      line-height: 22px;
    }
  }

  .aboutMyServicesReadMoreBtn {
    font-weight: 700;
    font-size: 20px;
    border: none;
    background-color: transparent;
    padding: 0;
    padding-left: 3px;
    cursor: pointer;
    @include for-size(phone-only) {
      font-size: 15px;
    }
  }

  .advisorModesContainer {
    @include for-size(phone-only) {
      justify-content: flex-start;
    }
  }

  .promotionLabelContainerBrowser {
    display: none;
  }

  .promotionLabelContainerMobile {
    display: none;
  }
}

@import '../../../mixin.scss';

.homeButtonContainer{
  width: 100%;
  max-width: 1000px;
  margin-top: 115px;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
}

.homeButton {
  color: #565553;
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding-left: 10px;
  font-size: 1.5em;
  text-decoration-line: underline;
  outline: 0;
}

.aboutMeContainer {
  font-weight: 700;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 40px;
  max-width: 500px;
  width: 100%;

  @include for-size (phone-only) {
    margin-top: 25px;
    font-size: 17px;
    line-height: 24px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.aboutMeText {
  color: black;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  margin-top: 18px;
  white-space: pre-wrap;
  word-break: break-word;

  @include for-size(phone-only) {
    font-size: 15px;
    margin-top: 0;
    line-height: 22px;
  }
}

.aboutMeReadMoreBtn {
  color: black;
  font-weight: 700;
  font-size: 20px;
  border: none;
  background-color: transparent;
  padding: 0;
  padding-left: 3px;
  cursor: pointer;
  @include for-size(phone-only) {
    font-size: 15px;
  }
}

.skeleton {
  width: 148px !important;
  margin-left: 9px;
  height: 207px !important;

  @include for-size(phone-only) {
    width: 100px !important;
    margin-left: 9px;
    height: 147px !important;
  }
}

.specialitiesAndAboutMeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include for-size(phone-only) {
    flex-direction: column;
  }
}

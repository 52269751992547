$descriptionSize: 0.9rem;

.introScreenContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.introContainer {
  width: 100%;
  background: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  .buttonDisabled {
    background: #d8d8d8;
  }
}

.introduceYourself {
  font-size: 1.2rem;
  color: black;
  padding: 0.9em;
}

.nickNameContainer {
  width: 100%;
  margin-bottom: 0.5em;
}

.nicknameDescriptionContainer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.nicknameDescriptionText {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
}

.nicknameDescriptionBoldText{
  font-size: 13px;
  font-weight: 700;
  color: #000000;
}

.fullWidth {
  width: 100%;
}

.genderSelectorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin radioBtnContent() {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
}

.radioBtnNotSelectedContent {
  color: #000000;
  @include radioBtnContent();
}

.radioBtnSelectedContent {
  color: #FFFFFF;
  @include radioBtnContent();
}

.radioBtnContentGenderIcon {
  margin-right: 5px;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dateDescriptionContainer {
  font-size: $descriptionSize;
  margin-bottom: 1em;
}

.startBtn {
  background: var(--app-color);
  color: #ffffff !important;
  height: 50px !important;
  width: 100% !important;
  font-size: 16px;
  font-weight: 700;
  border: none;
  border-radius: 8px !important;
  margin-top: 20px
}

.introPopupXContent {
  color: #b7bcbd;
  border: none;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 8px;
}

.introPopupXButton, .introPopupXButton:hover, .introPopupXButton:focus, .introPopupXButton:active {
  color: #b7bcbd !important;
  border: none;
  cursor: pointer;
  background-color: white !important;
}

.errorModalFooter {
  border-top: solid gray 1px;
  text-align: center;
}

.errorModalHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  font-size: 1.3em;
  color: black;
  padding-left: 10px;
  padding-right: 10px;
}

.errorModalBody {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
}

.errorModalOkButton {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  border: none;
  background: none;
}

.popupTitleContainer {
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #D9D9D9;
  padding-top: 20px;
  padding-bottom: 20px;
}

.popupTitleTextContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.popupTitle {
  font-size: 17px;
  font-weight: 700;
  color: #000000;
}

.popupSubTitle {
  font-size: 15px;
  font-weight: 400;
  color: #000000;
  margin-top: 10px;
}

.popupCloseBtn {
  width: 40px !important;
  height: 40px !important;
  border: none;
  background: transparent !important;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  margin: 0px !important;
}

.datePickerConfirmBtn {
  background: var(--app-color) !important;
  color: #ffffff !important;
  height: 50px !important;
  width: calc(100% - 40px) !important;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  border-radius: 8px !important;
  margin-top: 32px
}

.labelContainer {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 7px;
}

.labelText {
  color: #ABABAB;
  font-size: 14px;
  font-weight: 600;
}

.labelSubText {
  color: #ABABAB;
  font-size: 14px;
  font-weight: 400;
}

@mixin input() {
  width: 100%;
  color: black;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  border: solid 1px #DDDDDD;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
  height: 44px;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.inputClass, .inputClass:hover, .inputClass:focus, .inputClass:active{
  @include input();
}

.errorContainer {
  color: #FF4242;
  font-size: 13px;
  font-weight: 600;
  margin-top: 7px;
}

.generalPopupContentContainer {
  padding: 15px;
  text-align: center;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.link {
  color: blue;
}

.okBtnContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.okBtn {
  background: var(--app-color);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 60px;
  padding: 5px;
}

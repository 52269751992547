@import '../../../../mixin.scss';

.filterContainer {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    justify-content: space-between;
    background-color: white;
    flex-direction: column;
    border: 1px solid #EADDED;
    box-shadow: 0px 10px 10px #00000026;

    @include for-size(phone-only) {
        display: none;
    }
}

.popoverFilters {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;

    .filterFieldsContainer {
      flex-direction: column;
      padding: 0;
  }

  .itemContainer {
      margin-left: 0;
  }

  .itemHolder {
    padding-top: 25px;
  }

  .switcherContainer {
    margin-left: 0px;
    margin-top: 40px;
  }
}

.filterButtonContainer {
    display: none;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;

    @include for-size(phone-only) {
        display: flex;
    }
}

.displayFilterButton {
    @include noBackgroundButton();
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #FFF;
    border: 0.5px solid #DCDCDC;
    box-shadow: inset 0px -3px 0px rgba(202, 202, 202, 0.25);
    border-radius: 8px;
    font-weight: 500;
    font-size: 15px;
    padding: 10px;
    line-height: 21px;
}

.displayFilterButtonImg {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.searchButton {
    @include noBackgroundButton();
    min-width: 44px;
    min-height: 44px;
    max-width: 44px;
    max-height: 44px;
    border: 0.5px solid #DCDCDC;
    box-shadow: inset 0px -3px 0px rgba(202, 202, 202, 0.25);
    border-radius: 8px;
    margin-left: 8px;
    background-color: white;
}

.itemContainer {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    margin-left: 15px;

    @include for-size(phone-only) {
        margin-left: 0;
    }
}

.itemContainer:first-of-type {
    margin-left: 0;
}

.itemHolder {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include for-size(phone-only) {
        padding-top: 25px
    }
}

.dropdown {
    @include noBackgroundButton;
    display: flex;
    width: 100%;
    height: 55px;
    border: 1px solid #E2E2E2;
    box-shadow: inset 0px 1px 4px #DDDDDD;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 20px;
    font-size: 18px;
    font-weight: bold;
}

.arrowImg {
  width: 12px;
  height: 8px;
}

.itemTitle {
    line-height: 20px;
    color: #B1B1B1;
    font-size: 16px;
    padding-bottom: 12px;

    @include for-size(phone-only) {
        padding-bottom: 5px;
    }
}

.dropdownMenu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 11px);
    left: 0;
    right: 0;
    transition: all .3s ease;
    border: 1px solid #E2E2E2;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.3);
    z-index: 100;
    transition: visibility 0s, opacity 5s linear;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 17px;
    padding-bottom: 17px;
    border-radius: 8px;

    @include for-size(phone-only) {
        padding: 6px;
    }
}

@mixin dropdownOptionDefaults {
    @include noBackgroundButton();
    display: flex;
    align-items: center;
    width: 100%;
    height: 52px;
    font-size: 18px;
    vertical-align: middle;
    padding-left: 13px;

    @include for-size(phone-only) {
        height: 40px;
    }
}

.dropdownOption {
    @include dropdownOptionDefaults();
}

.selectedDropdownOption {
    @include dropdownOptionDefaults();
    background-color: #F4F4F4;
    border-radius: 4px;
    font-weight: 700;
}

.sliderRoot {
    display: flex;
    width: 100%;
    justify-items: center;
    height: 5px;
    position: relative;
    background-color: #DDD;
}

.rail {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #DDDDDD;
    cursor: pointer;
}

.sliderContainer {
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
}

.sliderLeftHandle {
    position: absolute;
    margin-left: -4px;
    margin-top: -10px;
    z-index: 2;
    cursor: pointer
}

.sliderRigthHandle {
    position: absolute;
    margin-left: -22px;
    margin-top: -10px;
    z-index: 2;
    cursor: pointer
}

.sliderArrowImg {
  width: 26px;
  height: 33px;
}

.sliderHandlePrice {
    margin-top: -55px;
    margin-left: -12px;
    font-size: 14px;
    font-weight: 500;
}

.sliderTrack {
    position: absolute;
    height: 5px;
    z-index: 1;
    background-color: #000;
    border-radius: 5px;
    cursor: pointer;
}

.sliderPrices {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: -25px;
    color: #B4B4B4;
    font-size: 12px;

    @include for-size(phone-only) {
        bottom: -16px;
    }
}

.switcherContainer {
    display: flex;
    width: 100%;
    align-items: center;
    margin-left: 20px;
    margin-top: 20px;
    justify-content: center;

    @include for-size(phone-only) {
        margin-left: 0px;
        margin-top: 40px;
    }
}

.switcherTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    margin-left: 11px;
    word-break: break-word;
}

.applyButton {
    @include noBackgroundButton();
    margin-top: 20px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D7D7D7;
    box-shadow: inset 0px -4px 0px rgba(202, 202, 202, 0.25);
    border-radius: var(--app-default-btn-lg-border-radius);
    font-size: 18px;
    font-weight: 500;
    padding-left: 15px;
    padding-right: 15px;
    height: 55px;

    @include for-size(phone-only) {
        font-weight: 700;
    }
}

.popoverContainer {
    display: flex;
    height: 100px;
    width: 100px;
    background-color: green;
}

.filterSearchContainer {
    display: flex;
    width: 100%;
    height: 55px;
    border: 1px solid #E2E2E2;
    box-shadow: inset 0px 1px 4px #DDDDDD;
    border-radius: 8px;
    align-items: center;
    font-size: 18px;
    padding-left: 10px;
}

.input {
    background: none;
    border: none;
    width: 100%;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    outline: none;
}

.input:focus {
    outline: none;
    border: none;
}

.input:hover {
    border: none;
}

.searchTitle {
    font-weight: 500;
    font-size: 22px;
    margin-top: 26px;

    @include for-size(phone-only) {
        margin-top: 0px;
        font-weight: 700;
    }
}

.searchIcon {
    padding-left: 5px;
    padding-right: 5px;
}

.searchIconImg {
  width: 17px;
  height: 16px;
}

.selectedPriceContainer {
    padding: 2px;
    padding-left: 7px;
    padding-right: 7px;
    background-color: #ECECEC;
    color: black;
    font-weight: 500;
    font-size: 12px;
    border-radius: 29px;
}

.filterFieldsContainer {
    display: flex;
    padding: 30px;
    padding-top: 0;
    @include for-size(phone-only) {
        flex-direction: column;
        padding: 0;
      }
}

.newToPGSectionContainer {
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #EADDED;

    @include for-size(phone-only) {
      display: none;
    }
  }

  .viewForNewAffiliateUser {
    font-size: 26px;
    font-weight: 500;
    width: 100%;
    @include for-size(phone-only) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .newToPGSectionMarksContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    flex-direction: row;
    width: 100%;
    @include for-size(phone-only) {
      margin-top: 10px;
      flex-direction: column;
      align-items: start;
    }
  }

  .newToPGSectionMarkContainer {
    display: flex;
    justify-content: center;
    @include for-size(phone-only) {
      margin-top: 15px;
      justify-content: start;
      padding-inline-start: 12px;
    }
  }

  .newToPGSectionMarkContainer:first-of-type {
    margin-top: 0px;
  }

  .newToPGSectionMarkImg {
    width: 50px;
    height: 50px;
    @include for-size(phone-only) {
      display: none;
    }
  }

  .newToPGSectionMarkTextContainer {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    margin-left: 10px;
    @include for-size(phone-only) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .newToPGMobileSectionContainer {
    display: none;
    @include for-size(phone-only) {
        display: unset;
      }
  }

  .filtersFieldsContainer {
    display: flex;
    flex-direction: row;
  }

  .closeButton {
    @include noBackgroundButton();
    padding: 20px 30px 0px 30px;
    display: flex;
    align-self: flex-end;
  }

  .clickHereBtn {
    @include noBackgroundButton();
    font-size: 18px;
    color: var(--app-color);
    font-weight: 700;
    @include for-size(phone-only) {
      font-size: 13px;
    }
  }

  .clickHereText {
    padding-top: 30px;
    font-size: 18px;
    font-weight: 700;
    @include for-size(phone-only) {
      display: none;
    }
  }

  .boldText {
    font-weight: 700;
  }

  .normalText {
    font-weight: 400;
  }

  .bullet {
    display: none;
    @include for-size(phone-only) {
      display: flex;
      width: 8px;
      min-width: 8px;
      height: 8px;
      border-radius: 50%;
      background: var(--app-color);
      margin-top: 6px;
    }
  }

  .mobileViewForNewAffiliateUser {
    display: none;
    @include for-size(phone-only) {
      display: flex;
      margin-top: 15px;
    }
  }

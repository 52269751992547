@import "../common_mixin.scss";

@mixin fixPosition {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  z-index: 1000;
}

.overlayContainer {
  background: var(--app-overlay-black);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  @include fixPosition();
}

.arrowDiv {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow {
  width: 26px;
  height: 21px;
}

.closeBtn,
.closeBtn:hover,
.closeBtn:focus {
  border: none;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  background: none;
  padding: 0px;
  outline: 0;
}

.generalContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s;

  @include for-size(phone-only) {
    width: 100%;
    bottom: 0;
    max-width: 100%;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.generalHolder {
  position: relative;
  width: 100%;
}

.topHeaderConteinerLogoContainer {
  display: none;
  justify-items: center;
  justify-content: center;
  margin-bottom: -30px;
  position: relative;
  z-index: 10;
  background-color: transparent;
  width: 100%;
}

.topHeaderConteinerLogo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  border: solid 10px white;
  display: none;
}

.popupContainer {
  position: relative;
  z-index: 1;
  background-color: var(--app-background-secondary-color) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  max-width: 475px;

  -webkit-mask-image: -webkit-radial-gradient(white, black); // made overflow: hidden

  @include for-size(phone-only) {
    width: 100%;
    border-radius: 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: unset;
  }
}

.topHeaderConteiner {
  width: 100%;
  border-bottom: 1px solid #D9D9D9;
  justify-content: space-between;
  height: 50px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  align-items: baseline;
}

.emptyDiv {
  display: flex;
  height: var(--height);
  @include for-size(phone-only) {
    width: 100%;
  }
}

.CHAT_ENDED {
  .emptyDiv {
    @include for-size(phone-only) {
      width: 100%;
      height: 0;
    }
  }
  .popupContainer {
    border-radius: 20px;
    overflow: visible;
    min-width: 500px;
    min-height: 382px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    @include for-size(phone-only) {
      padding-bottom: 0px;
      min-width: unset;
      min-height: unset;
      width: 90%;
      margin: 0 auto;
      border-radius: 20px;
    }
  }

  .topHeaderConteiner {
    height: 31px;
  }

  .overlayContainer {
    background: transparent!important;
  }

  .generalContainer {
    @include for-size(phone-only) {
      height: 100%;
    }
  }

  .generalHolder {
    height: 100%;
  }
}

.CHAT_ENDED_RATE_REVIEW {
  .popupContainer {
    border-radius: 20px;
    overflow: visible;
    min-width: 300px;
    min-height: 610px;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    @include for-size(phone-only) {
      padding-bottom: 0px;
      border-radius: 0px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }

  .generalContainer {
    @include for-size(phone-only) {
      height: 100%;
    }
  }

  .generalHolder {
    height: 100%;
  }
}

.closeButtonImg {
  width: 15px;
  height: 15px;
}

.INTRO_DATE_PICKER {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  .generalContainer {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: var(--app-overlay-black);
    left: 0;
    bottom: 0;
    z-index: 10;

    @include for-size(phone-only) {
      position: absolute;
      max-width: 400px;
    }
  }

  .emptyDiv {
    display: none !important;
  }

  .generalHolder {
    width: unset;
  }
}

.INTRO {
  height: 100%;
  .generalContainer {
    position: relative;
  }

  .popupContainer {
    position: relative;
    min-width: 315px;
    max-width: 375px;
    @include for-size(phone-only) {
      max-width: unset;
    }
  }

  .emptyDiv {
    display: none;
    @include for-size(phone-only) {
      display: flex;
      width: '100%';
      height: var(--height);
    }
  }
}

.ABSOLUTE_POPUP {
  .overlayContainer {
    position: absolute;
    background: var(--app-overlay-black) !important;
  }

  .generalContainer {
    width: unset;
  }

  .popupContainer {
    border-radius: 24px;
    overflow: hidden;
    @include for-size(phone-only) {
      border-top-left-radius: 24px !important;
      border-top-right-radius: 24px !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.SCROLLED_POPUP {
  height: 100%;
}

.CENTERED {
  .generalContainer {
    width: unset;
  }

  .popupContainer {
    border-radius: var(--app-popup-container-border-radius, 24px) !important; // need wor PO version 6.0.0
    min-width: unset;
    min-height: unset;
  }

  .emptyDiv {
    display: none !important;
  }
}

.BOTTOM {
  .overlayContainer {
    align-items: flex-end;
  }
  .popupContainer {
    min-height: unset;
  }
}

.REGISTER {
  height: 100%;
  .generalContainer {
    position: relative;
    @include for-size(phone-only) {
      min-height: 100%;
      max-width: 100%;
    }
  }
  .generalHolder {
    @include for-size(phone-only) {
      min-height: 100%;
    }
  }

  .popupContainer {
    position: relative;
    max-width: unset;
    @include for-size(phone-only) {
      min-height: 100%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .emptyDiv {
    display: flex;
    width: 100%;
    height: var(--height);
  }
}

.JUMIO_POPOVER {
  .generalContainer {
    width: unset;
  }

  .popupContainer {
    border-radius: 24px !important;
    min-width: unset;
    min-height: unset;
    -webkit-mask-image: unset;
  }

  .emptyDiv {
    display: none !important;
  }
}

.VIDEO_POPOVER {
  .popupContainer {
    -webkit-mask-image: unset;
  }
}


@import '../../../mixin.scss';

.paginationPagesContainer {
  widows: 100%;
  height: 100px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin paginationPageItem() {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  @include for-size(phone-only) {
    width: 40px;
    height: 40px;
    margin-left: 5px;
  }
 }

.paginationPageItemNotActive {
  @include paginationPageItem();
  color: black;
  font-size: 17px;
  font-weight: 600;
}

.paginationPageItemActive, .paginationPageItemActive:hover {
  @include paginationPageItem();
  background-color: var(--app-color) !important;
  color: #ffffff;
  border-radius: 8px;
  font-size: 17px;
  font-weight: 600;
  cursor: auto;
}

.paginationPageItemSeen, .paginationPageItemSeen:hover {
  @include paginationPageItem();
  background-color: #e8e8e8 !important;
  color: #575757;
  border-radius: 8px;
  font-size: 17px;
  font-weight: 600;
  box-shadow: inset 0 2px 0 0 rgba(202, 202, 202, 0.5);
}

.paginationPageArrowContainer {
  @include paginationPageItem();
  border-radius: 8px;
  box-shadow: inset 0 -4px 0 0 rgba(202, 202, 202, 0.25);
  border: solid 1px #d7d7d7;
  background-color: #fff;
  cursor: pointer;
}

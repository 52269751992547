@import '../../mixin.scss';

.container {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionButton {
  @include noBackgroundButton;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  border-radius: var(--app-default-btn-sm-border-radius);
  background-color: var(--app-color);
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.disableActionBtn {
  background: #cecece;
}

.navigationButton {
  @include noBackgroundButton;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 100%;
  margin-top: 10px;
  font-size: 15px;
  color: var(--app-color);
}

.error {
  color: #e05959;
}

.text {
  text-align: center;
  padding-top:1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow {
  color: var(--app-color);
  font-size: 2em;
}

.arrowDiv {
  position: absolute;
  top: 2em;
  left: 2em;
}

.forgotModalTitle {
  padding-top: 10px;
  font-weight: 700;
}

.forgotModalContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  padding: 10px;
  text-align: center;
}

.modalContentOkBtnContainer {
  display: flex;
  align-self: center;
  justify-content: center;
}

.modalContentOkBtn {
  background: transparent;
  border: none;
  color: var(--app-color);
  cursor: pointer;
  width: 44px;
  padding: 5px;
}

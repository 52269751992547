@import "../common_mixin.scss";

.sourcesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--app-background-color);
  border: 1px solid #E4E4E4;
  border-radius: 40px;
  padding: 80px 10px;
  @include for-size(phone-only) {
    padding: 0 10px;
    border: none;
    background-color: var(--app-background-color);
    border-radius: 0;
  }
}

.callSourcesContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
}

.placeholderContainer {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
  padding-top: 15px;
  @include for-size(phone-only) {
    padding-top: 50px;
  }
}

.placeholderImg {
  width: var(--no-payment-method-img-width);
  height: var(--no-payment-method-img-width);
  padding-bottom: 16px;
}

.placeholderText {
  font-weight: 400;
  font-size: 20px;
  white-space: pre-wrap;
  color: var(--mbw-text-secondary);
  @include for-size(phone-only) {
    max-width: 300px;
    white-space: unset;
    font-weight: 400;
    line-height: 20px;
    font-size: 15px;
  }
}


// ROOT_PURCHASE
.root_purchase {
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--app-background-color);;
  min-width: 475px;
  @include for-size(phone-only) {
    min-width: unset;
  }

  .paymentMethodsContentContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    background-color: var(--app-background-color);
    @include for-size(phone-only) {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .addCardBottomBtnContainer {
    @include noBackgroundButton;
    width: 100%;
    display: flex;
    font-weight: 700;
    font-size: 14px;
    background-color: var(--app-background-color);
    color: black;
    align-items: center;
  }

  .placeholderContainer {
    display: none;
  }
}

// ROOT_WALLET
.root_wallet {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: var(--app-background-color);
  @include for-size(phone-only) {
    height: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .paymentMethodsContentContainer {
    padding-top: 22px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: var(--app-background-color);
    @include for-size(phone-only) {
      display: flex;
      justify-content: space-between;
      height: 100%;
      background-color: var(--app-background-color);
    }
  }

  .addCardBottomBtnContainer {
    display: none;
    @include for-size(phone-only) {
      width: 100%;
      display: flex;
      bottom: 0;
      background-color: var(--app-background-color);
    }
  }

  .addCardBottomBtnContainerDesktop {
    padding-top: 24px;
    display: flex;
    background-color: var(--app-background-color);

    img {
      display: none;
    }

    @include for-size(phone-only) {
      display: none;
    }
  }
}

@import "../../mixin.scss";

.chatContainer {
  display: flex;
  flex-direction: column;
}

.chatBubbleWrapper {
  max-width: 500px;
  display: flex;
  flex-direction: row;
  @include for-size(phone-only) {
    max-width: 100%;
   }
}

@mixin chatBubble {
  padding: 10px 15px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  position: relative;
  min-height: 34px;
}

.chatBubbleOther {
  @include chatBubble();
  background: var(--background-secondary);
  color: #121212;
  border-bottom-left-radius: 0;
}

.chatBubbleUser {
  @include chatBubble();
  background: var(--app-color);
  color: #ffffff;
  border-bottom-right-radius: 0;
}

.chatBubbleMessage {
  padding: 0 8px 0 8px;
}

.chatBubbleMessageText {
  font-size: 14px;
  font-weight: 400;
  word-break: break-word;
}

.chatBubbleMessageTimeText {
  font-size: 10px;
  font-weight: 400;
}

.chatBubbleTime {
  position: absolute;
  right: 15px;
  bottom: 10px;
}

.chatBubbleLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
}

.chatBubbleLoaderCircle:nth-child(1) {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #ffffff;
  border-radius: 50%;
  animation: mbw-chat-loader-animation 1s infinite ease-in-out;
}

.chatBubbleLoaderCircle:nth-child(2) {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #c3c5ca;
  border-radius: 50%;
  animation: mbw-chat-loader-animation 1s infinite ease-in-out 0.2s;
}

.chatBubbleLoaderCircle:nth-child(3) {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #636570;;
  border-radius: 50%;
  animation: mbw-chat-loader-animation 1s infinite ease-in-out 0.4s;
}

@keyframes mbw-chat-loader-animation {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.chatBubbleAvatarContainer {
  display: flex;
  padding-right: 7px;
  align-items: flex-end;
  min-width: 41px;
}


@keyframes reveal {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

.timerContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timerHighlightedContainer {
  position: relative;
  animation: pulse 2s infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timerAnimation {
  stroke-dashoffset: var(--strokeValue);
  stroke-dasharray: var(--strokeValue);
  animation: reveal 60s linear infinite;
}

.timerTextColumnContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: center;
  top: 9px;
  line-height: 10px;
  gap: 1px;
  font-weight: 600;
  justify-content: center;
  align-items: center;

  .timerMin {
    font-size: 0.625em;
    color: var(--mbw-success-color);
  }

  .timerMinDuration {
    font-size: 0.935em;
    color: var(--mbw-success-color);
  }

  .timerSecDuration {
    font-size: 0.935em;
    color: var(--mbw-warning-color);
  }

    .timerSec {
      font-size: 0.625em;
      color: var(--mbw-warning-color);
    }
  }

.timerTextRowContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  gap: 3px;
  font-weight: 600;
  text-align: center;
  justify-content: center;
  align-items: center;

  .timerMin {
    font-size: 0.875em;
    color: var(--mbw-success-color);
  }

  .timerMinDuration {
    font-size: 0.935em;
    color: var(--mbw-success-color);
  }

  .timerSecDuration {
    font-size: 0.935em;
    color: var(--mbw-warning-color);
  }

  .timerSec {
    font-size: 0.875em;
    color: var(--mbw-warning-color);
  }
}

.freeTimeContainer {
  display: flex;
  flex-direction: column;
  text-align: end;
}

.freeTimeDuration {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: var(--mbw-success-color);
}

.freeTimeText {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: #8C909C;
}

.freeTimeVoipContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 20px;
}

.callStatusDotImg {
  display: flex;
  align-self: center;
  justify-self: center;
  width: 8px;
  height: 8px;
  margin-right: 5px;
}

.freeTimeVoipText {
  display: flex;
  align-self: center;
  justify-self: center;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #FFFFFF;
}

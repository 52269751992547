@import "../classes.module.scss";

$pressedPurple: #7943e9;

.continueContainer {
  width: 90%;
  margin-bottom: 25px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
}

$btnColor: #bdbdbd;

@mixin chatButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #fff0;
  border-radius: 10px;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
}

.endChatBtn {
  @include chatButton();
}

.addTimeBtn {
  @include chatButton();
  border: none;
  color: #FFFFFF;
}

@import './mixin.scss';

:export {
  middlePhoneOnlyUpSize: $middle-phone-only-up-size;
  phoneOnlyUpSize: $phone-only-up-size;
  appColor: var(--app-color);
  xfmLabelColor: var(--duration-option-xfm-label-color);
  advisorBusyColor: var(--advisor-busy-color)
}

:root {
  --scrollbar-width: 0px;
  --invisible-scrollbar-width: 0px;
  --app-error-red: #{$app-error-red};
  --app-border-gray: #{$app-border-gray};
  --app-box-shadow-gray: #{$app-box-shadow-gray};
  --app-white: #{$app-white};
  --app-black: #{$app-black};
  --app-purple: #{$app-purple};
  --app-chars-gray: #{$app-chars-gray};
  --app-border-light-gray: #{$app-border-light-gray};
  --app-border-shadow-light-gray: #{$app-border-shadow-light-gray};
  --app-border-shadow-gray: #{$app-border-shadow-gray};
  --app-default-btn-shadow-color: #{$app-default-btn-shadow-color};
  --app-disabled-btn-color: #{$app-disabled-btn-color};
  --app-overlay-black: #{$app-overlay-black};
  --app-orange: #{$app-orange};
  @include getThemeVars($light);
}

.darkTheme {
  @include getThemeVars($dark);
}

body {
  background-color: var(--app-background-color);
  min-width: 100%;
}

.layout {
  
  display: flex;
}

.pushNotificationContentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 300px;
}

.pushNotificationTextContainer {
  padding: 10px;
  text-align: center;
}

.pushNotificationTitleContainer {
  padding: 10px;
  padding-bottom: 0;
  text-align: center;
}

.pushNotificationButtonsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  border-top: 1px solid #f0f0f0;
}

.pushNotificationCancelBtn {
  background-color: transparent;
  padding: 4px 15px;
  border: solid 1px gray;
  border-radius: var(--app-default-btn-xs-border-radius);
  cursor: pointer;
}

.pushNotificationOkBtn {
  margin-left: 8px;
  background-color: var(--app-color);
  padding: 4px 15px;
  border: solid 1px var(--app-color);
  border-radius: var(--app-default-btn-xs-border-radius);
  cursor: pointer;
  color: white;
}

$purple: red;

$app-error-red: #F55151;
$app-border-gray: #C7C7C7;
$app-box-shadow-gray: #00000013;
$app-border-light-gray: #E7E7E7;
$app-border-shadow-light-gray: #00000014;
$app-border-shadow-gray: #cacaca40;
$app-white: #FFFFFF;
$app-black: #000000;
$app-purple1: #A131DA;
$app-purple :#8F63EC;
$app-chars-gray: #7D7D7D;
$app-default-btn-shadow-color: #5C30BB;
$app-disabled-btn-color: #ACAFB6;
$app-overlay-black: var(--app-overlay-black-color);
$app-orange: orange;


// LIGHT THEME
$app-background-color-light: #FFFFFF;
$app-background-secondary-color-light: #FFFFFF;
$app-background-gray-color-light: #F7F7F7;
$app-text-primary-light:  #121212;
$app-text-secondary-light: #7D7D7D;
$app-border-primary-color-light: #C7C7C7;
$app-default-btn-color-light: var(--app-color);
$app-skeleton-primary-color-light: #eee;
$app-skeleton-secondary-color-light: #f5f5f5;
$app-offer-expired-background-secondary-color-light: #F2F5FF;
$app-offer-expired-border-color-light: #DFE2EF;

$light: (
  --app-background-color: $app-background-color-light,
  --app-background-secondary-color: $app-background-secondary-color-light,
  --app-background-gray-color: $app-background-gray-color-light,
  --app-text-primary: $app-text-primary-light,
  --app-text-secondary: $app-text-secondary-light,
  --app-border-primary-color: $app-border-primary-color-light,
  --app-default-btn-color: $app-default-btn-color-light,
  --app-skeleton-primary-color: $app-skeleton-primary-color-light,
  --app-skeleton-secondary-color: $app-skeleton-secondary-color-light,
  --app-apple-pay-background-color: white,
  --app-apple-pay-style: white-outline,
  --app-offer-expired-background-secondary-color: $app-offer-expired-background-secondary-color-light,
  --app-offer-expired-border-color: $app-offer-expired-border-color-light
);

// DARK THEME
$app-background-color-dark: #27292F;
$app-background-secondary-color-dark: #383A40;
$app-background-gray-color-dark: #383A40;
$app-text-primary-dark: #EEEEEE;
$app-text-secondary-dark: #9C9C9C;
$app-border-primary-color-dark: #505155;
$app-default-btn-color-dark: var(--app-color);
$app-skeleton-primary-color-dark: #213243;
$app-skeleton-secondary-color-dark: #283954;
$app-offer-expired-background-secondary-color-dark: #383A40;
$app-offer-expired-border-color-dark: #505155;

$dark: (
  --app-background-color: $app-background-color-dark,
  --background-secondary: $app-background-color-dark,
  --app-background-secondary-color: $app-background-secondary-color-dark,
  --app-background-gray-color: $app-background-gray-color-dark,
  --app-text-primary: $app-text-primary-dark,
  --app-text-secondary: $app-text-secondary-dark,
  --app-border-primary-color: $app-border-primary-color-dark,
  --app-default-btn-color: $app-default-btn-color-dark,
  --app-skeleton-primary-color: $app-skeleton-primary-color-dark,
  --app-skeleton-secondary-color: $app-skeleton-secondary-color-dark,
  --app-apple-pay-style: white,
  --app-apple-pay-background-color: white,
  --app-offer-expired-background-secondary-color: $app-offer-expired-background-secondary-color-dark,
  --app-offer-expired-border-color: $app-offer-expired-border-color-dark,
  --add-credit-card-zen-mode-container-background-color: $app-background-gray-color-dark
);

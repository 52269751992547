.alertContentContainer {
  display: flex;
  justify-content: center;
  background: #fff2f0;
  padding: 10px;
}

.errorXImgContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.errorXImg {
  width: 24px;
  height: 24px;
}

.textContainer {
  margin-left: 5px;
}

.messageContainer {
  display: flex;
  font-size: 16px;
  font-weight: 700;
}

.errorStringContainer {
  font-size: 14px;
  font-weight: 400;
  color: black;
  word-break: break-word;
}

.closeBtnContainer {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.closeBtnImg {
  width: 13px;
  height: 13px;
}

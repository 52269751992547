@import '../../../../mixin.scss';
@import '../staff_pick_section/classes.module.scss';

.preview {
    display: flex;
    width: 100%;
}

.mainContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @include for-size(phone-only) {
        flex-direction: column;
        align-items: flex-start;
    }

    @include for-size(tablet-portrait-up) {
        flex-direction: column;
        align-items: flex-start;
    }

    @include for-size(tablet-landscape-up) {
      flex-direction: column;
      align-items: flex-start;
  }

  &.reverse {
    flex-direction: row-reverse;
  }
}

.iconsContainer {
    display: flex;
    justify-content: space-between;
}

.modesHolder {
    display: flex;
    flex-direction: row;
    column-gap: 12px;

    @include for-size(phone-only) {
        margin-bottom: 6px;
        align-items: flex-start;
        column-gap: 6px;
    }

    @include for-size(tablet-portrait-up) {
        margin-bottom: 6px;
        align-items: flex-start;
    }
}

.modesHolderSkeleton {
    @include skeletonContainer();
    width: 100px;
    height: 22px;
    @include for-size(phone-only) {
     height: 16px;
    }
  }

.modesHolderSkeleton {
    @include skeletonContainer();
    width: 100px;
    height: 22px;
    @include for-size(phone-only) {
     height: 16px;
    }
  }

.modeIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  &.withBg {
    width: 51px;
    height: 50px;
    background: #F7F7F7;
    border-radius: 6px;

    .modeIcon {
      width: 34px;
      height: 34px;
    }

    @include for-size(phone-only) {
      width: 30px;
      height: 30px;

      .modeIcon {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.modeIcon {
  width: 22px;
  height: 22px;
  @include for-size(phone-only) {
    width: 16px;
    height: 16px;
  }
  &.big {
    width: 36px;
    height: 36px;

    @include for-size(phone-only) {
      width: 20px;
      height: 20px;
    }
  }
}

.staffPick {
  .mainContainer {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      gap: 60px;

      @include for-size(phone-only) {
        gap: 35px;
      }
  }

  .iconsContainer {
      display: flex;
      justify-content: space-between;
  }

  .modesHolder {
      display: flex;
      flex-direction: row;
      margin-bottom: 0px;
      column-gap: 30px;

      @include for-size(phone-only) {
        column-gap: 28px;
      }
  }

  .modesHolderSkeleton {
    @include skeletonContainer();
    width: 100px;
    height: 40px;
    @include for-size(phone-only) {
        height: 30px;
    }
  }

  .modeIconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .modeIcon {
    width: 40px;
    height: 40px;
    @include for-size(phone-only) {
      width: 30px;
      height: 30px;
    }
  }

  &.v2 {
    .modesHolder {
      column-gap: 20px;

      @include for-size(phone-only) {
        column-gap: 16px;
      }
    }
  }
}

.recommendedCarousel {
  .modeIcon {
    width: 18px;
    height: 18px;
  }
}

.recommendedAdvisor {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .modesHolder {
    margin-bottom: 0;
  }
  .mainContainer {
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;

  }

  &.withBg {
    width: 36px;
    height: 36px;
    background: #F7F7F7;
    border-radius: 6px;

    .modeIcon {
      width: 25px;
      height: 25px;
    }
  }
}

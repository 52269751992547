@import "../../../mixin.scss";

.couponLabelContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.couponLabelIconContainer_small {
  display: flex;
  width: 80px;
  height: 26px;
}

.couponLabelIconContainer_medium {
  display: flex;
  width: 101px;
  height: 33px;
}

@mixin couponLabelContainer {
  display: flex;
  font-weight: 400;
  margin-left: 10px;
}

.couponLabelHoursToRedeemContainer_small {
  @include couponLabelContainer();
  color: black;
  font-size: 15px;
}

.couponLabelHoursToRedeemContainer_medium {
  @include couponLabelContainer();
  color: black;
  font-size: 20px;
}

.couponLabelOfferEndsIn1HoursToRedeemContainer_small {
  @include couponLabelContainer();
  color: #FF8717;
  font-size: 15px;
}

.couponLabelOfferEndsIn1HoursToRedeemContainer_medium {
  @include couponLabelContainer();
  color: #FF8717;
  font-size: 20px;
}

@import '../../mixin.scss';

.container {
  font-size: 1rem;
  min-width: 315px;
  padding: 15px;
  padding-bottom: 30px;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.radioBtnContainer {
  border-radius: 4px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin radioBtn {
  background: white;
  color: gray;
  padding: 0.5rem;
  flex-grow: 1;
  outline: none;
  border: 1px solid #e5e5e5;
  cursor: pointer;
}

.radioBtnLeft {
  @include radioBtn();
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.radioBtnRight {
  @include radioBtn();
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.radioBtnSelected {
  background: gray;
  color: white;
  border: 1px solid gray;
}

.closeBtnContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 15px;
  padding-right: 15px;
}

.closeBtn {
  border: none;
  background: transparent;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.advisorNotProvideServicesPopupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 420px;
}

.advisorNotProvideServicesPopupTitle {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.advisorNotProvideServicesPopupBody {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.advisorNotProvideServicesPopupButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
}

.advisorNotProvideServicesPopupButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.advisorNotProvideServicesPopupButtonContainer:first-of-type {
  margin-right: 20px
}

.advisorNotProvideServicesPopupContinueReadingButton {
  @include noBackgroundButton();
  border: 1px solid var(--app-border-gray);
  padding: 10px;
  width: 100%;
  font-weight: 700;
  border-radius: var(--app-default-btn-sm-border-radius);
}

.advisorNotProvideServicesPopupExploreOthersButton {
  @include noBackgroundButton();
  border: 1px solid var(--app-border-gray);
  padding: 10px;
  background-color: var(--app-color);
  color: var(--app-white);
  width: 100%;
  font-weight: 700;
  border-radius: var(--app-default-btn-sm-border-radius);
}

@import '../../common_mixin.scss';

@mixin toastContainer {
    position: fixed;
    width: 100%;
    padding: 0 10px;
    display: flex;
    z-index: 999999;
    bottom: 35px;
    transition: all .2s;
    justify-content: center;
    right: 0;
    @include for-size(phone-only) {
      bottom: var(--bottom);
    }
}

.toastContainerHiden {
  @include toastContainer();
  animation: fadeOut .7s;
  visibility: hidden;
}

.toastContainer {
  @include toastContainer();
  animation: fadeIn .7s;
}

.toastMessage {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  color: white;
  @include for-size(phone-only) {
    font-size: 12px;
  }
}

.toast {
  background: black;
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  padding: 18px;
  width: 370px;
  border-radius: 35px;
  opacity: 0.7;
  @include for-size(phone-only) {
    padding: 13px;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  100% { opacity: 0; }
  0% { opacity: 1; }
}

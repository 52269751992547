@import '../../../../mixin.scss';

@mixin defaultPaddings {
  padding-left: 160px;
  padding-right: 160px;
  @include for-size (phone-only) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.headerContainer {
  display: flex;
  max-width: 100%;
  margin-top: 95px;
  @include defaultPaddings();
  @include for-size(phone-only) {
    margin-top: 56px;
    align-items: center;
  }
}

.item {
  @include noBackgroundButton();
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-left: 60px;
  font-size: 22px;
  font-weight: 700;
  @include for-size (phone-only) {
    font-size: 15px;
  }
}

.item:first-of-type {
  margin-left: 0;
  align-items: center;
}

.reviewItemHolder {
  display: flex;
  height: 44px;
  align-items: center;
  padding-bottom: 16px;

  @include for-size (phone-only) {
    padding-bottom: 0;
  }
}

.reviewsContainer {
  display: flex;
  width: 100%;
  @include defaultPaddings();
  background-color: #FAFAFA;
  border-top: 1px solid #E4E4E4;
  padding-top: 50px;
  padding-bottom: 50px;
  flex-direction: column;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  @include for-size (phone-only) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.emptyReviewsContainer {
  display: flex;
  width: 100%;
  height: 400px;
  @include defaultPaddings();
  background-color: #FAFAFA;
  border-top: 1px solid #E4E4E4;
  flex-direction: column;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  justify-content: center;
  align-items: center;
  @include for-size (phone-only) {
    padding-top: 10px;
    height: 200px;
  }
}

.reviewContainer {
  display: flex;
  border: 1px solid #EAEAEA;
  flex-direction: column;
  background-color: white;
  margin-bottom: 16px;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 10px 20px rgba(0, 0, 0, 0.05);
  @include for-size (phone-only) {
    margin-bottom: 12px;
    padding: 15px;
    border-radius: 10px;
  }
}

.reviewerName {
  display: flex;
  width: 100%;
  font-size: 18px;
  @include for-size (phone-only) {
    font-size: 15px;
  }
}

.reviewImgContainer {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.reviewImage {
  width: 23px;
  height: 23px;
  @include for-size (phone-only) {
    width: 19px;
    height: 19px;
  }
}

.noReviews {
  width: 100%;
  height: 100%;
  color: #B7B7B7;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.comment {
  margin-top: 13px;
  font-weight: 400;
  font-size: 20px;
  word-break: break-word;
  @include for-size (phone-only) {
    font-size: 15px;
    margin-top: 2px;
  }
}

.reviewBuyerNicknameContainer {
  display: flex;
  width: 100%;
}

.reviewBuyerNickname {
  font-weight: 700;
}

.reviewDateContainer {
  display: flex;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 400;
  color: #A3A3A3;
  margin-left: 5px;
  @include for-size (phone-only) {
    font-size: 15px;
  }
}

.showMoreButton {
  @include noBackgroundButton();
  color: #63CA21;
  padding: 5px;
}

@import "../../mixin.scss";

@mixin controlButtonContainer {
    width: 66px;
    height: 66px;
    border-radius: 33px;
    justify-content: center;
    align-items: center;
}

@mixin blinking {
    margin-right: 7px;
    width: 13px;
    height: 13px;
    transition: opacity 0.5s;
}

.container {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: black;
}

.freeSetupFadeOut {
    @include blinking();
    opacity: 0;
}

.freeSetupFadeIn {
    @include blinking();
    opacity: 1;
}

.callAnimation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  z-index: 5;
}

.voiceContainer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    background-color: #000000;
    justify-content: center;
    display: flex;
}

.backgroundImage {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

.voiceOverlay {
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.7);
    position: absolute;
}

.muteActiveButton {
    @include controlButtonContainer();
    background-color: #5AC3EB;
    border: none;
}

.muteNotActiveButton {
    @include controlButtonContainer();
    background-color: rgba($color: #ffffff, $alpha: 0.26);
    border: none;
}

.muteIcon {
    width: 27px;
    height: 40px;
}

.controlsContainer {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 5;
    padding-bottom: 15px;
    padding-top: 15px;
    background: linear-gradient(to top, black, transparent 100%);
}

.controlsHolder {
  display: flex;
  justify-content: center;
  padding-bottom: 20px
}


.hangupButton {
    @include controlButtonContainer();
    background-color: #DB533C;
    margin-right: 27px;
    border: none;
}

.hangupIcon {
    width: 38px;
    height: 14px;
}

.timer {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 15px;
}

.reconnectLabel {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 120px;
}

.setupLabel {
    font-size: 1rem;
    color: #ffffff;
}

.publisherContainer {
    position: absolute;
    top: 28px;
    right: 15px;
    border-radius: 5px;
    border-width: 1.5px;
    border: solid;
    border-color: #B4B1AB;
    overflow: hidden;
    height: 150px;
    width: 100px;
    background-color: #000000;
}

.timerContainer {
    background-color: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 5px
}

*:focus {
    outline: 0 !important;
}

.nameAndDiscountLabelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  @include for-size(phone-only) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.callWithContainer {
  color: var(--app-white);
  font-size: 15px;
  font-weight: 400;
  @include for-size(phone-only) {
    font-size: 14px;
  }
}

.nameText {
  color: var(--app-white);
  font-size: 22px;
  font-weight: 700;
  margin-top: 5px;
  @include for-size(phone-only) {
    font-size: 18px;
  }
}

.discountLabelContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.discountLabelImg {
  width: 16px;
  height: 16px;
}

.discountLabelText {
  color: var(--app-white);
  font-size: 15px;
  font-weight: 700;
  margin-left: 3px;
  @include for-size(phone-only) {
    font-size: 14px;
  }
}

@import '../../mixin.scss';

.popoverContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  height: 100%;
  max-width: 100%;
  padding: 14px;
  padding-top: 0px;
}

.closeButtonContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.closeButton {
  @include noBackgroundButton();
  width: 44px;
  height: 44px;
}

$width: var(--width);
$height: var(--height);
$handleDiameter: var(--handleDiameter);
$transformXValue: calc(#{$width} - #{$handleDiameter} - 4px);
$offColor: var(--offColor);
$onColor: var(--onColor);
$offSliderColor: var(--offSliderColor);
$onSliderColor: var(--onSliderColor);
$offSliderImg: var(--offSliderImg);
$onSliderImg: var(--onSliderImg);

.switch {
  position: relative;
  display: inline-block;
  width: $width;
  height: $height;
  input {
    opacity: 0;
	width: 0;
	height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $offColor;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
    &:before {
	  position: absolute;
	  content: "";
	  height: $handleDiameter;
	  width: $handleDiameter;
	  left: 2px;
	  bottom: calc((#{$height} - #{$handleDiameter}) / 2);
	  background-color: $offSliderColor;
      background-image: $offSliderImg;
      background-repeat: no-repeat;
      background-position: center;
	  -webkit-transition: .4s;
	  transition: .4s;
      border-radius: 50%;
    }
}
input {
  &:checked {
	+ {
	  .slider {
		background-color: $onColor;
         &:before {
		    -webkit-transform: translateX($transformXValue);
			-ms-transform: translateX($transformXValue);
			transform: translateX($transformXValue);
            background-color: $onSliderColor;
            background-image: $onSliderImg;
            background-repeat: no-repeat;
            background-position: center;
          }
	  }
	}
  }
  &:focus {
    + {
	  .slider {
	    box-shadow: 0 0 1px #000000;
	  }
	}
  }
}

.container {
  width: 100%;
  background: red;
  color: white;
  flex-shrink: 0;
  padding: 8px;
}

.holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

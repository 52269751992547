@import '../../mixin.scss';

.closeImg {
  width: 40px;
  height: 40px;
}

.profileImageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  flex-direction: column;
}

.reactPlayerContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--reactPlayerContainerWidth);
  height: var(--reactPlayerContainerHeight);
  max-width: 600px;
  max-height: 600px;
  @include for-size(phone-only) {
    max-width: unset;
    max-height: unset;
  }
}

.playerContent {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  z-index: 10000;
  padding-bottom: 25px;
}

// .player {
//   outline: 0;

//   video {
//     outline: 0;
//   }
// }

.playBtnContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playButtonimg {
  z-index: 2;
  width: 61px;
  height: 61px;
  cursor: pointer;
}

.closeButton {
  position: absolute;
  border: none;
  background: none;
  cursor: pointer;
  z-index: 10;
  outline: 0;
  top: var(--closeButtonTop);
  right: var(--closeButtonRight);
}

@import '../../../mixin.scss';

.bottomButtonContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.bottomButton {
  font-size: 17px;
  font-weight: 600;
  border-radius: var(--app-default-btn-lg-border-radius);
  padding: 13px;
  background-color: #ffffff;
  border: 1px solid #E0E0E0;
  box-shadow: inset 0px -4px 0px rgba(202, 202, 202, 0.25);
  cursor: pointer;
}

.bottomButton:hover {
  box-shadow: 0px 4px 20px rgba(71, 74, 78, 0.08), 0px 2px 20px rgba(71, 74, 78, 0.14);
}

// searchResult
.searchResult {
  width: 100%;

  @include for-size(phone-only) {
    width: 100%;

    .bottomButtonContainer {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-top: 20px;
    }

    .bottomButton {
      font-size: 15px;
      padding: 10px;
    }
  }
}

//SOME_OTHER_ADVISOR
.someOtherAdvisor {
  width: 100%;
  margin-top: 15px;

  .seeMoreBtnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 60px;
  }

  .seeMoreBtn {
    font-size: 17px;
    font-weight: 600;
    border-radius: var(--app-default-btn-lg-border-radius);
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #ffffff;
    border: 1px solid #E0E0E0;
  }

  @include for-size(phone-only) {
    width: 100%;

    .seeMoreBtnContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    .seeMoreBtn {
      font-size: 15px;
      font-weight: 600;
      border-radius: var(--app-default-btn-lg-border-radius);
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 14px;
      padding-right: 14px;
      background-color: #ffffff;
      border: 1px solid #E0E0E0;
    }
  }
}


// homePageAllAdvisors
.homePageAllAdvisors {
  width: 100%;

  .bottomButtonContainer {
    flex-direction: row;
  }

  .bottomButton,
  .bottomButton:hover {
    font-size: 15px;
    font-weight: 700;
    border-radius: var(--app-default-btn-lg-border-radius);
    padding: 10px;
    background-color: var(--app-color);
    border: none;
    color: white;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .seeMoreBtnImg {
   width: 25px;
   height: 25px;
   margin-right: 13px;
    @include for-size(phone-only) {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .seeMoreBtnText {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  @include for-size(phone-only) {
    width: 100%;

    .bottomButtonContainer {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-top: 20px;
    }
  }

}

@import "../../common_mixin.scss";

.addButtonImg {
  width: 49px;
  max-height: 32px;
  margin-right: 8px;
}

.skeleton {
  @include skeletonContainer();
  width: 200px !important;
  height: 25px !important;
  margin-top: 20px !important;
}

.skeleton:first-of-type {
  margin-top: 0;
}

// WALLET
.wallet {
  @include for-size(phone-only) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    .addButtonImg {
      display: none;
    }
  }

  .addButton {
    @include noBackgroundButton;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: var(--app-white);
    background-color: var(--app-default-btn-color, var(--app-color));
    border-radius: var(--app-default-btn-lg-border-radius, 10px);
    padding: 15px;
    height: fit-content;
    @include for-size(phone-only) {
      width: 100%;
      font-weight: 700;
      margin: 0 auto;
      margin-bottom: 20px;
      border-radius: var(--app-default-btn-lg-border-radius, 10px);
      font-size: 14px;
      border: none;
      border-top: 1px solid var(--app-border-primary-color);
      box-shadow: none;
      justify-content: center;
    }
  }
}

// PURCHASE
.purchase {
  .addButton {
    @include noBackgroundButton;
    height: 50px;
    display: flex;
    align-items: center;
    width: 100%;
    color: var(--app-text-primary);
    font-size: 16px;
    @include for-size(phone-only) {
      display: flex;
      font-weight: 700;
      font-size: 16px;
      }
    }

  .popupAddButtonText {
    font-weight: 600;
    font-size: 13px;
    padding-top: 3px;
    text-align: left;
    color: var(--app-text-primary)
  }
}

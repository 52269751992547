@import '../../../mixin.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: white;
  z-index: 10;
}

@mixin animationFrame($width) {
  width: $width;
  height: $width;
  border-radius: 50%;
  border: solid 2px rgba($color: red, $alpha: 0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.frameOut {
  @include animationFrame($width: 225px);
  animation: outAnimation 3s infinite;
}

.frameMid {
  @include animationFrame($width: 200px);
  animation: midAnimation 3s infinite;
}

.frameIn {
  @include animationFrame($width: 175px);
  animation: inAnimation 3s infinite;
}

$circleColor: #797979;
@keyframes inAnimation {
  0%{
    border-color: rgba($color: $circleColor, $alpha: 0);
  }
  33%{
    border-color: rgba($color: $circleColor, $alpha: 0.75);
  }
}

@keyframes midAnimation {
  0%{
    border-color: rgba($color: $circleColor, $alpha: 0);
  }
  66%{
    border-color: rgba($color: $circleColor, $alpha: 0.5);
  }
}

@keyframes outAnimation {
  0%{
    border-color: rgba($color: $circleColor, $alpha: 0);
  }
  100%{
    border-color: rgba($color: $circleColor, $alpha: 0.25);
  }
}

.profilePicture {
  width: 150px;
  height: 150px;
  border-radius: 75px;
  border-color: var(--color);
  border-width: 2px;
  border-style: solid;
}

.contactingContainer {
  margin-top: 20px;
  text-align: center;
  font-size: 20px;
}

.contacting {
  font-size: 12px;
}

.buttonContainer {
  width: 100%;
  position: absolute;
  bottom: 3em;
  display: flex;
  justify-content: center;
}

.button {
  width: 70px;
  height: 70px;
  background: #f00;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background: #b20000;
}

.hangingUp {
  background: #b20000;
}

.buttonImage {
  width: 25px;
  height: 25px;
}

.voipButtonImage {
  width: 38px;
  height: 14px;
}

.blurImage {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -2;
  filter:blur(7px);
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba($color: #000000, $alpha: 0.5)
}

.publisherContainer {
  position: absolute;
  top: 28px;
  right: 15px;
  border-radius: 5px;
  border-width: 1.5px;
  border: solid;
  border-color: #B4B1AB;
  overflow: hidden;
  height: 150px;
  width: 100px;
  background-color: #000000;
}
